import _ from 'underscore';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import React, { useState, useMemo, useCallback } from 'react';
import { Button, Form, Dropdown } from 'semantic-ui-react';

import useIsPlugin from '@/hooks/common/useIsPlugin';
import useEmailApi from '@/hooks/common/useEmailApi';
import useMinimizedView from '@/hooks/ui/useMinimizedView';
import { useCandidateViewContext } from '@/context/CandidateView/useCandidateViewContext';
import { useMergedConfigurationParams } from '@/graphql/hooks/useMergedConfigurationParams';

import {
  TASK_TYPES,
  UNIPILE_TASK_TYPES,
  canHaveHtmlMessage,
  isEmailAction,
  isSMSAction,
} from '@/common/constants/taskTypes';
import SenderAndRecipient from '@/revealComponents/ProfileContactFlow/Actions/EmailAction/SenderAndRecipient';
import useRevealEmailAction, {
  RevealSender,
  RevealSenderType,
} from '@/revealComponents/ProfileContactFlow/Actions/EmailAction/useRevealEmailAction';
import { SearchPoolProfile } from '@/types/searchPoolProfile';
import { invalidEmailFormat } from '@/common/validators';
import { getUserClientMailAccount } from '@/hocs/email/sendEmailQuery';
import SimpleDropdown from '@/components/SimpleDropdown';
import MetaTaskForm from '@/revealComponents/ExplicitTasks/MetaTaskForm';
import { sanitizeTypename } from '@/common/utils/apollo';
import { TranslatableText } from '@/types/text';
import useTextingSenders from '@/graphql/hooks/clients/useTextingSenders';
import {
  TextMessageActionExecution,
  WhatsappMessageActionExecution,
} from '@/types/action';
import { useClientSnoozeReasons } from '@/graphql/hooks/clients/useClientSnoozeReasons';
import { useTaskCanSnooze } from '@/hooks/common/useTaskCanSnooze';

import useSharedMessagingAccounts from '@/graphql/hooks/clients/useSharedServiceAccount';
import useLocalStorage from '@/hooks/storage/useLocalStorage';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';
import { useHelpTextCTA } from './useHelpTextCTA';
import ActionItemContainer from './ActionItemContainer';
import ActionItemHeader from './ActionItemHeader';
import MarkTaskAsCompletedButton from './MarkTaskAsCompletedButton';
import EmailActionItem from './EmailActionItem';
import ActionMailButton from './ActionMailButton';
import ActionEmailEditor, { ActionMessage } from './ActionEmailEditor';
import SnoozeTask from './SnoozeTask';
import ActionInlineEdition from './ActionInlineEdition';
import { formatTaskOrActionMessage } from '../../helpers';
import { MetaTaskFormatForm } from '../../../ExplicitTasks/MetaTaskForm/types';
import BouncedEmailAlert from './BouncedEmailAlert';
import TextMessageForm from './TextMessageForm';

import styles from './ActionWithTaskItem.module.less';
import WhatsappMessageForm from './WhatsappMessageForm';
import { LinkedinSenderConfig } from './SendUnipileLinkedinMessageButton';

export const filterCcAndBcc = (addresses: string[]) => {
  return _.filter(
    addresses,
    (address) => !invalidEmailFormat({ email: address }),
  );
};
interface ActionWithTaskItemProps {
  task: any;
  action: any;
  actions: any[];
  profile: SearchPoolProfile;
  index: number;
  isFirstEmail?: boolean;
  isLast: boolean;
  clientId: string;
  searchPoolId: string;
  sequenceId?: string;
  onUpdate: ({ actionId, updatedAction }: any) => Promise<void>;
  onRemove?: () => void;
  onEdit?: () => void;
  onSnooze?: ({
    snoozeDate,
    snoozeDuration,
    snoozeReason,
  }: {
    snoozeDate: Date;
    snoozeDuration: {
      unit: 'day';
      value: number;
    };
    snoozeReason?: string;
  }) => void;
  nextEmailActionIds: string[];
  onBulkApplyCcBccToActions: ({
    actionIds,
    cc,
    bcc,
  }: {
    actionIds: string[];
    cc: string[];
    bcc: string[];
  }) => void;
  isInlineEdition: boolean;
  setIsInlineEdition: React.Dispatch<React.SetStateAction<boolean>>;
  messageState: ActionMessage;
  setMessageState: React.Dispatch<React.SetStateAction<ActionMessage>>;
}

const ActionWithTaskItem: React.FC<ActionWithTaskItemProps> = ({
  task,
  sequenceId,
  searchPoolId,
  profile,
  action,
  actions,
  index,
  isFirstEmail,
  isLast,
  clientId,
  onRemove,
  onEdit,
  onSnooze,
  nextEmailActionIds,
  onBulkApplyCcBccToActions,
  isInlineEdition,
  setIsInlineEdition,
  messageState,
  setMessageState,
  onUpdate,
}) => {
  const { isMinimized } = useMinimizedView();
  const { t } = useTranslation();
  const [cc, setCc] = useState(action?.message?.cc || []);
  const [bcc, setBcc] = useState(action?.message?.bcc || []);

  // unipile -
  const [reconnectUnipileModalOpen, setReconnectUnipileModalOpen] = useState(
    false,
  );

  const [unipileLinkedinSenderConfig] = useLocalStorage<LinkedinSenderConfig>(
    'revealLinkedinSender_',
    null,
  );
  const [selectedUnipileSenderId, setSelectedUnipileSenderId] = useState(
    action?.message?.senderId || unipileLinkedinSenderConfig,
  );

  const [unipilePollingRate, setUnipilePollingRate] = useState(0);
  // - unipile

  const isPlugin = useIsPlugin();
  const configurationParams = useMergedConfigurationParams();
  const { handleCompleteTask, completeTaskLoading } = useCandidateViewContext();
  const { snoozeReasons } = useClientSnoozeReasons(clientId);

  const { permissions } = useClientPermissions(clientId);

  const { sharedMessagingAccounts } = useSharedMessagingAccounts({
    skip: !_.includes(UNIPILE_TASK_TYPES, action?.type),
    queryOptions: {
      pollInterval: unipilePollingRate,
    },
  });

  const selectedMessagingAccount = useMemo(
    () =>
      _.includes(UNIPILE_TASK_TYPES, action.type)
        ? _.findWhere(sharedMessagingAccounts, { id: selectedUnipileSenderId })
        : undefined,
    [selectedUnipileSenderId, sharedMessagingAccounts, action],
  );

  const { textingSenders } = useTextingSenders();

  const canAutomaticallySentText =
    !!profile.resumeData?.phoneNumber && !_.isEmpty(textingSenders);

  const emailApi = useEmailApi();

  const { canSnooze } = useTaskCanSnooze(task);

  const actionSobo: RevealSender | null = useMemo(() => {
    const messagePredefinedSenderId = action?.message?.senderId;
    if (!messagePredefinedSenderId) {
      return null;
    }

    return {
      type: RevealSenderType.Sobo,
      senderId: messagePredefinedSenderId,
    };
  }, [action]);

  const {
    emitter,
    canChooseEmitter,
    assignedSender,
    setClassicSender,
    setSoboSender,
    senders,
    revealSenderType,
  } = useRevealEmailAction(profile, {
    // Used since our actions can have a predefined sobo sender
    // That will be displayed before the user selects their own
    forceRevealSender: actionSobo,
  });
  const { data: pluginUserData } = useQuery(getUserClientMailAccount, {});

  const { currentTask } = useCandidateViewContext();

  const [clipboardTargetIndex, setClipboardTargetIndex] = useState(0);
  const [snoozeModalOpen, setSnoozeModalOpen] = useState(false);
  const [
    snoozeModalOpenFromContextMenu,
    setSnoozeModalOpenFromContextMenu,
  ] = useState(false);

  const hasContent = !!useMemo(() => {
    return (
      action.message?.body?.length ||
      action.message?.subject?.length ||
      action?.description?.default?.length
    );
  }, [action]);

  const increaseClipboardTargetIndex = () => {
    setClipboardTargetIndex((previous) => previous + 1);
  };

  const handleSnoozeOneWeekCommand = () => {
    const defaultDate = new Date();
    defaultDate.setDate(defaultDate.getDate() + 7);
    if (onSnooze) {
      onSnooze({
        snoozeDate: defaultDate,
        snoozeReason: '',
        snoozeDuration: { unit: 'day', value: 7 },
      });
    }
  };

  const handleInlineEditionUpdate = ({ actionId, updatedAction }: any) => {
    const message = formatTaskOrActionMessage(
      updatedAction.message,
      updatedAction.type,
    );
    onUpdate({
      actionId,
      updatedAction: {
        ...updatedAction,
        message,
        snippets: message.snippets,
      },
    });
  };

  const handleCcUpdate = async (addresses: string[]) => {
    setCc(addresses);
    const updatedAction = {
      ...action,
      message: {
        ...action.message,
        cc: filterCcAndBcc(addresses),
      },
    };
    await onUpdate({
      actionId: action.actionId,
      updatedAction,
    });
  };

  const handleBccUpdate = (addresses: string[]) => {
    setBcc(addresses);
    const updatedAction = {
      ...action,
      message: {
        ...action.message,
        bcc: filterCcAndBcc(addresses),
      },
    };
    onUpdate({
      actionId: action.actionId,
      updatedAction,
    });
  };

  const areSameEmailsLists = (emailsList1: string[], emailsList2: string[]) =>
    (emailsList1 || []).join(';') === (emailsList2 || []).join(';');

  const hasFollowingEmailActionsWithDifferentCcBcc = _.some(
    (actions || []).slice(index + 1),
    (nextAction) =>
      isEmailAction(nextAction) &&
      (!areSameEmailsLists(
        [...(nextAction.message?.cc || [])].sort(),
        [...cc].sort(),
      ) ||
        !areSameEmailsLists(
          [...(nextAction.message?.bcc || [])].sort(),
          [...bcc].sort(),
        )),
  );

  const handleMetaTaskFormUpdate = ({
    helpText,
    newForm,
  }: {
    helpText?: TranslatableText;
    newForm: MetaTaskFormatForm;
  }) => {
    const sanitizedActionForm = sanitizeTypename({
      ...newForm,
    });
    const updatedAction = {
      ...sanitizeTypename(action),
      format: {
        helpText: sanitizeTypename(helpText),
        form: {
          ...sanitizedActionForm,
          fields: sanitizedActionForm.fields,
        },
      },
    };
    onUpdate({
      actionId: action.actionId,
      updatedAction,
    });
  };

  const userCanSend =
    (emailApi.currentAddress &&
      emailApi.hasOfflineGrant[emailApi.currentAddress] &&
      !emailApi.connectionInProgress) ||
    assignedSender;

  const userCanSendPluginSafe =
    userCanSend || (isPlugin && (pluginUserData?.user?.mailAccount ?? false));

  const displayPrincipalEditionButtons =
    configurationParams?.shouldHidePendingActionEditionInMenu !== 'true' &&
    configurationParams?.shouldHideCompletelyPendingActionEditionInMenu !==
      'true';

  const displayContextMenuEditionButtons =
    !displayPrincipalEditionButtons &&
    configurationParams?.shouldHideCompletelyPendingActionEditionInMenu !==
      'true';

  const actionHelpTextCTA = useHelpTextCTA({
    action,
    profile,
  });

  const handleExecution = useCallback(
    async (
      execution: TextMessageActionExecution | WhatsappMessageActionExecution,
    ) => {
      await onUpdate({
        actionId: action.actionId,
        updatedAction: {
          ...action,
          execution,
        },
      });
    },
    [action, onUpdate],
  );

  return (
    <ActionItemContainer
      action={action}
      isLast={isLast}
      isBox
      withIcon={false}
      withBorder={currentTask?.id === task?.id}
    >
      <ActionItemHeader
        action={action}
        task={task}
        withIcon
        isCurrent
        displayContextMenu={displayContextMenuEditionButtons}
        onSnoozeFromContextMenu={() => setSnoozeModalOpenFromContextMenu(true)}
        onRemoveFromContextMenu={onRemove}
        onEditFromContextMenu={onEdit}
        onExecute={handleExecution}
        selectedUnipileSenderId={selectedUnipileSenderId}
        setSelectedUnipileSenderId={setSelectedUnipileSenderId}
        messagingAccount={selectedMessagingAccount}
        sharedMessagingAccounts={sharedMessagingAccounts}
        reconnectModalOpen={reconnectUnipileModalOpen}
        onReconnectModalOpen={setReconnectUnipileModalOpen}
        onUnipilePollingRate={setUnipilePollingRate}
      >
        {isEmailAction(action) && (
          <ActionMailButton
            actionId={action.actionId}
            searchPoolId={searchPoolId}
            message={action.message}
            instantiatedSnippets={action.snippets || []}
            profile={profile}
            task={task}
            sequenceId={sequenceId}
            assignedSender={assignedSender}
            setClassicSender={setClassicSender}
            bccAddresses={bcc}
            ccAddresses={cc}
            clientId={clientId}
            userCanSend={userCanSendPluginSafe}
          />
        )}
      </ActionItemHeader>
      <MetaTaskForm
        format={action?.format}
        helpTextCTA={actionHelpTextCTA}
        onUpdate={handleMetaTaskFormUpdate}
      />
      {action?.type === TASK_TYPES.LINKEDIN_CHECK_REQUEST &&
        !selectedUnipileSenderId && (
          <MetaTaskForm
            format={{
              form: {
                fields: [
                  {
                    type: 'select',
                    id: 'linkedinCheckRequest',
                    title: {
                      default: t(
                        'reveal.candidatesView.timeline.unipileLinkedinCheckRequest',
                      ),
                    },
                    options: [
                      {
                        id: 'linkedinCheckRequestAccepted',
                        title: {
                          default: t(
                            'reveal.candidatesView.timeline.unipileLinkedinCheckRequestAccepted',
                          ),
                        },
                      },
                      {
                        id: 'linkedinCheckRequestNotAccepted',
                        title: {
                          default: t(
                            'reveal.candidatesView.timeline.unipileLinkedinCheckRequestNotAccepted',
                          ),
                        },
                      },
                    ],
                  },
                ],
              },
            }}
            helpTextCTA={actionHelpTextCTA}
            onUpdate={async ({ newForm }) => {
              await onUpdate({
                actionId: action.actionId,
                updatedAction: {
                  ...action,
                  format: { form: newForm },
                },
              });
            }}
          />
        )}
      {isSMSAction(action?.type) && (
        <TextMessageForm
          onExecution={handleExecution}
          profilePhoneNumber={profile.resumeData?.phoneNumber}
          onSubmit={() => handleCompleteTask(task)}
          completeTaskLoading={completeTaskLoading}
          message={action.message}
          snippets={action.snippets}
        />
      )}
      {permissions?.whatsappSequenceActions &&
        action?.type === TASK_TYPES.WHATSAPP_SEND_MESSAGE && (
          <WhatsappMessageForm
            profile={profile}
            onExecution={handleExecution}
            onSubmit={(task) => handleCompleteTask(task)}
            completeTaskLoading={completeTaskLoading}
            message={action.message}
            snippets={action.snippets}
            messageState={messageState}
            setMessageState={setMessageState}
            task={task}
            onOpenReconnectModal={setReconnectUnipileModalOpen}
            onUpdateAction={handleInlineEditionUpdate}
            action={action}
            isInlineEdition={isInlineEdition}
            onIsInlineEdition={setIsInlineEdition}
          />
        )}
      {isEmailAction(action) && canChooseEmitter && !userCanSendPluginSafe && (
        <div>
          <SimpleDropdown text={t('reveal.sobo.assignSender')}>
            <Dropdown.Menu>
              {_.map(senders, (sender) => (
                <Dropdown.Item
                  key={sender.id}
                  text={sender.senderAddress}
                  onClick={() => setSoboSender(sender.id)}
                />
              ))}
            </Dropdown.Menu>
          </SimpleDropdown>
        </div>
      )}
      {isEmailAction(action) && (
        // TODO: we could use allowSelectSharedSender here to remove the selector above
        <>
          <SenderAndRecipient
            clientId={clientId}
            revealSenderType={revealSenderType}
            from={emitter}
            canChooseEmitter={canChooseEmitter && !action?.message?.senderId}
            senders={senders}
            profile={profile}
            pluginUserData={pluginUserData}
            setClassicSender={setClassicSender}
            setSoboSender={setSoboSender}
            sobo={_.findWhere(senders, { id: action?.message?.senderId })}
            bcc={bcc}
            setBcc={handleBccUpdate}
            cc={cc}
            setCc={handleCcUpdate}
            t={t}
          />
          {hasFollowingEmailActionsWithDifferentCcBcc &&
            (!_.isEmpty(cc) || !_.isEmpty(bcc)) && (
              <span
                className='apply-to-next-mails'
                onClick={() =>
                  onBulkApplyCcBccToActions({
                    actionIds: nextEmailActionIds,
                    cc,
                    bcc,
                  })
                }
                onKeyPress={() =>
                  onBulkApplyCcBccToActions({
                    actionIds: nextEmailActionIds,
                    cc,
                    bcc,
                  })
                }
                role='button'
              >
                {t('reveal.candidatesView.timeline.applyToFollowingMails')}
              </span>
            )}
          <BouncedEmailAlert profile={profile} />
        </>
      )}
      {action.type !== TASK_TYPES.WHATSAPP_SEND_MESSAGE &&
        (!isInlineEdition ? (
          <>
            {isEmailAction(action) &&
              (!isMinimized ? (
                <Form.Field className='email-editor-container'>
                  <ActionEmailEditor
                    messageState={messageState}
                    setMessageState={setMessageState}
                    action={action}
                    isFirstEmail={isFirstEmail}
                    clientId={clientId}
                    onUpdateAction={({ updatedAction }: any) => {
                      onUpdate({
                        actionId: action.actionId,
                        updatedAction: {
                          ...action,
                          ...updatedAction,
                          message: {
                            ...action?.message,
                            ...updatedAction?.message,
                          },
                        },
                      });
                    }}
                    hideMenu={!canHaveHtmlMessage({ type: action.type })}
                  />
                </Form.Field>
              ) : (
                <div className='action-content minimized'>
                  <EmailActionItem
                    message={action.message}
                    instantiatedSnippets={action.snippets}
                    clipboardTargetIndex={clipboardTargetIndex}
                    increaseClipboardTargetIndex={increaseClipboardTargetIndex}
                    showSubject={isFirstEmail}
                  />
                </div>
              ))}
            {!isEmailAction(action) &&
              !isSMSAction(action?.type) &&
              action?.type !== TASK_TYPES.WHATSAPP_SEND_MESSAGE &&
              hasContent && (
                <div
                  className={`action-content editable ${
                    isMinimized ? 'minimized' : ''
                  }`}
                  onClick={() => setIsInlineEdition(true)}
                >
                  {action.message && (
                    <EmailActionItem
                      message={action.message}
                      instantiatedSnippets={action.snippets}
                      clipboardTargetIndex={clipboardTargetIndex}
                      increaseClipboardTargetIndex={
                        increaseClipboardTargetIndex
                      }
                      showSubject={isFirstEmail}
                    />
                  )}
                  {!!action.description && (
                    <span className='action-description'>
                      {messageState.body}
                    </span>
                  )}
                </div>
              )}
          </>
        ) : (
          <ActionInlineEdition
            action={action}
            messageState={messageState}
            setMessageState={setMessageState}
            isFirstEmail={isFirstEmail}
            clientId={clientId}
            onUpdate={handleInlineEditionUpdate}
            onQuitInlineEdition={() => setIsInlineEdition(false)}
          />
        ))}
      {!isMinimized && snoozeModalOpenFromContextMenu && (
        <>
          <div className={styles.snoozeDimmer} />
          <SnoozeTask
            snoozeReasons={snoozeReasons}
            setSnoozeModalOpen={(value) => {
              setSnoozeModalOpen(value);
              setSnoozeModalOpenFromContextMenu(value);
            }}
            onConfirm={onSnooze}
            maxSnoozeDate={action?.snoozeConfiguration?.maxSnoozeDate}
          />
        </>
      )}
      <div
        className={`pending-action-buttons ${isMinimized ? 'minimized' : ''}`}
      >
        {!isEmailAction(action) &&
          action?.type !== TASK_TYPES.WHATSAPP_SEND_MESSAGE &&
          !(isSMSAction(action?.type) && canAutomaticallySentText) &&
          (!selectedMessagingAccount ||
            !permissions?.whatsappSequenceActions) && (
            <div className='mark-completed-button'>
              <MarkTaskAsCompletedButton
                task={{
                  ...task,
                  ...(action?.format?.form && {
                    form: action.format.form,
                  }),
                  ...(action?.format?.helpText && {
                    helpText: action.format.helpText,
                  }),
                  ...(action?.completionButtonDisplay && {
                    completionButtonDisplay: action.completionButtonDisplay,
                  }),
                }}
                onExecute={handleExecution}
              />
            </div>
          )}
        {displayPrincipalEditionButtons && (
          <>
            {canSnooze && (
              <div className='snooze-button'>
                <Button
                  className='pending-action-button'
                  basic
                  onClick={() => setSnoozeModalOpen(true)}
                >
                  <i className='ri-rest-time-line' />
                  {!isMinimized && (
                    <>
                      {t(
                        `reveal.candidatesView.timeline.snooze.${
                          (action.nbManualSnoozes || 0) > 0
                            ? 'labelAgain'
                            : 'label'
                        }`,
                      )}
                      <i
                        className={
                          snoozeModalOpen
                            ? 'ri-arrow-drop-up-fill'
                            : 'ri-arrow-drop-down-fill'
                        }
                      />
                    </>
                  )}
                </Button>
              </div>
            )}
            {configurationParams?.shouldHideDeleteTask !== 'true' && (
              <div className='remove-button'>
                <Button
                  className='pending-action-button'
                  basic
                  onClick={onRemove}
                >
                  <i className='ri-close-fill' />
                  {!isMinimized && t('common.delete')}
                </Button>
              </div>
            )}
            {configurationParams?.shouldHideEditTask !== 'true' && (
              <div className='edit-button'>
                <Button
                  className='pending-action-button'
                  basic
                  onClick={onEdit}
                >
                  <i className='ri-pencil-line' />
                  {!isMinimized && t('common.edit')}
                </Button>
              </div>
            )}
          </>
        )}
        {!isMinimized && snoozeModalOpen && (
          <SnoozeTask
            snoozeReasons={snoozeReasons}
            setSnoozeModalOpen={(value) => {
              setSnoozeModalOpen(value);
              setSnoozeModalOpenFromContextMenu(value);
            }}
            onConfirm={onSnooze}
            maxSnoozeDate={action?.snoozeConfiguration?.maxSnoozeDate}
          />
        )}
      </div>
      {isMinimized && snoozeModalOpen && (
        <SnoozeTask
          snoozeReasons={snoozeReasons}
          setSnoozeModalOpen={setSnoozeModalOpen}
          onConfirm={onSnooze}
          maxSnoozeDate={task?.snoozeConfiguration?.maxSnoozeDate}
        />
      )}
      <button
        type='button'
        id='snooze-task-one-week-hidden'
        onClick={handleSnoozeOneWeekCommand}
      />
    </ActionItemContainer>
  );
};

export default ActionWithTaskItem;
