import React, { useContext, useMemo, useState } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

import useClientCustomCompanyDealStates from '@/graphql/hooks/clients/useClientCustomCompanyDealStates';
import ProfilesViewSettingsContext from '@/context/ProfilesViewSettingsContext';
import GenericSelect from '@/components/Common/GenericSelect';
import { getTranslatedText } from '@/common/helpers/translatableText';
import { getShortLanguage } from '@/common/utils/i18n';

function CompanyDealStateFilter() {
  const { i18n, t } = useTranslation();
  const lang = getShortLanguage(i18n.resolvedLanguage);
  const [settings, dispatch] = useContext(ProfilesViewSettingsContext);
  const [stateIds, setStateIds] = useState(settings.companyDealState?.stateIds);
  const { customCompanyDealStates } = useClientCustomCompanyDealStates();

  const options = useMemo(
    () => [
      ..._.map(
        customCompanyDealStates,
        ({ customCompanyDealStateId, title }) => ({
          value: customCompanyDealStateId,
          label: getTranslatedText(lang, title),
        }),
      ),
      { value: null, label: t('common.unknown') },
    ],
    [customCompanyDealStates, lang, t],
  );

  const selectedOptions = useMemo(
    () => _.filter(options, ({ value }) => _.includes(stateIds ?? [], value)),
    [stateIds, options],
  );

  return (
    <GenericSelect
      options={options}
      value={selectedOptions}
      isMulti
      onChange={(value) => {
        const newStateIds = _.pluck(value ?? [], 'value');
        if (_.isEmpty(newStateIds)) {
          setStateIds(undefined);
          dispatch({ type: 'setCompanyDealState', value: undefined });
          return;
        }
        setStateIds(newStateIds);
        dispatch({
          type: 'setCompanyDealState',
          value: {
            filterType: 'in',
            stateIds: newStateIds,
          },
        });
      }}
    />
  );
}

export default CompanyDealStateFilter;
