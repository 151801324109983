import type { Reducer } from 'react';
import _ from 'underscore';

import { type ATStype, ATS_FILTERS_KEY_BY_TYPE } from '@/common/reveal';
import type {
  AvailabilityFilter,
  CategoryFilter,
  CompanyDealStateFilter,
  ContactCategorySieve,
  SearchCriteria,
} from '@/types/search';
import { sectionDefinitions } from '@/routes/RevealView/JobsView/JobView/ProjectProfilesTab/ProfilesViewFilterSettings/ProfilesViewFilterDropdown/helpers/filters';
import { APIFilter } from '@/routes/RevealView/SearchView/SearchPane/CustomCriteriaFilters/types';

export type AvailabilityDateFilter =
  | { type: 'absolute'; date: Date }
  | { type: 'relative'; days: number };

export type MedicalExaminationFilter = 'valid' | 'invalid';

export type ConstructionCardFilter = 'valid' | 'invalid';

export type ATSTagFilter = {
  operator: 'and' | 'or';
  tagIds: string[];
};

export type CustomFieldFilter = CustomFieldEnumFilter;

type CustomFieldEnumFilter = {
  type: 'enum';
  clientCustomFieldId: string;
  policy: 'intersect' | 'exists';
  value?: readonly string[];
};

export type ProfilesViewSettings = {
  availabilityDate?: AvailabilityDateFilter;
  medicalExamination?: MedicalExaminationFilter;
  constructionCard?: ConstructionCardFilter;
  atsTagFilters: Partial<
    Record<
      ATStype,
      {
        [tagType: string]: ATSTagFilter;
      }
    >
  >;
  excludeExpiredCertifications?: boolean;
  contactCategory?: ContactCategorySieve;
  companyDealState?: CompanyDealStateFilter;
  customFieldFilters?: Record<string, CustomFieldFilter>;
};

export type ProfilesViewSettingsAction =
  | {
      type: 'replaceAll';
      settings: ProfilesViewSettings;
    }
  | {
      type: 'setAvailabilityDate';
      value?: AvailabilityDateFilter | undefined;
    }
  | {
      type: 'setMedicalExamination';
      value?: MedicalExaminationFilter | undefined;
    }
  | {
      type: 'setConstructionCard';
      value?: ConstructionCardFilter | undefined;
    }
  | {
      type: 'setAtsTagFilter';
      ats: ATStype;
      tagType: string;
      value?: ATSTagFilter | undefined;
    }
  | {
      type: 'setExcludeExpiredCertifications';
      value?: boolean | undefined;
    }
  | {
      type: 'setCompanyDealState';
      value?: CompanyDealStateFilter | undefined;
    }
  | {
      type: 'setCustomFieldFilter';
      customFieldId: string;
      value?: CustomFieldFilter | undefined;
    };

export function emptySettings(settings: ProfilesViewSettings): boolean {
  return getActiveFilterCount(settings) === 0;
}

export function getActiveFilterCount(state: ProfilesViewSettings): number {
  let result = 0;
  _.each(sectionDefinitions, ({ isActive }) => {
    if (isActive(state)) {
      result += 1;
    }
  });
  if (state.companyDealState) {
    result += 1;
  }
  return result;
}

export const profilesViewSettingsReducer: Reducer<
  ProfilesViewSettings,
  ProfilesViewSettingsAction
> = (current, action): ProfilesViewSettings => {
  if (action.type === 'replaceAll') {
    return action.settings;
  }
  if (action.type === 'setAtsTagFilter') {
    const { ats, tagType, value } = action;
    return {
      ...current,
      atsTagFilters: {
        ...current.atsTagFilters,
        [ats]: {
          ...(current.atsTagFilters[ats] || {}),
          [tagType]: value,
        },
      },
    };
  }
  if (action.type === 'setAvailabilityDate') {
    return {
      ...current,
      availabilityDate: action.value,
    };
  }
  if (action.type === 'setMedicalExamination') {
    return {
      ...current,
      medicalExamination: action.value,
    };
  }
  if (action.type === 'setConstructionCard') {
    return {
      ...current,
      constructionCard: action.value,
    };
  }
  if (action.type === 'setExcludeExpiredCertifications') {
    return {
      ...current,
      excludeExpiredCertifications: action.value,
    };
  }
  if (action.type === 'setCompanyDealState') {
    return {
      ...current,
      companyDealState: action.value,
    };
  }
  if (action.type === 'setCustomFieldFilter') {
    const { customFieldId, value } = action;
    const customFieldFilters = {
      ...current.customFieldFilters,
    };
    if (!value) {
      delete customFieldFilters[customFieldId];
    } else {
      customFieldFilters[customFieldId] = value;
    }
    return {
      ...current,
      customFieldFilters,
    };
  }
  return current;
};

export function getCriteriaFromSettings({
  availabilityDate,
  medicalExamination,
  constructionCard,
  atsTagFilters,
  excludeExpiredCertifications,
  contactCategory,
  companyDealState,
  customFieldFilters,
}: ProfilesViewSettings): SearchCriteria {
  const freeObject = {} as {
    context?: {
      isCompanySearch?: boolean;
    };
    criteria?: {
      availabilityFilters?: AvailabilityFilter;
      categoryFilters?: CategoryFilter[];
      companyDealStateFilters?: CompanyDealStateFilter[];
      customFilters?: APIFilter[];
    };
    shouldExcludeExpiredCertifications?: boolean;
  } & Record<string, { tagIds: string[] }>;
  _.each(atsTagFilters, (filterMap, ats) => {
    const allTagIds = [] as string[];
    _.each(filterMap || {}, (value) => {
      if (!value) {
        return;
      }
      const { operator, tagIds } = value;
      if (operator === 'and') {
        allTagIds.push(...tagIds);
      }
      if (operator === 'or') {
        const tagQuery = (tagIds || []).join(';');
        if (tagQuery) {
          allTagIds.push(tagQuery);
        }
      }
    });
    if (!_.isEmpty(allTagIds)) {
      freeObject[ATS_FILTERS_KEY_BY_TYPE[ats as ATStype]] = {
        tagIds: allTagIds,
      };
    }
  });

  if (excludeExpiredCertifications) {
    freeObject.shouldExcludeExpiredCertifications = true;
  }

  if (availabilityDate || medicalExamination || constructionCard) {
    const availabilityFilters = {} as AvailabilityFilter;
    if (availabilityDate?.type === 'absolute') {
      availabilityFilters.nextAvailabilityDateFilter = {
        maxDate: availabilityDate.date,
      };
    }
    if (availabilityDate?.type === 'relative') {
      availabilityFilters.nextAvailabilityDateFilter = {
        maxDelayInDays: availabilityDate.days,
      };
    }
    if (medicalExamination === 'invalid') {
      availabilityFilters.medicalExaminationExpirationDateFilter = {
        maxDate: new Date(),
        strict: true,
        orNull: true,
      };
    }
    if (medicalExamination === 'valid') {
      availabilityFilters.medicalExaminationExpirationDateFilter = {
        minDate: new Date(),
      };
    }
    if (constructionCard === 'invalid') {
      availabilityFilters.constructionCardExpirationDateFilter = {
        maxDate: new Date(),
        strict: true,
        orNull: false,
      };
    }
    if (constructionCard === 'valid') {
      availabilityFilters.constructionCardExpirationDateFilter = {
        minDate: new Date(),
      };
    }
    freeObject.criteria ??= {};
    freeObject.criteria.availabilityFilters = availabilityFilters;
  }

  if (contactCategory) {
    freeObject.criteria ??= {};
    freeObject.criteria.categoryFilters = [
      {
        filterType: 'in',
        categories: [contactCategory],
      },
    ];
    if (contactCategory.type === 'company') {
      freeObject.context ??= {};
      freeObject.context.isCompanySearch = true;
    }
  }

  if (companyDealState) {
    freeObject.criteria ??= {};
    freeObject.criteria.companyDealStateFilters = [companyDealState];
  }

  _.each(customFieldFilters ?? {}, (filter) => {
    freeObject.criteria ??= {};
    freeObject.criteria.customFilters ??= [];
    freeObject.criteria.customFilters.push(
      getAPIFilterFromCustomFieldFilter(filter),
    );
  });

  if (_.isEmpty(freeObject)) {
    return {};
  }

  return { free: JSON.stringify(freeObject) };
}

function getAPIFilterFromCustomFieldFilter(
  filter: CustomFieldFilter,
): APIFilter {
  switch (filter.type) {
    case 'enum': {
      return {
        id: 'customField',
        params: {
          type: 'enum',
          value: filter.value,
          policy: filter.policy,
          clientFilterKey: filter.clientCustomFieldId,
        },
      };
    }
    default: {
      throw new Error(`Unhandled filter type ${filter.type}`);
    }
  }
}
