import React, { Component, Suspense } from 'react';
import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/browser';

import AppRouter from './AppRouter';
import { createClient } from './configureApollo';
import { AppPluginPubSubProvider } from './common/appPluginMessaging/useAppPluginPubSub';
import { maybeCreateAppPluginPubSubFromApp } from './common/appPluginMessaging/AppPluginPubSub';

import GoogleAuth2 from './context/GoogleAuth2';
import Layout from './containers/Auth/Layout';
import ErrorBoundary from './ErrorBoundary';
import isErrorIgnored from './common/errors/errors';

const loadHubspot = () => {
  if (window.location.href?.includes('humanskills')) {
    return;
  }
  try {
    (function unnamed() {
      const d = document;
      const s = d.createElement('script');
      s.src = 'https://js.hs-scripts.com/1768483.js';
      s.async = 1;
      s.defer = 1;
      s.id = 'hs-script-loader';
      s.type = 'text/javascript';
      d.getElementsByTagName('body')[0].appendChild(s);
    })();
  } catch (e) {
    // console.error(e);
  }
};

const loadSentry = () => {
  Sentry.init({
    dsn: 'https://cb9d8c302e884939a71150a338355540@sentry.io/1217869',
    environment: process.env.NODE_ENV,
    enabled: process.env.NODE_ENV === 'production',
    beforeSend: (event, { originalException }) => {
      if (isErrorIgnored(originalException)) {
        return null;
      }
      return event;
    },
  });
};

class App extends Component {
  constructor() {
    super();
    this.state = {
      apolloClient: null,
      error: false,
    };
  }

  componentDidMount() {
    this.getApolloClient();
    loadHubspot();
    loadSentry();
  }

  async getApolloClient() {
    try {
      const client = createClient('Web app');
      this.setState({ apolloClient: client });
    } catch (error) {
      console.error('App Apollo error', error);
      this.setState({ error: true });
    }
  }

  render() {
    const { apolloClient, error } = this.state;
    // Loading schema and Apollo client
    if (!apolloClient && !error) {
      return null;
    }
    // Something went wrong
    if (error) {
      return (
        <Layout
          title='Something went wrong'
          subtitle='Please try to reload your page'
        />
      );
    }

    const pubSub = maybeCreateAppPluginPubSubFromApp();
    return (
      <Suspense fallback={null}>
        <AppPluginPubSubProvider pubSub={pubSub}>
          <ApolloProvider client={apolloClient}>
            <GoogleAuth2>
              <ErrorBoundary>
                <AppRouter />
              </ErrorBoundary>
            </GoogleAuth2>
          </ApolloProvider>
        </AppPluginPubSubProvider>
      </Suspense>
    );
  }
}

export default App;
