import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import Attachment from '../../graphql/fragments/Attachment';

export const query = gql`
  query getProfileAttachment($id: ID!, $profileId: ID) {
    attachment(id: $id, profileId: $profileId) {
      ...Attachment
      file {
        extension
        content
      }
    }
  }
  ${Attachment}
`;

export default graphql(query, {
  options: ({ id, profileId }) => ({
    variables: {
      id,
      profileId,
    },
  }),
  props: ({ data: { loading, attachment, error } }) => ({
    attachmentLoading: loading,
    attachment,
    error,
  }),
});
