import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';
import GenericTextInput from '@/components/Common/GenericTextInput';
import classnames from 'classnames';
import ColorPicker from '@/components/ColorPicker/ColorPicker';
import styles from './DescriptionThemeTab.module.less';
import { Theme } from './DescriptionModal';

interface CareerPageDescriptionThemeTabProps {
  theme: Theme;
  onTheme: (theme: Theme) => void;
}

const COLOR_PALETTE = [
  '#ffffff',
  '#000000',
  '#23c9ff',
  '#00c49a',
  '#ed6a5e',
  '#f8e16c',
  '#000F91',
  '#3A606E',
  '#CFCBCA',
  '#731DD8',
];

const CareerPageDescriptionThemeTab: React.FC<
  CareerPageDescriptionThemeTabProps
> = ({ theme, onTheme }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.themeContainer}>
      <h4>{t('settings.careerPage.description.modal.general')}</h4>
      <div className={styles.inputContainer}>
        <div className={styles.inputLabel}>
          {t('settings.careerPage.description.modal.background')}
        </div>
        <ColorPalette
          currentColor={theme?.background || ''}
          onColor={(color: string) =>
            onTheme({
              ...theme,
              background: color,
            })
          }
        />
        <div className={styles.colorInputContainer}>
          <GenericTextInput
            small
            value={theme?.background || '#f3f4f8'}
            placeholder='#ffffff'
            onChange={({ target }) =>
              onTheme({
                ...theme,
                background: target.value,
              })
            }
          />
          <ColorPicker
            selectedColor={theme?.background || '#f3f4f8'}
            setSelectedColor={(color) =>
              onTheme({
                ...theme,
                background: color,
              })
            }
            className={styles.colorOption}
          />
        </div>
      </div>
      <div className={styles.inputContainer}>
        <div className={styles.inputLabel}>
          {t('settings.careerPage.description.modal.font')}
        </div>
        <ColorPalette
          currentColor={theme?.font || ''}
          onColor={(color: string) =>
            onTheme({
              ...theme,
              font: color,
            })
          }
        />
        <div className={styles.colorInputContainer}>
          <GenericTextInput
            small
            value={theme?.font || '#1f2e77'}
            placeholder='#ffffff'
            onChange={({ target }) => {
              onTheme({
                ...theme,
                font: target.value,
              });
            }}
          />
          <ColorPicker
            selectedColor={theme?.font || '#1f2e77'}
            setSelectedColor={(color) =>
              onTheme({
                ...theme,
                font: color,
              })
            }
            className={styles.colorOption}
          />
        </div>
      </div>
      <h4>{t('settings.careerPage.description.modal.jobCards')}</h4>
      <div className={styles.inputContainer}>
        <div className={styles.inputLabel}>
          {t('settings.careerPage.description.modal.background')}
        </div>
        <ColorPalette
          currentColor={theme?.jobs?.background || ''}
          onColor={(color: string) =>
            onTheme({
              ...theme,
              jobs: {
                ...theme.jobs,
                background: color,
              },
            })
          }
        />
        <div className={styles.colorInputContainer}>
          <GenericTextInput
            small
            value={theme?.jobs?.background || '#ffffff'}
            placeholder='#ffffff'
            onChange={({ target }) => {
              onTheme({
                ...theme,
                jobs: {
                  ...theme.jobs,
                  background: target.value,
                },
              });
            }}
          />
          <ColorPicker
            selectedColor={theme?.jobs?.background || '#ffffff'}
            setSelectedColor={(color) =>
              onTheme({
                ...theme,
                jobs: {
                  ...theme.jobs,
                  background: color,
                },
              })
            }
            className={styles.colorOption}
          />
        </div>
      </div>
      <div className={styles.inputContainer}>
        <div className={styles.inputLabel}>
          {t('settings.careerPage.description.modal.font')}
        </div>
        <ColorPalette
          currentColor={theme?.jobs?.font || ''}
          onColor={(color: string) =>
            onTheme({
              ...theme,
              jobs: {
                ...theme.jobs,
                font: color,
              },
            })
          }
        />
        <div className={styles.colorInputContainer}>
          <GenericTextInput
            small
            value={theme?.jobs?.font || '#1f2e77'}
            placeholder='#ffffff'
            onChange={({ target }) => {
              onTheme({
                ...theme,
                jobs: {
                  ...theme.jobs,
                  font: target.value,
                },
              });
            }}
          />
          <ColorPicker
            selectedColor={theme?.jobs?.font || '#1f2e77'}
            setSelectedColor={(color) =>
              onTheme({
                ...theme,
                jobs: {
                  ...theme.jobs,
                  font: color,
                },
              })
            }
            className={styles.colorOption}
          />
        </div>
      </div>
      <div className={styles.inputContainer}>
        <div className={styles.inputLabel}>
          {t('settings.careerPage.description.modal.border')}
        </div>
        <ColorPalette
          currentColor={theme?.jobs?.border?.color || ''}
          onColor={(color: string) =>
            onTheme({
              ...theme,
              jobs: {
                ...theme.jobs,
                border: {
                  ...theme.jobs?.border,
                  color,
                },
              },
            })
          }
        />
        <div className={styles.colorInputContainer}>
          <GenericTextInput
            small
            value={theme?.jobs?.border?.color || '#dae0f4'}
            placeholder='#ffffff'
            onChange={({ target }) => {
              onTheme({
                ...theme,
                jobs: {
                  ...theme.jobs,
                  border: {
                    ...theme.jobs?.border,
                    color: target.value,
                  },
                },
              });
            }}
          />
          <ColorPicker
            selectedColor={theme?.jobs?.border?.color || '#dae0f4'}
            setSelectedColor={(color) =>
              onTheme({
                ...theme,
                jobs: {
                  ...theme.jobs,
                  border: {
                    ...theme.jobs?.border,
                    color,
                  },
                },
              })
            }
            className={styles.colorOption}
          />
        </div>
        <div className={styles.borderRadiusSelector}>
          <i className='ri-rounded-corner ri-2x' />
          <GenericTextInput
            small
            type='number'
            value={theme?.jobs?.border?.radius?.split('px')?.[0] || 2}
            placeholder='2'
            onChange={({ target }) => {
              onTheme({
                ...theme,
                jobs: {
                  ...theme.jobs,
                  border: {
                    ...theme.jobs?.border,
                    radius: `${target.value}px`,
                  },
                },
              });
            }}
          />
          px
        </div>
      </div>
      <h4>{t('settings.careerPage.description.modal.footer')}</h4>
      <div className={styles.inputContainer}>
        <div className={styles.inputLabel}>
          {t('settings.careerPage.description.modal.background')}
        </div>
        <ColorPalette
          currentColor={theme?.footer?.background || ''}
          onColor={(color: string) =>
            onTheme({
              ...theme,
              footer: {
                ...theme.footer,
                background: color,
              },
            })
          }
        />
        <div className={styles.colorInputContainer}>
          <GenericTextInput
            small
            value={theme?.footer?.background || '#f3f4f8'}
            placeholder='#ffffff'
            onChange={({ target }) =>
              onTheme({
                ...theme,
                footer: {
                  ...theme.footer,
                  background: target.value,
                },
              })
            }
          />
          <ColorPicker
            selectedColor={theme?.footer?.background || '#f3f4f8'}
            setSelectedColor={(color) =>
              onTheme({
                ...theme,
                footer: {
                  ...theme.footer,
                  background: color,
                },
              })
            }
            className={styles.colorOption}
          />
        </div>
      </div>
      <div className={styles.inputContainer}>
        <div className={styles.inputLabel}>
          {t('settings.careerPage.description.modal.font')}
        </div>
        <ColorPalette
          currentColor={theme?.footer?.font || ''}
          onColor={(color: string) =>
            onTheme({
              ...theme,
              footer: {
                ...theme.footer,
                font: color,
              },
            })
          }
        />
        <div className={styles.colorInputContainer}>
          <GenericTextInput
            small
            value={theme?.footer?.font || '#1f2e77'}
            placeholder='#ffffff'
            onChange={({ target }) => {
              onTheme({
                ...theme,
                footer: {
                  ...theme.footer,
                  font: target.value,
                },
              });
            }}
          />
          <ColorPicker
            selectedColor={theme?.footer?.font || '#1f2e77'}
            setSelectedColor={(color) =>
              onTheme({
                ...theme,
                footer: {
                  ...theme.footer,
                  font: color,
                },
              })
            }
            className={styles.colorOption}
          />
        </div>
      </div>
    </div>
  );
};

interface ColorPaletteProps {
  currentColor: string;
  onColor: (color: string) => void;
}

const ColorPalette: React.FC<ColorPaletteProps> = ({
  currentColor,
  onColor,
}) => {
  return (
    <div className={styles.colorsContainer}>
      {_.map(COLOR_PALETTE, (color) => (
        <div
          key={color}
          className={classnames(
            styles.colorOption,
            currentColor === color ? styles.selectedColor : '',
          )}
          style={{ backgroundColor: color }}
          onClick={() => onColor(color)}
        />
      ))}
    </div>
  );
};

export default CareerPageDescriptionThemeTab;
