import gql from 'graphql-tag';
import SearchPoolJobWorkflow from '@/graphql/fragments/SearchPoolJobWorkflow';
import Attachment from './Attachment';

export default gql`
  fragment SearchPoolJob on SearchPoolJob {
    id
    hide
    isArchived
    metadata {
      key
      value
    }
    attachments {
      ...Attachment
      file {
        extension
        url
      }
    }
    attachedSequences {
      isDefault
      sequenceId
    }
    data {
      sourceJobId
      title
      status
      openingDate
    }
    owner {
      firstname
      lastname
      email
    }
    profilesCount
    recommendationsProject {
      id
    }
    ... on RevealGreenhouseJob {
      applicationReviewProject {
        id
      }
    }
    ... on RevealLeverJob {
      applicationReviewProject {
        id
      }
    }
    workflows {
      ...SearchPoolJobWorkflow
    }
    linkedATSJobId
    linkedATSUserId
    jobPostings {
      id
      published
      title {
        default
        fr
        en
      }
    }
    foldering {
      department {
        id
      }
      section {
        id
      }
      subsection {
        id
      }
    }
    targets {
      companies {
        id
        name
      }
    }
    dynamicImports {
      id
      creationDate
      creationTimestamp
      type
      descriptor {
        unipileSharedAccountId
        sourceJobId
      }
      synchronizationSettings {
        type
      }
      synchronizationStatus {
        isSynchronizing
      }
    }
    positionDescription {
      description {
        type
        ... on MultiSectionsJobPostingDescription {
          sections {
            type
            ... on SimpleJobPostingDescriptionSection {
              title {
                ...TranslatableText
              }
              content {
                ...TranslatableText
              }
            }
            ... on VideoJobPostingDescriptionSection {
              url
            }
            ... on ImageJobPostingDescriptionSection {
              name
              file {
                url
              }
            }
          }
        }
      }
    }
  }
  ${SearchPoolJobWorkflow}
  ${Attachment}
`;
