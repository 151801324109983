/* eslint-disable no-constant-condition */
import React, { FC, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';
import classNames from 'classnames';

import useClientId from '@/hooks/router/useClientId';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';
import useHumanContactCategorySubtypes from '@/graphql/hooks/clients/useHumanCategorySubtypes';
import { getTranslatedText } from '@/common/helpers/translatableText';
import { getShortLanguage } from '@/common/utils/i18n';
import { capitalizeFirstLetter } from '@/common/utils/string';

import NewHumanButton from '../NewHumanButton';

import styles from './SubHeader.module.less';

type SubHeaderProps = {
  initialValue?: [string] | [string, null];
  onValue: (value: [string] | [string, null]) => void;
};

const SubHeader: FC<SubHeaderProps> = ({ initialValue, onValue }) => {
  const { t, i18n } = useTranslation();
  const { resolvedLanguage } = i18n;
  const lang = getShortLanguage(resolvedLanguage);
  const clientId = useClientId();
  const { data } = useClientPermissions(clientId);
  const { canEditHumanContactSubtypes } = data?.client.permissions || {};
  const { humanContactCategorySubtypes } = useHumanContactCategorySubtypes();
  const [humanSubtype, setHumanSubtype] = useState<string | undefined>(
    initialValue?.[0],
  );

  const selectSubtype = useCallback(
    (id: string, isDefault?: boolean) => {
      setHumanSubtype(id);
      onValue(isDefault ? [id, null] : [id]);
    },
    [onValue],
  );

  return (
    <div className={styles.subHeader}>
      <div className={styles.left}>
        {canEditHumanContactSubtypes &&
        !_.isEmpty(humanContactCategorySubtypes) ? (
          <ul className={styles.subtypes}>
            {_.map(humanContactCategorySubtypes, ({ id, title, isDefault }) => (
              <li
                className={classNames(styles.subtype, {
                  [styles.selected]:
                    id === humanSubtype || (!humanSubtype && isDefault),
                })}
                key={id}
              >
                <button
                  type='button'
                  onClick={() => selectSubtype(id, isDefault)}
                >
                  {clientId === 'chantal-baudron' ? (
                    id === 'hiring' ? 'Candidats' : 'Interlocuteurs'
                  ) : capitalizeFirstLetter(
                    t('reveal.searchView.subtypedContact', {
                      count: 10,
                      subtype: getTranslatedText(lang, title).toLowerCase(),
                    }),
                  )}
                </button>
              </li>
            ))}
          </ul>
        ) : (
          <div className={styles.title}>{t('header.parameters.contacts')}</div>
        )}
      </div>
      <NewHumanButton />
    </div>
  );
};

export default SubHeader;
