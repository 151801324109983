import React, { ReactNode, useState } from 'react';
import { Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import useClientId from '@/hooks/router/useClientId';
import useClient from '@/graphql/hooks/clients/useClient';
import GenericModal from '@/components/Common/GenericModal';
import GenericButton from '@/components/Common/GenericButton';
import AskDemoModal from '@/components/FreeTrial/AskDemoModal';
import logAction from '@/common/logAction';
import PaymentLink from '@/components/FreeTrial/PaymentLink';
import useCurrentUser from '@/graphql/hooks/users/useCurrentUser';

import { isInExpiredFreeTrial } from './helpers';

import styles from './FreeTrialGuard.module.less';

type Props = {
  children: ReactNode;
};

const FreeTrialGuard = ({ children }: Props) => {
  const { t } = useTranslation();
  const { user } = useCurrentUser();
  const clientId = useClientId();
  const { data, loading } = useClient(clientId);
  const [demoModalOpen, setDemoModalOpen] = useState(false);

  if (loading) {
    return (
      <div className='reveal-view loading'>
        <Loader active inline='centered' size='large' />
      </div>
    );
  }

  if (user?.isAdmin || !isInExpiredFreeTrial(data.client)) {
    return children;
  }

  return (
    <>
      <GenericModal className={styles.modal} open>
        <h1 className={styles.title}>
          {t('reveal.freeTrial.expiredModal.title')}
        </h1>
        <p className={styles.paragraph}>
          {t('reveal.freeTrial.expiredModal.paragraph')}
        </p>
        <PaymentLink size='big' className={styles.cta} />
        <GenericButton
          primacy='secondary'
          size='big'
          className={styles.cta}
          onClick={() => {
            logAction({ type: 'reveal-open-ask-demo-modal' });
            setDemoModalOpen(true);
          }}
        >
          {t('reveal.freeTrial.expiredModal.askDemo')}
        </GenericButton>
      </GenericModal>
      <AskDemoModal open={demoModalOpen} setOpen={setDemoModalOpen} />
    </>
  );
};

export default FreeTrialGuard;
