import React, { useMemo, Dispatch, SetStateAction, useEffect } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';

import SegmentationDropdown from '@/components/PipelineSegmentation/SegmentationDropdown';
import BoardIcon from '@/components/Reveal/Icons/BoardIcon';
import TableIcon from '@/components/Reveal/Icons/TableIcon';
import GenericDropdown from '@/components/Common/GenericDropdown';
import GenericButton from '@/components/Common/GenericButton';
import DropdownMenuPanel from '@/components/Common/DropdownMenuPanel';
import Dots from '@/components/Common/Icons/Dots';
import { DropdownMenuOption } from '@/components/Common/DropdownMenuPanel/DropdownMenuPanel';
import useClientId from '@/hooks/router/useClientId';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';
import { Segmentation } from '@/components/PipelineSegmentation/pipelineSegmentation';

import ArrowRight from '@/components/Reveal/Icons/ArrowRight';
import ArrowLeft from '@/components/Reveal/Icons/ArrowLeft';
import { WEEK_IN_MS, getWeekNumber } from '@/common/dates';
import { useMutation } from '@apollo/client';
import { TRIGGER_DYNAMIC_IMPORT } from '@/graphql/DynamicImports';
import useSearchPoolJob from '@/graphql/hooks/searchPoolJobs/useSearchPoolJob';
import GenericTooltip from '@/components/Common/GenericTooltip';
import useSharedMessagingAccounts from '@/graphql/hooks/clients/useSharedServiceAccount';
import { Link } from 'react-router-dom';
import ButtonWithDropdown from '@/components/ButtonWithDropdown/ButtonWithDropdown';
import styles from './ProjectProfilesViewSettingsHeader.module.less';
import ProfilesViewFilterSettings from './ProfilesViewFilterSettings';

interface ProjectProfilesViewSettingsHeaderProps {
  segmentations: Segmentation[];
  segmentation: Segmentation;
  onSelectSegmentation: (segmentationId: string) => void;
  displayType: string;
  onSelectDisplayType: (displayType: string) => void;
  condensedInterested: boolean;
  setCondensedInterested: Dispatch<SetStateAction<boolean>>;
  weekDiffInMS?: number;
  onWeekDiffChange?: (diff: number) => void;
  missionId: string;
  dynamicImportPolling: number;
  onDynamicImportPollingChange: (value: number) => void;
}

const ProjectProfilesViewSettingsHeader: React.FC<
  ProjectProfilesViewSettingsHeaderProps
> = ({
  segmentations,
  segmentation,
  onSelectSegmentation,
  displayType,
  onSelectDisplayType,
  condensedInterested,
  setCondensedInterested,
  weekDiffInMS = 0,
  onWeekDiffChange,
  missionId,
  dynamicImportPolling,
  onDynamicImportPollingChange,
}) => {
  const { t } = useTranslation();
  const clientId = useClientId();
  const permissions = useClientPermissions(clientId).data?.client?.permissions;
  const showKanbanAvailability = permissions?.nextAvailability;
  const { sharedMessagingAccounts } = useSharedMessagingAccounts();

  const [triggerImport] = useMutation(TRIGGER_DYNAMIC_IMPORT, {
    refetchQueries: ['getSearchPoolJobProfileResults', 'getSearchPoolJob'],
  });

  const { data: jobData } = useSearchPoolJob(
    'reveal',
    missionId,
    dynamicImportPolling,
  );

  const { job } = jobData?.searchPool || {};

  const jobDynamicImport = useMemo(() => {
    return job?.dynamicImports?.[0];
  }, [job]);

  const dynamicImportAccount = useMemo(() => {
    return sharedMessagingAccounts?.find(
      (account) =>
        account.id === jobDynamicImport?.descriptor?.unipileSharedAccountId,
    );
  }, [jobDynamicImport, sharedMessagingAccounts]);

  const hasDynamicImportSynchronizing = useMemo(
    () =>
      _.some(job?.dynamicImports, (dynamicImport) => {
        return dynamicImport.synchronizationStatus?.isSynchronizing;
      }),
    [job],
  );

  useEffect(() => {
    if (hasDynamicImportSynchronizing && dynamicImportPolling === 0) {
      onDynamicImportPollingChange(4000);
    }
    if (!hasDynamicImportSynchronizing) {
      onDynamicImportPollingChange(0);
    }
  }, [
    hasDynamicImportSynchronizing,
    dynamicImportPolling,
    onDynamicImportPollingChange,
  ]);

  const filteredSegmentations = useMemo(() => {
    if (showKanbanAvailability) {
      return segmentations;
    }
    return _.filter(
      segmentations,
      (segmentationItem) => !segmentationItem.featureFlag,
    );
  }, [showKanbanAvailability, segmentations]);

  const showCondensedInterested =
    segmentation.showExtraOptions?.condensedInterested || false;

  const missionSegmentationDropdownOptions = useMemo(() => {
    const result = [] as DropdownMenuOption[];
    if (showCondensedInterested) {
      result.push({
        id: 'condensedInterested',
        label: t(
          `reveal.toggles.${
            condensedInterested ? 'displaySubSteps' : 'hideSubSteps'
          }`,
        ),
      });
    }
    return result;
  }, [showCondensedInterested, condensedInterested, t]);
  const date = new Date(Date.now() + weekDiffInMS);
  const weekNumber = getWeekNumber(date);

  return (
    <div className={styles.projectProfileViewSettingsHeader}>
      <SegmentationDropdown
        segmentations={filteredSegmentations}
        segmentation={segmentation}
        onSelectSegmentation={(segmentationId: string) =>
          onSelectSegmentation(segmentationId)
        }
        showKanban
      />
      <div className={styles.right}>
        {_.map(job?.dynamicImports, (dynamicImport) => (
          <div key={dynamicImport.id}>
            {dynamicImport.synchronizationStatus?.isSynchronizing ||
            dynamicImportAccount?.status !== 'valid' ? (
              <GenericTooltip
                rich
                hoverable
                trigger={
                  <GenericButton
                    primacy='secondary'
                    className={styles.disabledButton}
                  >
                    {dynamicImportAccount?.status !== 'valid'
                      ? t(
                          `reveal.missions.mission.settingsTab.imports.resynchronize`,
                        )
                      : t(
                          `reveal.missions.mission.settingsTab.imports.synchronizing`,
                        )}
                  </GenericButton>
                }
              >
                {dynamicImportAccount?.status !== 'valid' ? (
                  <>
                    {t(
                      `reveal.missions.mission.settingsTab.imports.invalidAccount`,
                    )}
                    <Link to={`/client/${clientId}/automation-integrations`}>
                      {t(`reveal.missions.mission.settingsTab.imports.here`)}
                    </Link>
                  </>
                ) : (
                  <>
                    {t(
                      `reveal.missions.mission.settingsTab.imports.synchronizingDetails`,
                    )}
                  </>
                )}
              </GenericTooltip>
            ) : (
              <ButtonWithDropdown
                primacy='secondary'
                onClick={() =>
                  triggerImport({
                    variables: {
                      searchPoolId: 'reveal',
                      input: {
                        missionId,
                        dynamicImportId: dynamicImport.id,
                        options: ['not-archived-only'],
                      },
                    },
                  })
                }
                label={t(
                  `reveal.missions.mission.settingsTab.imports.resynchronize`,
                )}
                onSelect={(id) => {
                  if (id !== 'synchronize-with-archived') {
                    return;
                  }
                  triggerImport({
                    variables: {
                      searchPoolId: 'reveal',
                      input: {
                        missionId,
                        dynamicImportId: dynamicImport.id,
                      },
                    },
                  });
                }}
                options={[
                  {
                    id: 'synchronize-with-archived',
                    label: t(
                      `reveal.missions.mission.settingsTab.imports.resynchronize-with-archived`,
                    ),
                  },
                ]}
              />
            )}
          </div>
        ))}
        <ProfilesViewFilterSettings />
        {segmentation.subtype === 'date' && onWeekDiffChange && (
          <div className={styles.weekSelectorContainer}>
            <div
              className={styles.prevSelector}
              onClick={() => onWeekDiffChange(weekDiffInMS - WEEK_IN_MS)}
            >
              <ArrowLeft />
            </div>
            <div
              className={styles.nextSelector}
              onClick={() => onWeekDiffChange(weekDiffInMS + WEEK_IN_MS)}
            >
              <ArrowRight />
            </div>
            <div className={styles.currentWeek}>
              {t('common.week')}&nbsp;
              {weekNumber}
            </div>
          </div>
        )}
        {segmentation.showDisplayTypeSelector && (
          <Dropdown
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            direction='left'
            trigger={
              displayType === 'kanban' ? (
                <div className={styles.displayTypeDropdownTrigger}>
                  <BoardIcon />
                  {t('reveal.toggles.viewAsKanban')}
                </div>
              ) : (
                <div className={styles.displayTypeDropdownTrigger}>
                  <TableIcon />
                  {t('reveal.toggles.viewAsTable')}
                </div>
              )
            }
            className={styles.displayTypeDropdown}
          >
            <Dropdown.Menu>
              <Dropdown.Item
                text={t('reveal.toggles.viewAsTable')}
                onClick={() => onSelectDisplayType('table')}
              />
              <Dropdown.Item
                text={t('reveal.toggles.viewAsKanban')}
                onClick={() => onSelectDisplayType('kanban')}
              />
            </Dropdown.Menu>
          </Dropdown>
        )}
        {!_.isEmpty(missionSegmentationDropdownOptions) && (
          <GenericDropdown
            position='right'
            Trigger={({ onClick }) => (
              <GenericButton primacy='tertiary' onClick={onClick} isIcon>
                <Dots />
              </GenericButton>
            )}
          >
            <DropdownMenuPanel
              options={missionSegmentationDropdownOptions}
              onSelect={(id) => {
                if (id === 'condensedInterested') {
                  setCondensedInterested(!condensedInterested);
                }
              }}
            />
          </GenericDropdown>
        )}
      </div>
    </div>
  );
};

export default ProjectProfilesViewSettingsHeader;
