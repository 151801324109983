import gql from 'graphql-tag';
import { CustomFieldValue } from '@/common/customFields';
import SearchPoolJob from './fragments/SearchPoolJob';
import CustomFieldsValues from './fragments/CustomFieldsValues';

export interface ISearchPoolJob {
  id: string;
  hide?: boolean;
  owner?: {
    firstname?: string;
    lastname?: string;
    email?: string;
  };
  data?: {
    title: string;
    status: string;
    openingDate: string;
  };
  recommendationsProject?: {
    id: string;
  };
  applicationReviewProject?: {
    id: string;
  };
  stats?: {
    applicants?: number;
    recommendations?: number;
    importedProfiles?: number;
  };
  profilesCount?: number;
  isArchived?: boolean;
  customFields: CustomFieldValue[];
  foldering?: {
    department: { id: string };
    subsection: { id: string };
    section: { id: string };
  };
  targets?: {
    companies?: { id: string; name: string }[];
  };
  dynamicImports?: {
    id: string;
    creationDate: string;
    creationTimestamp: number;
    type: string;
    descriptor: {
      unipileSharedAccountId: string;
      sourceJobId: string;
    };
    synchronizationSettings: {
      type: 'manual';
    };
  };
}

export interface SearchPoolJobQueryResult {
  searchPool: {
    id: string;
    jobs?: ISearchPoolJob[];
  };
}
export interface SearchPoolJobQueryVariables {
  searchPoolId: string;
  filters?: SearchPoolJobFiltersInput;
}

export type SearchPoolJobFiltersInput = {
  includeAll?: boolean;
  userEmail?: string;
  ATSOnly?: boolean;
  activeOnly?: boolean;
  departmentId?: string;
  sectionId?: string;
  subsectionId?: string;
  type?: string;
  missionCategory?: {
    type: string;
  };
  unlistedMode?: UnlistedMode;
  title?: string;
};

type UnlistedMode = 'noUnlisted' | 'includeUnlisted' | 'unlistedOnly';

const SEARCH_POOL_JOBS = gql`
  query getSearchPoolJobs(
    $searchPoolId: ID!
    $filters: SearchPoolJobFiltersInput
  ) {
    searchPool(id: $searchPoolId) {
      id
      jobs(filters: $filters) {
        ...SearchPoolJob
        stats {
          recommendations
        }
      }
    }
  }
  ${SearchPoolJob}
`;

export const SEARCH_POOL_JOBS_WITH_CUSTOM_FIELDS = gql`
  query getSearchPoolJobs(
    $searchPoolId: ID!
    $filters: SearchPoolJobFiltersInput
  ) {
    searchPool(id: $searchPoolId) {
      id
      jobs(filters: $filters) {
        ...SearchPoolJob
        stats {
          recommendations
        }
        customFields {
          ...CustomFieldsValues
        }
      }
    }
  }
  ${SearchPoolJob}
  ${CustomFieldsValues}
`;

export default SEARCH_POOL_JOBS;
