import _ from 'underscore';
import React, { FC, Dispatch, ReactNode } from 'react';

import { Client } from '@/types/client';
import { ATStype } from '@/common/reveal';
import Check from '@/components/Common/Icons/Check';
import Cross from '@/components/Common/Icons/Cross';

import {
  ProfilesViewSettings,
  ProfilesViewSettingsAction,
} from '@/context/ProfilesViewSettingsContext/helpers';
import ATSTagFilterPanel from '../ATSTagFilterPanel';
import AvailabilityDateFilterPanel from '../AvailabilityDateFilterPanel';
import WorkingDaysFilterPanel from '../WorkingDays/WorkingDaysFilterPanel';
import CertificationFilterPanel from '../CertificationFilterPanel';
import MedicalExaminationFilterPanel from '../MedicalExaminationFilterPanel';
import ConstructionCardFilterPanel from '../ConstructionCardFilterPanel';

export type PanelComponentProps = {
  onSubmit?: () => void;
};

export type FilterDefinition = {
  i18nKey: string;
  Component: FC<PanelComponentProps>;
  isActive: (state: ProfilesViewSettings) => boolean;
  isAvailable: (context: { client?: Client }) => boolean;
  isAlwaysShown?: (context: { client?: Client }) => boolean;
  clear: (dispatch: Dispatch<ProfilesViewSettingsAction>) => void;
  getPreview: (state: ProfilesViewSettings) => ReactNode | null;
  serialize: (state: ProfilesViewSettings) => string;
};

const filterIds = [
  'adventure__transportation-type',
  'adventure__activity-sector',
  'adventure__qualification',
  'adventure__certification',
  'adventure__status',
  'adventure__cdii',
  'availabilityDate',
  'medicalExamination',
  'constructionCard',
  'workingDays',
] as const;

export type FilterId = (typeof filterIds)[number];

export const sectionDefinitions: Record<FilterId, FilterDefinition> = {
  availabilityDate: {
    i18nKey: 'reveal.searchView.filters.availabilityDate',
    Component: AvailabilityDateFilterPanel,
    isActive: ({ availabilityDate }) => availabilityDate !== undefined,
    isAvailable: ({ client }) => isATSFilterAvailable('adventure', client),
    isAlwaysShown: ({ client }) => isATSFilterAvailable('adventure', client),
    clear: (dispatch) => dispatch({ type: 'setAvailabilityDate' }),
    getPreview: () => null,
    serialize: (state) => JSON.stringify(state.availabilityDate),
  },
  'adventure__activity-sector': atsFilterSectionDefinition(
    'adventure',
    'activity-sector',
    undefined,
    ({ client }) => isATSFilterAvailable('adventure', client),
  ),
  adventure__qualification: atsFilterSectionDefinition(
    'adventure',
    'qualification',
    undefined,
    ({ client }) => isATSFilterAvailable('adventure', client),
  ),
  adventure__certification: {
    i18nKey: 'reveal.searchView.filters.ats.adventure.certification',
    Component: CertificationFilterPanel,
    isActive: ({ excludeExpiredCertifications, atsTagFilters }) =>
      excludeExpiredCertifications ||
      !_.isEmpty(atsTagFilters.adventure?.certification),
    isAvailable: ({ client }) => isATSFilterAvailable('adventure', client),
    isAlwaysShown: ({ client }) => isATSFilterAvailable('adventure', client),
    clear: (dispatch) => {
      dispatch({
        type: 'setAtsTagFilter',
        tagType: 'certification',
        ats: 'adventure',
      });
      dispatch({ type: 'setExcludeExpiredCertifications' });
    },
    getPreview: (state) =>
      getATSFilterPreview('adventure', 'certification', state),
    serialize: (state) =>
      serializeATSFilter('adventure', 'certification', state),
  },
  workingDays: {
    i18nKey: 'reveal.searchView.filters.ats.adventure.workingHours.title',
    Component: WorkingDaysFilterPanel,
    isActive: ({ atsTagFilters }) =>
      !_.isEmpty(atsTagFilters.adventure?.workslot),
    isAvailable: ({ client }) => isATSFilterAvailable('adventure', client),
    isAlwaysShown: ({ client }) => isATSFilterAvailable('adventure', client),
    clear: (dispatch) =>
      dispatch({
        type: 'setAtsTagFilter',
        tagType: 'workslot',
        ats: 'adventure',
      }),
    getPreview: (state) => getATSFilterPreview('adventure', 'workslot', state),
    serialize: (state) =>
      serializeATSFilter('adventure', 'certification', state),
  },
  'adventure__transportation-type': atsFilterSectionDefinition(
    'adventure',
    'transportation-type',
    undefined,
    ({ client }) => isATSFilterAvailable('adventure', client),
  ),
  medicalExamination: {
    i18nKey: 'reveal.searchView.filters.medicalExamination.title',
    Component: MedicalExaminationFilterPanel,
    isActive: ({ medicalExamination }) => medicalExamination !== undefined,
    isAvailable: ({ client }) => isATSFilterAvailable('adventure', client),
    clear: (dispatch) => dispatch({ type: 'setMedicalExamination' }),
    getPreview: (state) =>
      state.medicalExamination === 'valid' ? <Check /> : <Cross />,
    serialize: (state) => state.medicalExamination || '',
  },
  constructionCard: {
    i18nKey: 'reveal.searchView.filters.constructionCard.title',
    Component: ConstructionCardFilterPanel,
    isActive: ({ constructionCard }) => constructionCard !== undefined,
    isAvailable: ({ client }) => isATSFilterAvailable('adventure', client),
    clear: (dispatch) => dispatch({ type: 'setConstructionCard' }),
    getPreview: (state) =>
      state.constructionCard === 'valid' ? <Check /> : <Cross />,
    serialize: (state) => state.constructionCard || '',
  },
  adventure__status: atsFilterSectionDefinition('adventure', 'status', 'or'),
  adventure__cdii: atsFilterSectionDefinition('adventure', 'cdii', 'or'),
};

function atsFilterSectionDefinition(
  ats: ATStype,
  tag: string,
  lockedOperator?: 'and' | 'or',
  isAlwaysShown?: (context: { client?: Client }) => boolean,
): FilterDefinition {
  return {
    i18nKey: `reveal.searchView.filters.ats.${ats}.${tag}`,
    Component: ({ onSubmit }) => (
      <ATSTagFilterPanel
        onSubmit={onSubmit}
        ats={ats}
        tagType={tag}
        lockedOperator={lockedOperator}
      />
    ),
    isActive: ({ atsTagFilters }) => !_.isEmpty(atsTagFilters[ats]?.[tag]),
    isAvailable: ({ client }) => isATSFilterAvailable(ats, client),
    isAlwaysShown,
    clear: (dispatch) =>
      dispatch({ type: 'setAtsTagFilter', tagType: tag, ats }),
    getPreview: (state) => getATSFilterPreview(ats, tag, state),
    serialize: (state) => serializeATSFilter(ats, tag, state),
  };
}

function getATSFilterPreview(
  ats: ATStype,
  tag: string,
  state: ProfilesViewSettings,
) {
  const tagIds = state.atsTagFilters[ats]?.[tag]?.tagIds || [];
  if (_.isEmpty(tagIds)) {
    return null;
  }
  return `${_.size(tagIds)}`;
}

const serializeATSFilter = (
  ats: ATStype,
  tag: string,
  state: ProfilesViewSettings,
) => JSON.stringify(state.atsTagFilters[ats]?.[tag]);

function isATSFilterAvailable(ats: string, client?: Client) {
  const connector = _.findWhere(client?.revealProjects[0].connectors || [], {
    type: ats,
  });

  return connector !== undefined;
}
