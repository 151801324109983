import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';
import { useHistory } from 'react-router-dom';
import { Modal } from '@hiresweet/hiresweet-lib';

import GenericAsyncSelect from '@/components/Common/GenericSelect/GenericAsyncSelect';
import MissionCategoryContext from '@/context/MissionCategoryContext';
import useClientId from '@/hooks/router/useClientId';

import useUnlistedProjects from './useUnlistedProjects';

import styles from './SearchUnlistedJobsButton.module.less';

function SearchUnlistedProjectsButton() {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <div className={styles.box}>
        <button
          type='button'
          className={styles.button}
          onClick={() => setModalOpen((current) => !current)}
        >
          {t('reveal.missions.searchUnlisted')}
        </button>
      </div>
      <SearchUnlistedProjectsModal open={modalOpen} setOpen={setModalOpen} />
    </>
  );
}

function SearchUnlistedProjectsModal({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const { t } = useTranslation();
  const clientId = useClientId();
  const missionCategory = useContext(MissionCategoryContext);
  const history = useHistory();
  const [selectedProject, setSelectedProject] = useState<string | undefined>();
  const [getUnlistedProjects] = useUnlistedProjects();
  const loadOptions = useCallback(
    async (searchText: string) => {
      const { projects } = await getUnlistedProjects(searchText);
      return projects.map(({ id, data }) => ({
        value: id,
        label: data.title,
      }));
    },
    [getUnlistedProjects],
  );
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      title={t('reveal.missions.searchUnlisted')}
      disabled={!selectedProject}
      onSubmit={() => {
        history.push(
          `/client/${clientId}/reveal/projects/${missionCategory}/${selectedProject}`,
        );
      }}
    >
      <div className={styles.modal}>
        <GenericAsyncSelect
          isClearable
          loadOptions={loadOptions}
          onChange={(newValue) => {
            if (!newValue) {
              setSelectedProject(undefined);
              return;
            }
            setSelectedProject(newValue.value);
          }}
        />
      </div>
    </Modal>
  );
}

export default SearchUnlistedProjectsButton;
