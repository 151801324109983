import React, { FC, useContext, useRef, useState, useMemo } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import GenericSelect from '@/components/Common/GenericSelect';
import useAtsFilterOptions from '@/graphql/hooks/clients/useClientAtsFiltersOptions';
import useClientId from '@/hooks/router/useClientId';
import { getTranslatedText } from '@/common/helpers/translatableText';
import { getShortLanguage } from '@/common/utils/i18n';
import { ATStype } from '@/common/reveal';
import GenericButton from '@/components/Common/GenericButton';
import useDistinctFilterOptions from '@/graphql/hooks/searchPoolJob/useDistinctFilterOptions';

import { components, createFilter } from 'react-select';
import { lowerCaseAndUnaccent } from '@/common';
import GenericSwitch from '@/components/Common/GenericSwitch';
import ProfilesViewSettingsContext from '@/context/ProfilesViewSettingsContext';

import styles from './ATSTagFilterPanel.module.less';

type ATSTagFilterPanelProps = {
  ats: ATStype;
  tagType: string;
  onSubmit?: () => void;
  lockedOperator?: 'and' | 'or';
};

const SelectAllButton = (props: any) => {
  const { selectProps, children } = props;
  const { t } = useTranslation();
  return (
    <components.Menu {...props}>
      <div>
        <div>{children}</div>
        <div className={styles.selectAllContainer}>
          <span
            className={styles.selectAllButton}
            onClick={selectProps.changeOptionsData}
          >
            {t('reveal.missions.mission.profiles.filter.selectAll')}
          </span>
        </div>
      </div>
    </components.Menu>
  );
};

const ATSTagFilterPanel: FC<ATSTagFilterPanelProps> = ({
  ats,
  tagType,
  onSubmit,
  lockedOperator,
}) => {
  const { missionId } = useParams<{ missionId: string }>();
  const { t, i18n } = useTranslation();
  const { resolvedLanguage } = i18n;
  const lang = getShortLanguage(resolvedLanguage);
  const clientId = useClientId();
  const { data } = useAtsFilterOptions(clientId);
  const [selectorInput, setSelectorInput] = useState('');
  const [state, dispatch] = useContext(ProfilesViewSettingsContext);
  const [operator, setOperator] = useState<'and' | 'or'>(
    lockedOperator ?? (state.atsTagFilters[ats]?.[tagType]?.operator || 'or'),
  );

  const ref = useRef(null);

  const { distinctFilterOptions } = useDistinctFilterOptions({
    filterId: `${ats}__${tagType}`,
    missionId,
  });

  const [innerValue, setInnerValue] = useState(
    state.atsTagFilters[ats]?.[tagType]?.tagIds || [],
  );

  const connector = _.findWhere(
    data?.client.revealProjects[0].connectors || [],
    { type: ats },
  );

  const selectAllValues = () => {
    setSelectorInput('');
    setInnerValue((currentSelection) => {
      const isAlreadySelected = _.object(
        _.map(currentSelection, (id) => [id, true]),
      );
      return [
        ...(currentSelection || []),
        ..._.map(
          _.filter(
            filteredOptions,
            (option) =>
              !isAlreadySelected[option.value] &&
              lowerCaseAndUnaccent(option.label || '').indexOf(
                lowerCaseAndUnaccent(selectorInput),
              ) >= 0,
          ),
          (option) => option.value,
        ),
      ];
    });
    ref.current?.blur();
  };

  const operatorOptions = useMemo(
    () =>
      [
        {
          value: 'or',
          label: t('common.or'),
        },
        {
          value: 'and',
          label: t('common.and'),
        },
      ] as { value: 'and' | 'or'; label: string }[],
    [t],
  );

  if (!connector) {
    return null;
  }

  const filterOptionSet = _.reduce(
    distinctFilterOptions,
    (acc, { optionId }) => ({ ...acc, [optionId]: true }),
    {} as Record<string, boolean>,
  );

  const tags = _.filter(connector.filterOptions?.tags || [], ({ id }) =>
    id.startsWith(tagType),
  );

  const options = _.map(tags, ({ id, name }) => ({
    value: id,
    label: getTranslatedText(lang, name),
  }));

  const filteredOptions = _.filter(
    options,
    ({ value }) => filterOptionSet[value],
  );

  return (
    <div className={styles.panel}>
      {!lockedOperator && (
        <GenericSwitch
          selectedValue={operator}
          onValue={setOperator}
          options={operatorOptions}
          className={styles.operatorSwitch}
        />
      )}
      <GenericSelect
        ref={ref}
        isMulti
        options={filteredOptions}
        value={_.compact(
          _.map(innerValue, (value) => _.findWhere(options, { value })),
        )}
        onInputChange={(value) => setSelectorInput(value)}
        onChange={(newValue) =>
          setInnerValue(_.map(newValue || [], ({ value }) => value))
        }
        inputValue={selectorInput}
        components={
          !_.isEmpty(selectorInput) ? { Menu: SelectAllButton } : undefined
        }
        changeOptionsData={selectAllValues}
        filterOption={createFilter({
          stringify: ({ label }) => label,
        })}
      />
      <GenericButton
        onClick={() => {
          dispatch({
            type: 'setAtsTagFilter',
            ats,
            tagType,
            value: {
              operator,
              tagIds: innerValue,
            },
          });
          if (onSubmit) {
            onSubmit();
          }
        }}
        className={styles.button}
        size='big'
      >
        {t('reveal.missions.mission.profiles.filter.apply')}
      </GenericButton>
    </div>
  );
};

export default ATSTagFilterPanel;
