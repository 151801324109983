import React, { useMemo } from 'react';
import _, { compose } from 'underscore';
import { withTranslation } from 'react-i18next';

import EmptyState from '@/revealComponents/EmptyState/EmptyState';
import { useNavApplicationMode } from '@/containers/TopBar/Header/NavSwitch.hook';
import GenericButton from '@/components/Common/GenericButton';
import withClientPermissions from '../../../../hocs/clients/withClientPermissions';
import withActionLogger from '../../../../hocs/withActionLogger';
import LoadingComponent from '../../../../components/LoadingComponent';
import { SummaryBase } from '../Card/Summary';
import Experiences from './Experiences';
import Education from './Education';
import LinkedInExtract from './LinkedInExtract.component';
import GithubExtract from './GithubExtract.component';
import StackOverflowExtract from './StackOverflowExtract.component';
import AttachmentsSection from '../Attachments/AttachmentsSection';
import { Stack } from '../../../../components/Stack';

import './Background.css';

const EmptyBackgroundView = () => (
  <div>
    <LoadingComponent as='div' loading length={60} margin={1} randomCoef={5} />
    <LoadingComponent as='div' loading length={60} randomCoef={10} />
    <LoadingComponent as='div' loading length={60} randomCoef={10} />
    <LoadingComponent as='div' loading length={60} randomCoef={10} />
    <LoadingComponent
      as='div'
      loading
      length={60}
      rows={3}
      randomCoef={10}
      margin={2}
    />
    <LoadingComponent
      as='div'
      loading
      length={60}
      rows={5}
      randomCoef={10}
      margin={2}
    />
  </div>
);

const Background = React.memo(
  ({
    resumeData,
    attachments,
    hideAttachmentActions,
    isRevealProfile = false,
    permissions,
    profileId,
    onLogAction,
    toggleEditMode,
    creationTimestamp,
    t,
    /**
     * When true, this will disable hover-triggered popups.
     *
     * This can be used to prevent glitches when the component is already
     * used in a hover-trigger popup.
     */
    hoverPopupsDisabled,
  }) => {
    const { github, linkedin, stackoverflow } =
      (resumeData && resumeData.sourceData) || {};
    const isWatchProfile =
      resumeData?.prequalification?.type === 'hiresweet-watch';
    const linkedinProfileURL = resumeData?.sources?.linkedin;
    // fix some bug coming from the back where linkedin ID is None
    const hasLinkedinURL =
      _.isString(linkedinProfileURL) &&
      !linkedinProfileURL.includes('/in/None');
    const hideEndorsements =
      (permissions?.hideEndorsements && !isWatchProfile) || !hasLinkedinURL;

    const isRecent = useMemo(() => {
      const twoWeeks = 1000 * 86400 * 15;
      return Date.now() - creationTimestamp < twoWeeks;
    }, [creationTimestamp]);

    const { applicationMode } = useNavApplicationMode();

    if (_.isEmpty(resumeData)) {
      return <EmptyBackgroundView />;
    }

    const hasSomethingInGithub =
      github &&
      (!_.isEmpty(github.repositories) || !_.isEmpty(github.relevantFacts));

    const hasSomethingInLinkedin =
      linkedin && !_.isEmpty(linkedin.skills) && !isRevealProfile;

    const hasSomethingInStackoverflow =
      stackoverflow && !_.isEmpty(stackoverflow.relevantFacts);

    const hasSomethingInExpertise =
      hasSomethingInGithub ||
      hasSomethingInLinkedin ||
      hasSomethingInStackoverflow;

    const hasAttachments = !_.isEmpty(attachments);

    const showBackgroundPlaceholder =
      _.isEmpty(resumeData.experiences) &&
      _.isEmpty(resumeData.education) &&
      !hasAttachments;

    // const questionsForm = ((resumeData || {}).application || {}).questionsForm || {};
    // const answeredApplicationQuestions = _.filter(questionsForm.fields, (field) => (field.value || {}).text);
    const displayableEducation = _.filter(
      resumeData?.education,
      educationItemCanBeDisplayed,
    );

    const { content = {}, stack = {} } = resumeData?.summary || {};
    const shouldDisplaySummary =
      _.isObject(content) &&
      (!_.isEmpty(content.text) || !_.isEmpty(content.children));

    return (
      <div
        className={`profile-background ${applicationMode === 'reveal' &&
          'reveal'}`}
      >
        {shouldDisplaySummary && (
          <div className='background-section'>
            <div className='background-section-title summary-title'>
              {t('profile.resume.summary')}
              <div className='summary-stack-container'>
                <Stack
                  stack={stack}
                  displaySoftSkills
                  profileId={profileId}
                  context='summary'
                />
              </div>
            </div>
            <SummaryBase
              key={`summary__${profileId}`}
              content={content}
              logAction={onLogAction}
              profileId={profileId}
            />
          </div>
        )}

        {resumeData && !_.isEmpty(resumeData.experiences) && (
          <div className='background-section'>
            <div className='background-section-title'>
              {t('profile.resume.experience')}
            </div>
            <Experiences
              experiences={resumeData.experiences}
              t={t}
              profileId={profileId}
              hoverPopupsDisabled={hoverPopupsDisabled}
              applicationMode={applicationMode}
            />
          </div>
        )}
        {!_.isEmpty(displayableEducation) && (
          <div className='background-section'>
            <div className='background-section-title'>
              {t('profile.resume.education')}
            </div>
            <Education
              resumeData={resumeData}
              education={displayableEducation}
              t={t}
              profileId={profileId}
              applicationMode={applicationMode}
            />
          </div>
        )}

        {showBackgroundPlaceholder && (
          <EmptyState
              illustrationPath='/images/placeholders/profileEmptyState.svg'
              title={t('reveal.profileModal.emptyStates.profile.title', {
                firstname: resumeData.firstname,
              })}
              innerContent={
                (!hasLinkedinURL &&
                  t(
                    'reveal.profileModal.emptyStates.profile.innerContent.noLinkedIn',
                  )) ||
                (isRecent &&
                  t(
                    'reveal.profileModal.emptyStates.profile.innerContent.linkedIn',
                  )) ||
                undefined
              }
              cta={
                (!hasLinkedinURL && (
                  <GenericButton size='big' onClick={toggleEditMode}>
                    {t('reveal.profileModal.emptyStates.profile.cta')}
                  </GenericButton>
                )) ||
                undefined
              }
              className='profileEmptyState' // For resume creator parsing
            />
        )}

        {hasSomethingInExpertise && (
          <div className='background-section'>
            <div className='background-section-title'>
              {t('profile.resume.expertise')}
            </div>
            {hasSomethingInLinkedin && (
              <LinkedInExtract
                skills={linkedin.skills}
                hideEndorsements={hideEndorsements}
                t={t}
              />
            )}
            {hasSomethingInStackoverflow && (
              <StackOverflowExtract
                relevantFacts={stackoverflow.relevantFacts}
                t={t}
              />
            )}
            {hasSomethingInGithub && (
              <GithubExtract
                repositories={github.repositories}
                relevantFacts={github.relevantFacts}
                t={t}
                logAction={onLogAction}
                profileId={profileId}
              />
            )}
          </div>
        )}
        {!_.isEmpty(attachments) && !!permissions && !permissions.profileDocumentsTab && (
          <AttachmentsSection
              attachments={attachments}
              hideAttachmentActions={hideAttachmentActions}
              profileId={profileId}
            />
        )}
      </div>
    );
  },
);

const educationItemCanBeDisplayed = (educationItem) =>
  educationItem?.schoolName || educationItem?.degree?.text;

export default compose(
  withTranslation('translations'),
  withActionLogger,
  withClientPermissions,
)(Background);
