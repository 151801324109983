import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';

export const UPDATE_PROFILE_LINKED_COMPANY = gql`
  mutation UpdateProfileLinkedCompany(
    $profileId: ID!
    $input: ProfileLinkedCompanyInput!
    $searchPoolId: ID!
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      updateProfileLinkedCompany(profileId: $profileId, input: $input) {
        profile {
          id
          company {
            id
            name
          }
        }
      }
    }
  }
`;

type UpdateProfileLinkedCompanyVariables = {
  profileId: string;
  searchPoolId: string;
  input: { id?: string };
};

type UpdateProfileLinkedCompanyResult = {
  data: {
    searchPoolProfile: {
      updateProfileLinkedCompany: {
        profile: {
          id: string;
          company: {
            id: string;
            name: string;
          };
        };
      };
    };
  };
};

export default function useUpdateProfileLinkedCompany() {
  return useMutation<
    UpdateProfileLinkedCompanyResult,
    UpdateProfileLinkedCompanyVariables
  >(UPDATE_PROFILE_LINKED_COMPANY, {
    refetchQueries: ['CompanyTargetContacts'],
  });
}
