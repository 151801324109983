import React, { FC, useContext, useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ReactDatePicker from 'react-datepicker';
import _ from 'underscore';

import GenericButton from '@/components/Common/GenericButton';
import { getShortLanguage } from '@/common/utils/i18n';
import GenericSelect from '@/components/Common/GenericSelect';

import ProfilesViewSettingsContext from '@/context/ProfilesViewSettingsContext';
import { AvailabilityDateFilter } from '@/context/ProfilesViewSettingsContext/helpers';

import styles from './AvailabilityDateFilterPanel.module.less';

type AvailabilityDateFilterPanelProps = {
  onSubmit?: () => void;
};

const AvailabilityDateFilterPanel: FC<AvailabilityDateFilterPanelProps> = ({
  onSubmit,
}) => {
  const { t, i18n } = useTranslation();
  const lang = getShortLanguage(i18n.resolvedLanguage);
  const [state, dispatch] = useContext(ProfilesViewSettingsContext);
  const [filter, setFilter] = useState<AvailabilityDateFilter | undefined>(
    state.availabilityDate,
  );

  const dateFormat = useMemo(
    () => (lang === 'en' ? 'MM/dd/yyyy' : 'dd/MM/yyyy'),
    [lang],
  );

  const typeOptions = useMemo(
    () => [
      {
        value: 'days:1',
        label: t(
          'reveal.missions.mission.profiles.filter.availabilityDate.today',
        ),
      },
      {
        value: 'days:2',
        label: t(
          'reveal.missions.mission.profiles.filter.availabilityDate.beforeNDays',
          { days: 2 },
        ),
      },
      {
        value: 'days:3',
        label: t(
          'reveal.missions.mission.profiles.filter.availabilityDate.beforeNDays',
          { days: 3 },
        ),
      },
      {
        value: 'days:5',
        label: t(
          'reveal.missions.mission.profiles.filter.availabilityDate.beforeNDays',
          { days: 5 },
        ),
      },
      {
        value: 'days:7',
        label: t(
          'reveal.missions.mission.profiles.filter.availabilityDate.beforeNDays',
          { days: 7 },
        ),
      },
      {
        value: 'days:14',
        label: t(
          'reveal.missions.mission.profiles.filter.availabilityDate.beforeNDays',
          { days: 14 },
        ),
      },
      {
        value: 'days:30',
        label: t(
          'reveal.missions.mission.profiles.filter.availabilityDate.beforeNDays',
          { days: 30 },
        ),
      },
      {
        value: 'custom',
        label: t(
          'reveal.missions.mission.profiles.filter.availabilityDate.custom',
        ),
      },
    ],
    [t],
  );

  const onType = useCallback((type: string) => {
    if (type === 'custom') {
      setFilter({ type: 'absolute', date: new Date() });
      return;
    }
    const [unit, value] = type.split(':');
    if (unit === 'days') {
      setFilter({ type: 'relative', days: +value || 1 });
    }
  }, []);

  const selectedOption = useMemo(() => {
    if (filter?.type === 'absolute') {
      return _.findWhere(typeOptions, { value: 'custom' });
    }
    if (filter?.type === 'relative') {
      return _.findWhere(typeOptions, { value: `days:${filter.days}` });
    }
    return undefined;
  }, [filter, typeOptions]);

  return (
    <div className={styles.panel}>
      <GenericSelect
        options={typeOptions}
        value={selectedOption}
        onChange={(value) => {
          if (!value) {
            setFilter(undefined);
            return;
          }
          onType(value.value);
        }}
      />
      {filter?.type === 'absolute' && (
        <ReactDatePicker
          className={styles.datepicker}
          selected={filter?.date}
          onChange={(date) =>
            setFilter(date ? { type: 'absolute', date } : undefined)
          }
          locale={lang}
          dateFormat={dateFormat}
        />
      )}
      <GenericButton
        onClick={() => {
          dispatch({
            type: 'setAvailabilityDate',
            value: filter,
          });
          if (onSubmit) {
            onSubmit();
          }
        }}
        disabled={filter === undefined}
        className={styles.button}
        size='big'
      >
        {t('reveal.missions.mission.profiles.filter.apply')}
      </GenericButton>
    </div>
  );
};

export default AvailabilityDateFilterPanel;
