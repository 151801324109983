import React, { ReactNode, useReducer } from 'react';

import ProfilesViewSettingsContext from './ProfilesViewSettingsContext';
import { ProfilesViewSettings, profilesViewSettingsReducer } from './helpers';

type Props = {
  children?: ReactNode;
  initialSettings?: ProfilesViewSettings;
};

const ProfilesViewSettingsContextProvider = ({
  children,
  initialSettings = { atsTagFilters: {} },
}: Props) => {
  const value = useReducer(profilesViewSettingsReducer, initialSettings);
  return (
    <ProfilesViewSettingsContext.Provider value={value}>
      {children}
    </ProfilesViewSettingsContext.Provider>
  );
};

export default ProfilesViewSettingsContextProvider;
