import React from 'react';
import { useTranslation } from 'react-i18next';

import { useMergedConfigurationParams } from '@/graphql/hooks/useMergedConfigurationParams';

import './ProfileTabsMenu.css';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';
import useClientId from '@/hooks/router/useClientId';

interface ProfileTabsMenuProps {
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  isCompany?: boolean;
  withSimilarProfiles?: boolean;
  nbMissions?: number;
  atsName?: any;
  hasHiresweetDetails: boolean;
}

const ProfileTabsMenu = ({
  activeTab,
  setActiveTab,
  isCompany = false,
  withSimilarProfiles = false,
  nbMissions = 0,
  atsName,
  hasHiresweetDetails = false,
}: ProfileTabsMenuProps) => {
  const { t } = useTranslation();
  const clientId = useClientId();
  const { permissions } = useClientPermissions(clientId);
  const configurationParams = useMergedConfigurationParams();

  if (isCompany) {
    return (
      <div className='profile-tabs-menu'>
        {configurationParams?.shouldHideProfileTab !== 'true' && (
          <div
            onClick={() => setActiveTab('company-overview')}
            onKeyPress={() => setActiveTab('company-overview')}
            role='menuitem'
            tabIndex={0}
            className={`tab${activeTab === 'company-overview' ? ' active' : ''}`}
          >
            <h4>{t('reveal.profileModal.company')}</h4>
          </div>
        )}
        {permissions?.profileDocumentsTab && (
          <div
            onClick={() => setActiveTab('documents')}
            onKeyPress={() => setActiveTab('documents')}
            role='menuitem'
            tabIndex={0}
            className={`tab${activeTab === 'documents' ? ' active' : ''}`}
          >
            <h4>{t('reveal.profileModal.documents')}</h4>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className='profile-tabs-menu'>
      {configurationParams?.shouldHideProfileTab !== 'true' && (
        <div
          onClick={() => setActiveTab('profile')}
          onKeyPress={() => setActiveTab('profile')}
          role='menuitem'
          tabIndex={0}
          className={`tab${activeTab === 'profile' ? ' active' : ''}`}
        >
          <h4>{t('reveal.profileModal.profile')}</h4>
        </div>
      )}
      {configurationParams?.shouldHideProfileATSPane !== 'true' && atsName && (
        <div
          onClick={() => setActiveTab('ATS')}
          onKeyPress={() => setActiveTab('ATS')}
          role='menuitem'
          tabIndex={0}
          className={`tab${activeTab === 'ATS' ? ' active' : ''}`}
        >
          <h4>{t(`integrations.types.${atsName}`)}</h4>
        </div>
      )}
      {configurationParams?.shouldHideProfileInformationPane !== 'true' && (
        <div
          onClick={() => setActiveTab('details')}
          onKeyPress={() => setActiveTab('details')}
          role='menuitem'
          tabIndex={0}
          className={`tab${activeTab === 'details' ? ' active' : ''}`}
        >
          <h4>{t('reveal.profileModal.details')}</h4>
        </div>
      )}
      {permissions?.profileDocumentsTab && (
        <div
          onClick={() => setActiveTab('documents')}
          onKeyPress={() => setActiveTab('documents')}
          role='menuitem'
          tabIndex={0}
          className={`tab${activeTab === 'documents' ? ' active' : ''}`}
        >
          <h4>{t('reveal.profileModal.documents')}</h4>
        </div>
      )}
      {hasHiresweetDetails && (
        <div
          onClick={() => setActiveTab('hs-data')}
          onKeyPress={() => setActiveTab('hs-data')}
          role='menuitem'
          tabIndex={0}
          className={`tab${activeTab === 'hs-data' ? ' active' : ''}`}
        >
          <h4>{t('reveal.profileModal.hsData')}</h4>
        </div>
      )}
      {configurationParams?.shouldHideProfileProjectsPane !== 'true' && (
        <div
          onClick={() => setActiveTab('missions')}
          onKeyPress={() => setActiveTab('missions')}
          role='menuitem'
          tabIndex={0}
          className={`tab${activeTab === 'missions' ? ' active' : ''}`}
        >
          <h4>
            {t('header.parameters.projects')}{' '}
            {nbMissions > 0 && <span className='ui label'>{nbMissions}</span>}
          </h4>
        </div>
      )}
      {configurationParams?.shouldDisplayProfileTimelineOnLeftPane ===
        'true' && (
        <div
          onClick={() => setActiveTab('timeline')}
          onKeyPress={() => setActiveTab('timeline')}
          role='menuitem'
          tabIndex={0}
          className={`tab${activeTab === 'timeline' ? ' active' : ''}`}
        >
          <h4>{t('profile.contact.timeline.timeline')}</h4>
        </div>
      )}
      {configurationParams?.shouldHideProfileSimilarProfilesPane !== 'true' &&
        withSimilarProfiles && (
          <div
            onClick={() => setActiveTab('similar')}
            onKeyPress={() => setActiveTab('similar')}
            role='menuitem'
            tabIndex={0}
            className={`tab${activeTab === 'similar' ? ' active' : ''}`}
          >
            <h4>{t('reveal.profileModal.similarProfiles')}</h4>
          </div>
        )}
    </div>
  );
};

export default ProfileTabsMenu;
