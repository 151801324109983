import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';
import { Dropdown } from 'semantic-ui-react';
import GenericToggle from '@/components/Common/GenericToggle';
import GenericTextInput from '@/components/Common/GenericTextInput';
import { getTranslatedText } from '@/common';
import GenericButton from '@/components/Common/GenericButton';
import Cross from '@/components/Common/Icons/Cross';
import { JobsLayout } from './DescriptionModal';
import styles from './DescriptionJobsLayout.module.less';

interface DescriptionJobsLayoutProps {
  jobsLayout: JobsLayout;
  onJobsLayout: (newlayout: JobsLayout) => void;
}

const DescriptionJobsLayout: React.FC<DescriptionJobsLayoutProps> = ({
  jobsLayout,
  onJobsLayout,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.jobsLayoutContainer}>
      <div className={styles.inputContainer}>
        <div className={styles.inputLabel}>
          {t('settings.careerPage.description.modal.displayType')}
        </div>
        <Dropdown
          selection
          className='hiresweet-rounded'
          onChange={(_e, { value }) => {
            onJobsLayout({
              ...jobsLayout,
              displayType: value as 'list' | 'grid',
            });
          }}
          options={[
            {
              value: 'list',
              text: t('settings.careerPage.description.modal.list'),
            },
            {
              value: 'grid',
              text: t('settings.careerPage.description.modal.grid'),
            },
          ]}
          value={jobsLayout?.displayType}
        />
      </div>
      <div className={styles.inputContainer}>
        <div className={styles.inputLabel}>
          {t('settings.careerPage.description.modal.extraLinks')}
        </div>
        <div className={styles.extraLinksContainer}>
          {_.map(jobsLayout?.extraMenuLinks, (link, index) => (
            <div key={link.url} className={styles.extraLinkContainer}>
              <GenericTextInput
                fluid
                placeholder={t(
                  'settings.careerPage.description.modal.linkTitle',
                )}
                value={getTranslatedText(link?.title)}
                onChange={({ target }) => {
                  const newLinks = [...jobsLayout.extraMenuLinks];
                  newLinks[index] = {
                    ...newLinks[index],
                    title: { default: target.value },
                    url: newLinks[index]?.url || '',
                  };
                  onJobsLayout({
                    ...jobsLayout,
                    extraMenuLinks: newLinks,
                  });
                }}
              />
              <GenericTextInput
                fluid
                placeholder={t('settings.careerPage.description.modal.linkURL')}
                value={link?.url}
                onChange={({ target }) => {
                  const newLinks = [...jobsLayout.extraMenuLinks];
                  newLinks[index] = {
                    ...newLinks[index],
                    title: newLinks[index]?.title || { default: '' },
                    url: target?.value,
                  };
                  onJobsLayout({
                    ...jobsLayout,
                    extraMenuLinks: newLinks,
                  });
                }}
              />
              <GenericButton
                primacy='tertiary'
                onClick={() => {
                  const newLinks = [...jobsLayout.extraMenuLinks];
                  newLinks.splice(index, 1);
                  onJobsLayout({
                    ...jobsLayout,
                    extraMenuLinks: newLinks,
                  });
                }}
              >
                <Cross />
              </GenericButton>
            </div>
          ))}
          <GenericButton
            primacy='secondary'
            onClick={() => {
              onJobsLayout({
                ...jobsLayout,
                extraMenuLinks: [
                  ...(jobsLayout.extraMenuLinks || []),
                  { title: { default: '' }, url: '' },
                ],
              });
            }}
          >
            {t('settings.careerPage.description.modal.addLink')}
          </GenericButton>
        </div>
      </div>
      <div className={styles.inputContainer}>
        <div className={styles.inputLabel}>
          {t('settings.careerPage.description.modal.banner')}
        </div>
        <GenericTextInput
          fluid
          placeholder={t(
            'settings.careerPage.description.modal.bannerPlaceholder',
          )}
          value={jobsLayout?.banner?.file?.url}
          onChange={({ target }) => {
            onJobsLayout({
              ...jobsLayout,
              banner: {
                type: 'image',
                file: {
                  url: target.value,
                },
              },
            });
          }}
        />
      </div>
      <div className={styles.inputContainer}>
        <div className={styles.inputLabel}>
          {t('settings.careerPage.description.modal.showFilter')}
        </div>
        <GenericToggle
          size='large'
          name='showFilter'
          isChecked={jobsLayout?.showFilter}
          onChange={() => {
            onJobsLayout({
              ...jobsLayout,
              showFilter: !jobsLayout?.showFilter,
            });
          }}
        />
      </div>
      <div className={styles.inputContainer}>
        <div className={styles.inputLabel}>
          {t('settings.careerPage.description.modal.showFirstSectionPreview')}
        </div>
        <GenericToggle
          size='large'
          name='showFirstSectionPreview'
          isChecked={jobsLayout?.showFirstSectionPreview}
          onChange={() => {
            onJobsLayout({
              ...jobsLayout,
              showFirstSectionPreview: !jobsLayout?.showFirstSectionPreview,
            });
          }}
        />
      </div>
      <div className={styles.inputContainer}>
        <div className={styles.inputLabel}>
          {t('settings.careerPage.description.modal.hideSalary')}
        </div>
        <GenericToggle
          size='large'
          name='hideSalary'
          isChecked={jobsLayout?.hideSalary}
          onChange={() => {
            onJobsLayout({
              ...jobsLayout,
              hideSalary: !jobsLayout?.hideSalary,
            });
          }}
        />
      </div>
      <div className={styles.inputContainer}>
        <div className={styles.inputLabel}>
          {t('settings.careerPage.description.modal.hideRemote')}
        </div>
        <GenericToggle
          size='large'
          name='hideRemote'
          isChecked={jobsLayout?.hideRemote}
          onChange={() => {
            onJobsLayout({
              ...jobsLayout,
              hideRemote: !jobsLayout?.hideRemote,
            });
          }}
        />
      </div>
      <div className={styles.inputContainer}>
        <div className={styles.inputLabel}>
          {t('settings.careerPage.description.modal.hideNativeFooter')}
        </div>
        <GenericToggle
          size='large'
          name='hideNativeFooter'
          isChecked={jobsLayout?.hideNativeFooter}
          onChange={() => {
            onJobsLayout({
              ...jobsLayout,
              hideNativeFooter: !jobsLayout?.hideNativeFooter,
            });
          }}
        />
      </div>
    </div>
  );
};

export default DescriptionJobsLayout;
