import React, { useState } from 'react';
import _ from 'underscore';
import { Modal, Button, Message, Dropdown } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';

import {
  ATTACHMENT_TYPE_COVER_LETTER,
  ATTACHMENT_TYPE_OTHER,
  ATTACHMENT_TYPE_RESUME,
} from '@/common/constants/attachments';
import { getTranslatedText } from '@/common';
import Attachment from '@/graphql/fragments/Attachment';
import { useClientDocumentTypes } from '@/graphql/hooks/clients/useClientDocumentTypes';
import { TranslatableText } from '@/types/text';
import CustomFileInput from '../../CustomFileInput';

interface RevealUploadFileModalProps {
  profileId: string;
  attachments: any[];
  open: boolean;
  onClose: () => void;
}

const RevealUploadFileModal: React.FC<RevealUploadFileModalProps> = ({
  profileId,
  attachments,
  open,
  onClose,
}) => {
  const { t } = useTranslation();
  const [uploadProfileAttachment] = useMutation(ADD_PROFILE_ATTACHMENT);
  const [fileType, setFileType] = useState<string>(ATTACHMENT_TYPE_RESUME);
  const [fileName, setFileName] = useState<string>();
  const [fileContent, setFileContent] = useState<string>();
  const [importError, setImportError] = useState<null | unknown>(null);
  const [importingFile, setImportingFile] = useState(false);
  const [uploadWasSuccessful, setUploadWasSuccessful] = useState(false);

  const { clientDocumentTypes } = useClientDocumentTypes();

  const onCancel = () => {
    setFileType(ATTACHMENT_TYPE_RESUME);
    setFileName(undefined);
    setFileContent(undefined);
    setImportError(null);
    setUploadWasSuccessful(false);
    onClose();
  };

  const onDrop = (files: File[]) => {
    const file = (files || [])[0];
    if (file) {
      onChange(file);
    }
  };

  const onChange = (file: File) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        if (!reader.result || !file.name) {
          return;
        }
        setFileName(file.name);
        setFileContent(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
    setImportError(null);
    setUploadWasSuccessful(false);
  };

  const onUpload = async () => {
    try {
      setImportingFile(true);
      await uploadProfileAttachment({
        variables: {
          searchPoolId: 'reveal',
          input: {
            attachment: {
              type: fileType,
              name: fileName,
              file: { content: fileContent },
            },
            profileId,
          },
        },
      });

      setUploadWasSuccessful(true);
      setImportError(null);
    } catch (e) {
      console.error('Import failed', e);
      setImportError(e);
      setUploadWasSuccessful(false);
    }
    setImportingFile(false);
  };

  const canTryUpload = !!fileContent;

  const fileTypeConflict =
    (fileType === ATTACHMENT_TYPE_RESUME &&
      _.findWhere(attachments, { type: ATTACHMENT_TYPE_RESUME })) ||
    (fileType === ATTACHMENT_TYPE_COVER_LETTER &&
      _.findWhere(attachments, { type: ATTACHMENT_TYPE_COVER_LETTER }));

  return (
    <Modal
      open={open}
      size='small'
      className='import-csv-modal'
      onClose={onCancel}
      closeIcon
    >
      <Modal.Header>{t('fileUpload.title')}</Modal.Header>
      <Modal.Content>
        <div className='input-container'>
          <div className='input-label'>{t('fileUpload.attachmentType')}</div>
          <div className='input-element'>
            <Dropdown
              selection
              fluid
              placeholder={t('fileUpload.attachmentType')}
              value={fileType}
              onChange={(_event, { value }) => setFileType(value as string)}
              options={[
                {
                  text: t('fileUpload.typeOptions.resume'),
                  value: ATTACHMENT_TYPE_RESUME,
                },
                {
                  text: t('fileUpload.typeOptions.coverLetter'),
                  value: ATTACHMENT_TYPE_COVER_LETTER,
                },
                ...(clientDocumentTypes || []).map(
                  (type: { key: string; value: TranslatableText }) => ({
                    text: getTranslatedText(type.value),
                    value: type.key,
                  }),
                ),
                {
                  text: t('fileUpload.typeOptions.other'),
                  value: ATTACHMENT_TYPE_OTHER,
                },
              ]}
            />
          </div>
        </div>
        {!fileContent && (
          <CustomFileInput
            onChange={onDrop}
            description={undefined}
            fileTypes={undefined}
            imageSrc={undefined}
          />
        )}
        {fileContent && !uploadWasSuccessful && (
          <>
            <div className='modal-main-description'>
              {t('fileUpload.willImportFile', { fileName })}
            </div>
            {fileTypeConflict && (
              <Message className='warning'>
                {t('fileUpload.fileTypeConflict.content')}
              </Message>
            )}
          </>
        )}
        {uploadWasSuccessful && (
          <Message className='success'>{t('fileUpload.success')}</Message>
        )}
        {importError && (
          <Message className='error'>{t('fileUpload.error')}</Message>
        )}
      </Modal.Content>
      {canTryUpload && !uploadWasSuccessful ? (
        <Modal.Actions>
          <div className='align-left'>
            <Button className='dismiss' onClick={onCancel}>
              {t('fileUpload.cancel')}
            </Button>
          </div>
          <Button
            primary={!fileTypeConflict}
            secondary={fileTypeConflict}
            size='big'
            onClick={onUpload}
            disabled={!canTryUpload || importingFile}
            loading={importingFile}
          >
            {t('fileUpload.upload')}
          </Button>
        </Modal.Actions>
      ) : (
        <Modal.Actions>
          <Button primary size='big' onClick={onCancel}>
            {t('fileUpload.close')}
          </Button>
        </Modal.Actions>
      )}
    </Modal>
  );
};

const ADD_PROFILE_ATTACHMENT = gql`
  mutation addProfileAttachment(
    $searchPoolId: ID!
    $input: AddAttachmentInput!
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      addAttachment(input: $input) {
        profile {
          id
          attachments {
            ...Attachment
            file {
              extension
              url
            }
          }
        }
      }
    }
  }
  ${Attachment}
`;

export default RevealUploadFileModal;
