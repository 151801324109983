import React, { FC, useCallback, useMemo } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

import {
  useClientProfileCustomFields,
  useClientProfileCustomFieldsMutation,
  CustomFieldDefinition,
} from '@/graphql/hooks/clients/useClientProfileCustomFields';
import LoadingComponent from '@/components/LoadingComponent';
import { CustomFieldListForm } from '@/components/CustomFields/CustomFieldListForm';
import {
  useClientMissionCustomFields,
  useClientMissionCustomFieldsMutation,
} from '@/graphql/hooks/clients/useClientMissionCustomFields';
import useClientProcessCustomFields, {
  useClientProcessCustomFieldsMutation,
} from '@/graphql/hooks/clients/useClientProcessCustomFields';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';
import SettingsLayout from '../SettingsLayout';

import styles from './CustomFieldsSettings.module.less';

const cleanFields = (fields: CustomFieldDefinition[]) => {
  return _.map(
    fields,
    (field) => _.omit(field, 'defaultValue') as CustomFieldDefinition,
  );
};

interface CustomFieldsSettingsProps {
  clientId: string;
}

const CustomFieldsSettings: FC<CustomFieldsSettingsProps> = ({ clientId }) => {
  const { t } = useTranslation();
  const toast = useNotificationSystem();
  const { permissions } = useClientPermissions(clientId);

  const { profileCustomFields, loading } =
    useClientProfileCustomFields(clientId);

  const { missionCustomFields, loading: loadingMissionFields } =
    useClientMissionCustomFields(clientId);

  const { processCustomFields, loading: loadingProcessCustomFields } =
    useClientProcessCustomFields(clientId);

  const [updateProfileFields] = useClientProfileCustomFieldsMutation({
    onError: (e) => {
      console.error('Error updating profile fields', e);
      toast.error(t('customFieldsSettings.profilesUpdateError'));
    },
  });

  const [updateMissionFields] = useClientMissionCustomFieldsMutation({
    onError: (e) => {
      console.error('Error updating mission fields', e);
      toast.error(t('customFieldsSettings.missionsUpdateError'));
    },
  });

  const [updateProcessFields] = useClientProcessCustomFieldsMutation({
    onError: (e) => {
      console.error('Error updating mission fields', e);
      toast.error(t('customFieldsSettings.processUpdateError'));
    },
  });

  const updateProfileCustomFields = useCallback(
    async (fields: CustomFieldDefinition[]) => {
      await updateProfileFields({
        variables: { input: { fields: cleanFields(fields) } },
      });
    },
    [updateProfileFields],
  );

  const updateMissionCustomFields = useCallback(
    async (fields: CustomFieldDefinition[]) => {
      await updateMissionFields({
        variables: { input: { fields: cleanFields(fields) } },
      });
    },
    [updateMissionFields],
  );

  const updateProcessCustomFields = useCallback(
    async (fields: CustomFieldDefinition[]) => {
      await updateProcessFields({
        variables: { input: { fields: cleanFields(fields) } },
      });
    },
    [updateProcessFields],
  );

  const { contactsCustomFields, companiesCustomFields } = useMemo(
    () => ({
      contactsCustomFields: _.filter(
        profileCustomFields,
        (field) =>
          !field.contactCategory ||
          !field.contactCategory.type ||
          field.contactCategory.type === 'human',
      ),
      companiesCustomFields: _.filter(
        profileCustomFields,
        (field) =>
          !!field.contactCategory && field.contactCategory.type === 'company',
      ),
    }),
    [profileCustomFields],
  );

  const updateHumanCustomFields = useCallback(
    (customFields) => {
      const enrichedHumanCustomFields = _.map(customFields, (customField) => ({
        ...customField,
        contactCategory: { type: 'human' },
      }));
      updateProfileCustomFields([
        ...enrichedHumanCustomFields,
        ...companiesCustomFields,
      ]);
    },
    [companiesCustomFields, updateProfileCustomFields],
  );

  const updateCompanyCustomFields = useCallback(
    (customFields) => {
      const enrichedCompanyCustomFields = _.map(
        customFields,
        (customField) => ({
          ...customField,
          contactCategory: { type: 'company' },
        }),
      );
      updateProfileCustomFields([
        ...contactsCustomFields,
        ...enrichedCompanyCustomFields,
      ]);
    },
    [contactsCustomFields, updateProfileCustomFields],
  );

  return (
    <SettingsLayout
      currentPage='customFields'
      clientId={clientId}
      className='settings-container'
    >
      <div className='settings'>
        <div className='settings-header'>
          <h1>{t('customFieldsSettings.header')}</h1>
        </div>
        <h2>{t('customFieldsSettings.profilesTitle')}</h2>
        <div className='section visible'>
          <div className={styles.description}>
            {t('customFieldsSettings.profilesDescription')}
          </div>
          <div className='input-container'>
            {loading && !contactsCustomFields?.length ? (
              <LoadingComponent
                rows={5}
                style={{ width: '100%', height: '3rem', marginBotton: '1rem' }}
                loading
              />
            ) : (
              <CustomFieldListForm
                customFields={contactsCustomFields}
                onChange={updateHumanCustomFields}
                canBeDisplayedInCard
                canBeDisplayedAtCreation
                canBeMandatoryAtCreation
              />
            )}
          </div>
        </div>

        <h2>{t('customFieldsSettings.missionsTitle')}</h2>
        <div className='section visible'>
          <div className={styles.description}>
            {t('customFieldsSettings.missionsDescription')}
          </div>
          <div className='input-container'>
            {loadingMissionFields && !missionCustomFields?.length ? (
              <LoadingComponent
                rows={5}
                style={{ width: '100%', height: '3rem', marginBotton: '1rem' }}
                loading
              />
            ) : (
              <CustomFieldListForm
                isProfile={false}
                customFields={missionCustomFields}
                onChange={updateMissionCustomFields}
                canBeDisplayedAtCreation
                canBeMandatoryAtCreation
              />
            )}
          </div>
        </div>
        {permissions?.canHandleCompanyContacts && (
          <>
            <h2>{t('customFieldsSettings.companiesTitle')}</h2>
            <div className='section visible'>
              <div className={styles.description}>
                {t('customFieldsSettings.companiesDescription')}
              </div>
              <div className='input-container'>
                {loading && !companiesCustomFields?.length ? (
                  <LoadingComponent
                    rows={5}
                    style={{
                      width: '100%',
                      height: '3rem',
                      marginBotton: '1rem',
                    }}
                    loading
                  />
                ) : (
                  <CustomFieldListForm
                    customFields={companiesCustomFields}
                    onChange={updateCompanyCustomFields}
                    canBeDisplayedInCard
                    canBeDisplayedAtCreation
                    canBeMandatoryAtCreation
                  />
                )}
              </div>
            </div>
          </>
        )}
        <h2>{t('customFieldsSettings.processTitle')}</h2>
        <div className='section visible'>
          <div className={styles.description}>
            {t('customFieldsSettings.processDescription')}
          </div>
          <div className='input-container'>
            {loadingProcessCustomFields && !processCustomFields?.length ? (
              <LoadingComponent
                rows={5}
                style={{ width: '100%', height: '3rem', marginBotton: '1rem' }}
                loading
              />
            ) : (
              <CustomFieldListForm
                isProfile={false}
                customFields={processCustomFields}
                onChange={updateProcessCustomFields}
              />
            )}
          </div>
        </div>
      </div>
    </SettingsLayout>
  );
};

export default CustomFieldsSettings;
