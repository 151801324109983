import React, { useEffect, useMemo, useRef, useState } from 'react';
import _ from 'underscore';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import classNames from 'classnames';

import 'react-datepicker/dist/react-datepicker.css';

import useClickOutside from '@/hooks/common/useClickOutside';
import useIsPlugin from '@/hooks/common/useIsPlugin';
import useMinimizedView from '@/hooks/ui/useMinimizedView';
import GenericButton from '@/components/Common/GenericButton';
import { RevealSnoozeReason } from '@/graphql/hooks/clients/useClientSnoozeReasons';
import GenericTextArea from '@/components/Common/GenericTextArea';
import { canSnoozeTaskForGivenTime } from '@/hooks/common/useTaskCanSnooze';
import { getShortLanguage } from '@/common/utils/i18n';
import { useMergedConfigurationParams } from '@/graphql/hooks/useMergedConfigurationParams';

import styles from './SnoozeTask.module.less';

type SnoozeDuration = {
  unit: 'day';
  value: number;
};

interface SnoozeTaskProps {
  snoozeReasons: RevealSnoozeReason[];
  setSnoozeModalOpen: (open: boolean) => void;
  onConfirm?: (args: {
    snoozeDate: Date;
    snoozeDuration: SnoozeDuration;
    snoozeReason?: string;
    snoozeComment?: string;
  }) => void;
  maxSnoozeDate?: string;
}

const SnoozeTask: React.FC<SnoozeTaskProps> = ({
  snoozeReasons,
  setSnoozeModalOpen,
  onConfirm,
  maxSnoozeDate,
}) => {
  const { isMinimized } = useMinimizedView();
  const { t, i18n } = useTranslation();
  const lang = getShortLanguage(i18n.resolvedLanguage);
  const ref = useRef<HTMLDivElement>(null);
  const isPlugin = useIsPlugin();
  const [selectedOption, setSelectedOption] = useState('');
  const [shouldDisplayTime, setShouldDisplayTime] = useState(false);
  const [selectedReasonId, setSelectedReasonId] = useState('');
  const [selectedReasonTitle, setSelectedReasonTitle] = useState('');
  const [comment, setComment] = useState('');
  const [snoozeDuration, setSnoozeDuration] = useState<SnoozeDuration>({
    unit: 'day',
    value: 7,
  });

  const configurationParams = useMergedConfigurationParams();

  useClickOutside(ref, (event: any) => {
    if (
      !event.target?.classList?.contains('react-datepicker__navigation') &&
      !event.target.classList?.contains('react-datepicker__day')
    ) {
      setSnoozeModalOpen(false);
    }
  });

  const [snoozeDate, setSnoozeDate] = useState(new Date());

  useEffect(() => {
    if (!selectedOption) return;
    setSnoozeDate(new Date());
    if (selectedOption === '1-day') {
      setSnoozeDuration({
        unit: 'day',
        value: 1,
      });
      setSnoozeDate(() => {
        const defaultDate = new Date();
        defaultDate.setDate(defaultDate.getDate() + 1);
        return defaultDate;
      });
    }
    if (selectedOption === '1-week') {
      setSnoozeDate(() => {
        const defaultDate = new Date();
        defaultDate.setDate(defaultDate.getDate() + 7);
        return defaultDate;
      });
    }
    if (selectedOption === '1-month') {
      setSnoozeDate(() => {
        const defaultDate = new Date();
        defaultDate.setMonth(defaultDate.getMonth() + 1);
        return defaultDate;
      });
    }
    if (selectedOption === '2-month') {
      setSnoozeDate(() => {
        const defaultDate = new Date();
        defaultDate.setMonth(defaultDate.getMonth() + 2);
        return defaultDate;
      });
    }
    if (selectedOption === '3-month') {
      setSnoozeDate(() => {
        const defaultDate = new Date();
        defaultDate.setMonth(defaultDate.getMonth() + 3);
        return defaultDate;
      });
    }
    if (selectedOption !== 'custom') {
      setShouldDisplayTime(false);
    }
  }, [selectedOption]);

  useEffect(() => {
    if (isMinimized) {
      setSelectedOption('1-week');
    }
  }, [isMinimized]);

  const snoozeOptions = useMemo(() => {
    return [
      {
        text: t('reveal.candidatesView.timeline.snooze.buttons.1-day'),
        value: '1-day',
      },
      ...(canSnoozeTaskForGivenTime(maxSnoozeDate || '', 7)
        ? [
            {
              text: t('reveal.candidatesView.timeline.snooze.buttons.1-week'),
              value: '1-week',
            },
          ]
        : []),
      ...(canSnoozeTaskForGivenTime(maxSnoozeDate || '', 31)
        ? [
            {
              text: t('reveal.candidatesView.timeline.snooze.buttons.1-month'),
              value: '1-month',
            },
          ]
        : []),
      ...(canSnoozeTaskForGivenTime(maxSnoozeDate || '', 62)
        ? [
            {
              text: t('reveal.candidatesView.timeline.snooze.buttons.2-month'),
              value: '2-month',
            },
          ]
        : []),
      ...(canSnoozeTaskForGivenTime(maxSnoozeDate || '', 92)
        ? [
            {
              text: t('reveal.candidatesView.timeline.snooze.buttons.3-month'),
              value: '3-month',
            },
          ]
        : []),
      ...(canSnoozeTaskForGivenTime(maxSnoozeDate || '', 2)
        ? [
            {
              text: t('reveal.candidatesView.timeline.snooze.buttons.custom'),
              value: 'custom',
            },
          ]
        : []),
    ];
    // eslint-disable-next-line
  }, []);

  const usesHours = configurationParams?.shouldHideSnoozeTimePicker !== 'true';
  const onChangeDate = (date: Date, event: unknown) => {
    if (date) {
      if (!usesHours) {
        date.setHours(3, 0, 0, 0);
      }
      setSnoozeDate(date);
    }
    // no event = user click on time column
    setShouldDisplayTime(!event);
  };

  const dateFormat = useMemo(
    () => (lang === 'fr' ? 'dd MMMM YYYY' : 'MMMM d, YYYY'),
    [lang],
  );

  const onSubmit = () => {
    if (onConfirm) {
      onConfirm({
        snoozeDate,
        snoozeDuration,
        ...(!_.isEmpty(selectedReasonId) && {
          snoozeReasonId: selectedReasonId,
          snoozeReasonTitle: selectedReasonTitle,
        }),
        ...(!_.isEmpty(selectedReasonId) &&
          comment && { snoozeComment: comment }),
      });
    }
  };

  const handleToggleReason = (reasonId: string, title: string) => {
    setSelectedReasonId(reasonId);
    setSelectedReasonTitle(title);
  };

  return (
    <div
      className={`${styles.snoozeModal} ${isMinimized ? styles.minimized : ''}`}
      ref={ref}
    >
      {!isMinimized && !_.isEmpty(snoozeReasons) && (
        <>
          <div className={styles.title}>
            {t('reveal.candidatesView.timeline.snooze.reasonTitle')}
          </div>
          <div className={styles.buttons}>
            {_.map(snoozeReasons, (reason, index) => {
              return (
                <Button
                  key={index}
                  className={classNames(
                    styles.button,
                    reason.id === selectedReasonId && styles.buttonSelected,
                  )}
                  onClick={() => handleToggleReason(reason.id, reason.title)}
                >
                  {reason.title}
                </Button>
              );
            })}
          </div>
          <div className={styles.title}>
            {t('reveal.candidatesView.timeline.snooze.commentTitle')}
          </div>
          <GenericTextArea
            className={styles.comment}
            value={comment}
            onValue={setComment}
          />
        </>
      )}
      {!isMinimized && (
        <>
          <span className={styles.title}>
            {t('reveal.candidatesView.timeline.snooze.title')}
          </span>
          <div className={styles.buttons}>
            {_.map(snoozeOptions, (option, index) => {
              return (
                <Button
                  key={index}
                  className={classNames(
                    styles.button,
                    selectedOption === option.value && styles.buttonSelected,
                  )}
                  onClick={() => setSelectedOption(option.value)}
                >
                  {option.text}
                </Button>
              );
            })}
          </div>
        </>
      )}
      {selectedOption === 'custom' && (
        <div
          className={classNames(
            styles.datePickerWrapper,
            isPlugin && 'plugin-date-picker-wrapper',
          )}
        >
          <DatePicker
            selected={snoozeDate}
            minDate={new Date()}
            filterTime={(selectedDate) =>
              new Date().getTime() < selectedDate.getTime()
            }
            maxDate={maxSnoozeDate ? new Date(maxSnoozeDate) : null}
            onChange={onChangeDate}
            locale={lang}
            dateFormat={dateFormat}
            inline
            showTimeSelect={usesHours}
          />
        </div>
      )}
      {selectedOption.length > 0 &&
        (_.isEmpty(snoozeReasons) || !_.isEmpty(selectedReasonId)) && (
          <div className={styles.confirm}>
            <div>
              {t('reveal.candidatesView.timeline.snooze.snoozedUntil')}{' '}
              {shouldDisplayTime
                ? t('common.shortDateAndTime', { date: snoozeDate })
                : t('common.simpleDate', { date: snoozeDate })}
            </div>
            <GenericButton onClick={onSubmit}>
              {t('common.confirm')}
            </GenericButton>
          </div>
        )}
    </div>
  );
};

export default SnoozeTask;
