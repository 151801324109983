import React, { Component, useEffect, useMemo } from 'react';

import { compose } from 'underscore';
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { HiresweetLibProvider } from '@hiresweet/hiresweet-lib';

import PropTypes from 'prop-types';

import useOpenReplay from '@/hooks/common/useOpenReplay';
import { LastProfileActionsProvider } from './context/LastProfileActionsContext';
import PrivateRoute from './containers/PrivateRoute';
import withClient from './hocs/clients/withClient';

import withNotifications from './hocs/withNotifications';
import routerParamsToProps from './hocs/routerParamsToProps';
import withLanguage from './hocs/withLanguage';
import withUserFromJWToken from './hocs/users/withUserFromJWToken';
import withEmailApi from './hocs/email/withEmailApi';

import ParametersViewRoute from './routes/ParametersView';
import OfferParametersViewRoute from './routes/OfferParametersView';
import DashboardView from './routes/DashboardView';
import RevealView from './routes/RevealView';
// import ScrollToTop from './routes/ScrollToTop';
import WatchCollectViewV2 from './routes/WatchCollectViewV2';
import ProfileView from './routes/ProfileView';
import SigninView from './routes/SigninView';
import SignupView from './routes/SignupView';
import TrialSignupView from './routes/TrialSignupView';
import CRMSignupView from './routes/CRMSignupView';
import GlobalSigninView from './routes/GlobalSigninView';
import ForgottenPasswordView from './routes/ForgottenPasswordView';
import ResetPasswordView from './routes/ResetPasswordView';
import RedirectView from './routes/RedirectView';
import SequenceGenerationDemo from './routes/RevealView/SequenceGenerationDemo';
import { AdminApplicationModeContextProvider } from './containers/TopBar/Header/AdminApplicationModeContext';
import SSOReceptionView from './routes/SSOReceptionView';
import logAction from './common/logAction';
import FreeTrialUpgradeModalContextProvider from './components/FreeTrial/FreeTrialUpgradeModalContextProvider';
import { AppContextProvider } from './context/AppContext/AppContext';
import MarketplaceOnboardingModal from './components/Sequences/MarketplaceOnboardingModal';
import PricingValidationModal from './containers/Onboarding/FirstSignin/PricingValidationModal';
import SharedSpaceView from './routes/SharedSpaceView';

const BaseMainRouter = ({
  clientId,
  client,
  user,
  refetchClient,
  toggleMenuVisibility,
}) => {
  useOpenReplay();

  useEffect(() => {
    logAction({
      type: 'identify',
      info: {
        clientId,
      },
    });
  }, [clientId]);

  useEffect(() => {
    refetchClient();
  }, [user, refetchClient]);

  const { pathname } = useLocation();

  const isRevealRoute = (pathname || '').indexOf('/reveal/') >= 0;

  const hiresweetContextValue = useMemo(
    () => ({
      clientId,
      theme: 'hiresweet',
    }),
    [clientId],
  );

  if (!client?.permissions) {
    return null;
  }

  const { marketplaceUnifiedView, watchCollect } = client.permissions;

  return (
    <AppContextProvider clientId={clientId}>
      <HiresweetLibProvider value={hiresweetContextValue}>
        <LastProfileActionsProvider>
          <AdminApplicationModeContextProvider>
            <Switch>
              <Route
                exact
                path='/client/:clientId/offers/:offerId/profiles/:profileId'
                render={({
                  match: {
                    params: { offerId, clientId, profileId },
                  },
                }) => (
                  <Redirect
                    to={`/client/${clientId}/jobs/${offerId}/profiles/${profileId}`}
                    replace
                  />
                )}
              />

              <PrivateRoute
                path='/client/:clientId/jobs/:offerId/profiles/:profileId'
                component={ProfileView}
                toggleMenuVisibility={toggleMenuVisibility}
                includeUnclassified
              />

              <Route
                exact
                path='/client/:clientId/offers/:offerId/profiles'
                render={({
                  match: {
                    params: { offerId, clientId },
                  },
                }) => (
                  <Redirect
                    to={`/client/${clientId}/jobs/${offerId}/profiles`}
                    replace
                  />
                )}
              />
              <PrivateRoute
                path='/client/:clientId/jobs/:offerId/profiles'
                component={ProfileView}
                toggleMenuVisibility={toggleMenuVisibility}
                includeUnclassified
              />

              <Route
                exact
                path='/client/:clientId/jobs/:offerId'
                render={({
                  match: {
                    params: { offerId, clientId },
                  },
                }) => (
                  <Redirect
                    to={`/client/${clientId}/jobs/${offerId}/profiles`}
                  />
                )}
              />

              <Route
                exact
                path='/client/:clientId/offers/:offerId'
                render={({
                  match: {
                    params: { offerId, clientId },
                  },
                }) => (
                  <Redirect
                    to={`/client/${clientId}/jobs/${offerId}/profiles`}
                  />
                )}
              />

              {marketplaceUnifiedView && watchCollect ? (
                <Route
                  exact
                  path='/client/:clientId/dashboard'
                  render={({
                    match: {
                      params: { clientId: newClientId },
                    },
                  }) => <Redirect to={`/client/${newClientId}/jobs/`} />}
                />
              ) : (
                <PrivateRoute
                  exact
                  path='/client/:clientId/dashboard'
                  component={DashboardView}
                  toggleMenuVisibility={toggleMenuVisibility}
                />
              )}

              <Route
                exact
                path='/client/:clientId/discover'
                render={(routeProps) => {
                  return (
                    <Redirect
                      to={{
                        ...routeProps?.location, // keep URL params like profileId
                        pathname: `/client/${clientId}/discover/candidates`,
                      }}
                      replace
                    />
                  );
                }}
              />

              <PrivateRoute
                path='/client/:clientId/discover/:tabId'
                component={WatchCollectViewV2}
                searchPoolId='watch'
              />

              <Route
                exact
                path='/client/:clientId/reveal'
                render={(routeProps) => {
                  return (
                    <Redirect
                      to={{
                        ...routeProps?.location, // keep URL params like profileId
                        pathname: `/client/${clientId}/reveal/projects`,
                      }}
                      replace
                    />
                  );
                }}
              />

              <PrivateRoute
                path='/client/:clientId/reveal/:tabId'
                component={RevealView}
              />

              <Route
                exact
                path='/client/:clientId/offers/:offerId/:pageId'
                render={({
                  match: {
                    params: { offerId, clientId, pageId },
                  },
                }) => (
                  <Redirect
                    to={`/client/${clientId}/jobs/${offerId}/${pageId}`}
                  />
                )}
              />
              <PrivateRoute
                path='/client/:clientId/jobs/:offerId/:pageId'
                component={OfferParametersViewRoute}
                toggleMenuVisibility={toggleMenuVisibility}
              />

              <Route
                exact
                path='/client/:clientId/jobs/:offerId'
                render={({
                  match: {
                    params: { offerId, clientId },
                  },
                }) => (
                  <Redirect
                    to={`/client/${clientId}/jobs/${offerId}/profiles`}
                  />
                )}
              />

              <PrivateRoute
                path='/client/:clientId/:pageId'
                component={ParametersViewRoute}
                toggleMenuVisibility={toggleMenuVisibility}
              />
              <Route
                exact
                path='/client/:clientId'
                render={({ match }) => (
                  <Redirect to={`/client/${match.params.clientId}/dashboard`} />
                )}
              />
            </Switch>

            {user?.firstLogin && client?.permissions?.watchCollect && (
              <MarketplaceOnboardingModal />
            )}

            {!isRevealRoute &&
              user &&
              !user.newPricingValidated &&
              client?.permissions?.watchCollect && (
                <PricingValidationModal clientId={clientId} />
              )}
          </AdminApplicationModeContextProvider>
        </LastProfileActionsProvider>
      </HiresweetLibProvider>
    </AppContextProvider>
  );
};

const MainRouter = compose(
  routerParamsToProps,
  withUserFromJWToken,
  withClient,
  withLanguage,
  withEmailApi,
)(BaseMainRouter);

class AppRouter extends Component {
  static childContextTypes = {
    onShowNotification: PropTypes.func,
    onClearNotifications: PropTypes.func,
  };

  getChildContext() {
    return {
      onShowNotification: this.props.onShowNotification,
      onClearNotifications: this.props.onClearNotifications,
    };
  }

  render() {
    return (
      <FreeTrialUpgradeModalContextProvider>
        <BrowserRouter>
          <Switch>
            {/* Public routes */}
            <Route
              exact
              path='/sharing/:standaloneReferrer/:appId'
              component={SharedSpaceView}
            />
            <Route
              exact
              path='/client/:clientId/signin'
              component={SigninView}
            />
            <Route
              exact
              path='/client/:clientId/signup'
              component={SignupView}
            />
            <Route
              exact
              path='/client/:clientId/reset-password'
              component={ResetPasswordView}
            />
            <Route
              exact
              path='/client/:clientId/forgotten-password'
              component={ForgottenPasswordView}
            />
            {/* <Route exact path='/free-trial' component={TrialSignupView} /> */}
            <Route
              exact
              path='/discover-marketplace'
              component={TrialSignupView}
            />
            <Route exact path='/start-crm-trial' component={CRMSignupView} />
            <Route
              exact
              path='/forgotten-password'
              component={ForgottenPasswordView}
            />
            <Route
              exact
              path='/client/:clientId/signin/sso'
              component={SSOReceptionView}
            />
            <Route
              exact
              path='/ai-generation'
              component={SequenceGenerationDemo}
            />
            {/* Private routes */}
            <PrivateRoute path='/client/:clientId' component={MainRouter} />
            <Route component={RedirectView} path='/redirect*' />
            <Route exact path='/signin' component={GlobalSigninView} />
            <Route
              path='/'
              render={({ location }) => (
                <Redirect to={{ ...location, pathname: '/signin' }} />
              )}
            />
            <Route path='/404' render={() => <Redirect to='/signin' />} />
            <Redirect from='*' to='/404' />
          </Switch>
        </BrowserRouter>
      </FreeTrialUpgradeModalContextProvider>
    );
  }
}

export default withNotifications(AppRouter);
