import { getTranslatedText } from '@/common';
import RichReader from '@/components/RichEditor/RichReader';
import React from 'react';
import ReactPlayer from 'react-player';
import { Image } from 'semantic-ui-react';
import _ from 'underscore';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import GenericDropdown from '@/components/Common/GenericDropdown';
import GenericButton from '@/components/Common/GenericButton';
import ArrowDown from '@/components/Reveal/Icons/ArrowDown';
import useClientId from '@/hooks/router/useClientId';
import { JobsLayout, Theme } from './DescriptionModal';
import styles from './DescriptionPreview.module.less';

interface DescriptionPreviewProps {
  sections: any[];
  theme: Theme;
  layout: JobsLayout;
}

const DescriptionPreview: React.FC<DescriptionPreviewProps> = ({
  sections,
  theme,
  layout,
}) => {
  const { t } = useTranslation();
  const clientId = useClientId();

  return (
    <div>
      <div className={styles.header}>
        <div />
        <div className={styles.headerTitle}>
          <h1>{clientId}</h1>
        </div>
        <div className={styles.links}>
          {_.map(layout?.extraMenuLinks || [], (link, index) => (
            <div key={index} className={styles.link}>
              <a href={link.url} target='_blank' rel='noreferrer'>
                {getTranslatedText(link.title)}
              </a>
            </div>
          ))}
        </div>
      </div>
      {layout?.banner?.file?.url && (
        <div className={styles.banner}>
          <Image src={layout.banner.file.url} />
        </div>
      )}
      {_.map(sections || [], (section, index) => (
        <div
          className={styles.previewSection}
          key={index}
          style={{
            ...(index === sections.length - 1 &&
              theme?.footer?.background && {
                backgroundColor: theme.footer.background,
                width: '100%',
                position: 'relative',
                padding: '24px',
                marginBottom: '0px',
              }),
          }}
        >
          {section.type === 'simple-section' && (
            <div className='simple-section'>
              <div
                className={classNames('title', styles.sectionTitle)}
                style={{
                  ...(index === sections.length - 1 && theme?.footer?.font
                    ? {
                        color: theme.footer.font,
                      }
                    : { color: theme?.font || '' }),
                }}
              >
                <h3>{getTranslatedText(section?.title)}</h3>
              </div>
              <div
                className='content'
                style={{
                  ...(index === sections.length - 1 && theme?.footer?.font
                    ? {
                        color: theme.footer.font,
                      }
                    : { color: theme?.font || '' }),
                }}
              >
                <RichReader initialHtml={getTranslatedText(section?.content)} />
              </div>
            </div>
          )}
          {section.type === 'video-section' && (
            <div className='video-section'>
              <div className='react-player-container'>
                <ReactPlayer
                  className='react-player'
                  url={section.url}
                  width='100%'
                  height='100%'
                  controls
                  config={{
                    youtube: {
                      playerVars: { showinfo: 1, controls: 1 },
                    },
                  }}
                />
              </div>
            </div>
          )}
          {section.type === 'image-section' && (
            <div className='image-section'>
              <Image
                className='react-player'
                src={section.file.url}
                width='100%'
                height='100%'
              />
            </div>
          )}
          {section.type === 'job-postings-list' && (
            <>
              {layout?.showFilter && (
                <GenericDropdown
                  disabled
                  className={styles.jobsfilter}
                  Trigger={({ onClick }) => (
                    <GenericButton
                      primacy='secondary'
                      type='button'
                      onClick={onClick}
                      className={styles.dropdownTrigger}
                      disabled
                    >
                      <span className={styles.title}>
                        {t('reveal.missions.mission.profiles.filter.button')}
                      </span>
                      <span className={styles.arrow}>
                        <ArrowDown />
                      </span>
                    </GenericButton>
                  )}
                />
              )}
              <div
                className={classNames(
                  styles.jobPostingCardsContainer,
                  layout?.displayType === 'grid' ? styles.gridLayout : '',
                )}
              >
                <div
                  className={styles.jobPostingCard}
                  style={{
                    backgroundColor: theme?.jobs?.background,
                    color: theme?.jobs?.font,
                    borderRadius: theme?.jobs?.border?.radius,
                    ...(theme?.jobs?.border?.color && {
                      borderColor: theme?.jobs?.border?.color,
                      borderWidth: '1px',
                      borderStyle: 'solid',
                    }),
                  }}
                >
                  {t('settings.careerPage.description.modal.exampleJobPosting')}{' '}
                  #1
                  {layout?.showFirstSectionPreview && (
                    <div className={styles.firstSectionPreview}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nunc tincidunt risus neque, id pulvinar orci consectetur
                      a. Morbi nibh metus, maximus non nunc eget, dignissim
                      feugiat orci. Vivamus placerat sit amet quam ultricies
                      viverra. Sed dui nisi, sagittis vel hendrerit et, auctor
                      tempus lacus. Donec imperdiet lacus id nulla suscipit
                      scelerisque. Pellentesque fermentum ligula nulla, at
                      consectetur tortor rutrum id. Nullam varius efficitur arcu
                      et scelerisque. Sed lacus enim, porta eu lorem eget,
                      commodo tristique nunc. Vivamus aliquet justo auctor nibh
                      interdum, sed commodo nisl varius. In vitae neque eget
                      mauris iaculis posuere nec in sapien. Fusce pulvinar velit
                      urna. Nulla facilisi. Integer dictum sodales orci vitae
                      tincidunt. Suspendisse vulputate placerat eros, et
                      vestibulum purus consequat non. Ut non libero arcu.
                      Aliquam id dui ullamcorper, viverra ipsum in, faucibus
                      elit.
                    </div>
                  )}
                </div>
                <div
                  className={styles.jobPostingCard}
                  style={{
                    backgroundColor: theme?.jobs?.background,
                    color: theme?.jobs?.font,
                    borderRadius: theme?.jobs?.border?.radius,
                    ...(theme?.jobs?.border?.color && {
                      borderColor: theme?.jobs?.border?.color,
                      borderWidth: '1px',
                      borderStyle: 'solid',
                    }),
                  }}
                >
                  {t('settings.careerPage.description.modal.exampleJobPosting')}{' '}
                  #2
                  {layout?.showFirstSectionPreview && (
                    <div className={styles.firstSectionPreview}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nunc tincidunt risus neque, id pulvinar orci consectetur
                      a. Morbi nibh metus, maximus non nunc eget, dignissim
                      feugiat orci. Vivamus placerat sit amet quam ultricies
                      viverra. Sed dui nisi, sagittis vel hendrerit et, auctor
                      tempus lacus. Donec imperdiet lacus id nulla suscipit
                      scelerisque. Pellentesque fermentum ligula nulla, at
                      consectetur tortor rutrum id. Nullam varius efficitur arcu
                      et scelerisque. Sed lacus enim, porta eu lorem eget,
                      commodo tristique nunc. Vivamus aliquet justo auctor nibh
                      interdum, sed commodo nisl varius. In vitae neque eget
                      mauris iaculis posuere nec in sapien. Fusce pulvinar velit
                      urna. Nulla facilisi. Integer dictum sodales orci vitae
                      tincidunt. Suspendisse vulputate placerat eros, et
                      vestibulum purus consequat non. Ut non libero arcu.
                      Aliquam id dui ullamcorper, viverra ipsum in, faucibus
                      elit.
                    </div>
                  )}
                </div>
                <div
                  className={styles.jobPostingCard}
                  style={{
                    backgroundColor: theme?.jobs?.background,
                    color: theme?.jobs?.font,
                    borderRadius: theme?.jobs?.border?.radius,
                    ...(theme?.jobs?.border?.color && {
                      borderColor: theme?.jobs?.border?.color,
                      borderWidth: '1px',
                      borderStyle: 'solid',
                    }),
                  }}
                >
                  {t('settings.careerPage.description.modal.exampleJobPosting')}{' '}
                  #3
                  {layout?.showFirstSectionPreview && (
                    <div className={styles.firstSectionPreview}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nunc tincidunt risus neque, id pulvinar orci consectetur
                      a. Morbi nibh metus, maximus non nunc eget, dignissim
                      feugiat orci. Vivamus placerat sit amet quam ultricies
                      viverra. Sed dui nisi, sagittis vel hendrerit et, auctor
                      tempus lacus. Donec imperdiet lacus id nulla suscipit
                      scelerisque. Pellentesque fermentum ligula nulla, at
                      consectetur tortor rutrum id. Nullam varius efficitur arcu
                      et scelerisque. Sed lacus enim, porta eu lorem eget,
                      commodo tristique nunc. Vivamus aliquet justo auctor nibh
                      interdum, sed commodo nisl varius. In vitae neque eget
                      mauris iaculis posuere nec in sapien. Fusce pulvinar velit
                      urna. Nulla facilisi. Integer dictum sodales orci vitae
                      tincidunt. Suspendisse vulputate placerat eros, et
                      vestibulum purus consequat non. Ut non libero arcu.
                      Aliquam id dui ullamcorper, viverra ipsum in, faucibus
                      elit.
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      ))}
      {!layout?.hideNativeFooter && (
        <div className={styles.nativeFooter}>
          <a href={`https://join.hiresweet.com/${clientId}/jobs`}>Home Page</a>
        </div>
      )}
    </div>
  );
};

export default DescriptionPreview;
