import React, { useCallback, useState } from 'react';
import _ from 'underscore';
import classNames from 'classnames';
import {
  Button,
  Checkbox,
  Placeholder,
  PlaceholderLine,
  Segment,
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import {
  ARCHIVED_ENGAGEMENT,
  ARCHIVED_REASONS,
  sentryCaptureException,
} from '@/common';
import { useRemoveProfilesFromMission } from '@/graphql/searchPoolProfiles';
import useUpdateProfilesPipelineStage from '@/graphql/hooks/searchPoolJob/useUpdateProfilesMissionPipelineStage';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import MoveToStageAction from '@/components/PipelineSegmentation/MoveToStageAction';
import { Segmentations } from '@/components/PipelineSegmentation/data';
import { useCandidateViewContext } from '@/context/CandidateView/useCandidateViewContext';
import ConfirmationModal from '@/components/modals/ConfirmationModal';

import useUpdateProfileMissionComment from '@/graphql/hooks/searchPoolProfile/useUpdateProfileMissionComment';
import Plus from '@/components/Reveal/Icons/Plus';
import GenericButton from '@/components/Common/GenericButton';
import Edit from '@/components/Reveal/Icons/Edit';
import { ProfileMissionCustomFields } from '@/containers/Profile/Resume/Card/RevealProfileHeader/RevealProfileHeader';
import useClientProcessCustomFields from '@/graphql/hooks/clients/useClientProcessCustomFields';
import styles from './MissionItem.module.less';

interface MissionItemProps {
  searchPoolId: string;
  missionId: string;
  missionTitle: string;
  segmentationId: string;
  stageId: string;
  profileId: string;
  missionsInfoData: any;
  clientId: string;
}
const MissionItem: React.FC<MissionItemProps> = ({
  searchPoolId,
  missionId,
  missionTitle,
  segmentationId,
  stageId,
  profileId,
  missionsInfoData,
  clientId,
}) => {
  const { t } = useTranslation();
  const { error, success } = useNotificationSystem();
  const { profile } = useCandidateViewContext();
  const [missionPipelineOpen, setMissionPipelineOpen] = useState(false);
  const [removeFromMissionModalOpen, setRemoveFromMissionModalOpen] =
    useState(false);
  const [selectedEngagement, setSelectedEngagement] = useState('not-contacted');
  const [selectedReason, setSelectedReason] = useState('');
  const [shouldInterruptSequence, setShouldInterruptSequence] = useState(true);
  const [editComment, setEditComment] = useState(false);
  const [comment, setComment] = useState(missionsInfoData.comment || '');

  const { data: processCustomFieldsData } =
    useClientProcessCustomFields(clientId);

  const [updateCommentMutation, { loading: updateCommentLoading }] =
    useUpdateProfileMissionComment();

  const [updatePipeline, { loading: pipelineUpdateLoading }] =
    useUpdateProfilesPipelineStage();
  const [
    removeProfilesFromMission,
    // @ts-ignore no properly typed because types wrongly guessed by TS from a JS file
    { loading: removeProfileFromMissionLoading },
  ] = useRemoveProfilesFromMission();

  const onChangeStage = async (
    newStageId: string,
    _prevStageId: string,
    labels?: string[],
    clientArchiveReasonId?: string,
    interestedStepId?: string,
  ) => {
    try {
      await updatePipeline({
        missionId,
        stage: newStageId,
        profileIds: [profileId],
        segmentationId,
        labels,
        clientArchiveReasonId,
        interestedStepId,
      });
      success(t('reveal.candidatesView.actionsBox.updateStageSuccess'));
      setMissionPipelineOpen(false);
    } catch (e) {
      sentryCaptureException({ error: e });
      console.info(e);
      error(t('reveal.candidatesView.actionsBox.updateStageError'));
    }
  };

  const onRemoveFromMission = async () => {
    try {
      // @ts-ignore no properly typed because types wrongly guessed by TS from a JS file
      await removeProfilesFromMission({
        variables: {
          searchPoolId,
          input: {
            missionId,
            profileIds: [profileId],
            shouldInterruptSequence,
            answerLabels: _.compact([selectedEngagement, selectedReason]),
            ...(shouldInterruptSequence && {
              sequenceId: profile?.currentSequenceInfo?.sequenceId,
            }),
          },
        },
      });
      success(
        t('reveal.candidatesView.actionsBox.removeFromMissionSuccess', {
          count: 1,
        }),
      );
    } catch (e) {
      sentryCaptureException({ error: e });
      error(t('reveal.candidatesView.actionsBox.removeFromMissionError'));
    }
  };

  const onOpenModal = (): void => {
    const { segmentationStates } = missionsInfoData;
    if (!segmentationStates || segmentationStates[0].state === 'pending') {
      setRemoveFromMissionModalOpen(false);
      onRemoveFromMission();
      return;
    }
    setRemoveFromMissionModalOpen(true);
  };

  const saveComment = useCallback(() => {
    updateCommentMutation({
      variables: {
        searchPoolId,
        input: {
          missionId,
          profileId,
          comment,
        },
      },
    })
      .catch(() => {
        error(t('reveal.candidatesView.actionsBox.updateCommentError'));
      })
      .then(() => {
        setEditComment(false);
      });
  }, [
    missionId,
    profileId,
    searchPoolId,
    updateCommentMutation,
    comment,
    error,
    t,
  ]);

  const handleCommentChange = useCallback(
    (value) => setComment(value),
    [setComment],
  );

  const displayInterruptSequence =
    profile?.currentSequenceInfo?.sequenceId &&
    profile?.currentSequenceInfo?.state !== 'completed';

  return (
    <Segment className='mission-item'>
      <div className='mission-item-main-content'>
        <div className='mission-title-container'>
          <div className='mission-title' title={missionTitle}>
            {missionTitle}
          </div>
          {!_.isEmpty(missionsInfoData.customFields) && (
            <ProfileMissionCustomFields
              customFields={missionsInfoData.customFields}
              customFieldDefinitions={
                processCustomFieldsData?.client?.processCustomFields || []
              }
              profileId={profileId}
              missionId={missionId}
            />
            // <div>Custom Fields</div>
          )}
          {_.isEmpty(missionsInfoData.comment) && !editComment && (
            <div
              className='mission-comment empty'
              onClick={() => setEditComment(true)}
            >
              <Plus />
              {t('reveal.candidatesView.actionsBox.addComment')}
            </div>
          )}
        </div>
        <div style={{ position: 'relative' }}>
          <Button
            className='mission-pipeline-dropdown'
            loading={pipelineUpdateLoading}
            disabled={pipelineUpdateLoading || removeProfileFromMissionLoading}
            onClick={() => setMissionPipelineOpen((prev) => !prev)}
          >
            {t(`reveal.pipelineSegmentations.${stageId}`)}
            <i aria-hidden='true' className='dropdown icon' />
          </Button>
          {missionPipelineOpen && (
            <MoveToStageAction
              missionId={missionId}
              currentStage={stageId}
              stages={Segmentations[0].segments}
              onMove={onChangeStage}
              isSubmitting={pipelineUpdateLoading}
              candidates={[profile]}
              setOpen={setMissionPipelineOpen}
              absolutePosition='right'
              clientId={clientId}
            />
          )}
        </div>

        {removeFromMissionModalOpen && (
          <ConfirmationModal
            open
            onCancel={() => setRemoveFromMissionModalOpen(false)}
            onSubmit={onRemoveFromMission}
            header={`${t(
              'common.remove',
            )} ${`${profile.resumeData?.firstname} ${profile.resumeData?.lastname}`} ${t(
              'reveal.candidatesView.actionsBox.fromMission',
            )}`}
            content={
              <div>
                <div className={styles.reasonsLabel}>
                  <span className={styles.mainTitle}>
                    {t('reveal.candidatesView.actionsBox.reasonsLabel')}
                  </span>{' '}
                  <span className={styles.subTitle}>
                    {t('common.facultative')}
                  </span>
                </div>

                <div className={styles.reasonsButtons}>
                  {ARCHIVED_ENGAGEMENT.map((reason) => (
                    <Button
                      key={reason}
                      className={classNames(
                        styles.button,
                        selectedEngagement === reason && styles.buttonSelected,
                      )}
                      size='small'
                      onClick={() => setSelectedEngagement(reason)}
                    >
                      {t(
                        `reveal.candidatesView.actionsBox.missionsReasons.${reason}`,
                      )}
                    </Button>
                  ))}
                </div>

                {selectedEngagement === 'profile-interested' && (
                  <>
                    <div className={styles.reasonsLabel}>
                      <span className={styles.mainTitle}>
                        {t('common.details')}
                      </span>{' '}
                    </div>
                    <div className={styles.reasonsButtons}>
                      {ARCHIVED_REASONS.map((reason) => (
                        <Button
                          key={reason}
                          className={classNames(
                            styles.button,
                            selectedReason === reason && styles.buttonSelected,
                          )}
                          size='small'
                          onClick={() => setSelectedReason(reason)}
                        >
                          {t(
                            `reveal.candidatesView.actionsBox.missionsReasons.${reason}`,
                          )}
                        </Button>
                      ))}
                    </div>
                  </>
                )}

                {displayInterruptSequence && (
                  <div className={styles.interruptSequence}>
                    <Checkbox
                      label={
                        <label>
                          <span className={styles.checkboxTitle}>
                            {profile?.currentSequenceInfo?.sequence.title}{' '}
                          </span>
                          <span className={styles.checkboxSubtitle}>
                            {t(
                              'reveal.candidatesView.actionsBox.missionsReasons.willBeStopped',
                            )}
                          </span>
                        </label>
                      }
                      defaultChecked={shouldInterruptSequence}
                      onChange={(event, data) => {
                        if (data?.checked) {
                          setShouldInterruptSequence(data.checked);
                        }
                      }}
                    />
                  </div>
                )}
              </div>
            }
            submit={t('common.remove')}
            cancel={t('common.cancel')}
            size='tiny'
          />
        )}

        <Button
          className='mission-remove-button'
          basic
          disabled={pipelineUpdateLoading || removeProfileFromMissionLoading}
          onClick={onOpenModal}
        >
          <i className='ri-close-fill' />
        </Button>
      </div>

      {(missionsInfoData.comment || editComment) && (
        <div className='mission-comment-container'>
          {editComment ? (
            <>
              <textarea
                rows={1}
                autoFocus
                className='mission-comment input'
                defaultValue={missionsInfoData.comment}
                onChange={(e) => handleCommentChange(e.target.value)}
              />
              <GenericButton
                size='small'
                primacy='secondary'
                className='mission-comment-cancel'
                onClick={() => {
                  setComment(missionsInfoData.comment);
                  setEditComment(false);
                }}
              >
                {t('common.cancel')}
              </GenericButton>
              <GenericButton
                size='small'
                primacy='primary'
                className='mission-comment-save'
                onClick={() => {
                  saveComment();
                }}
              >
                {t('common.save')}
              </GenericButton>
            </>
          ) : !updateCommentLoading ? (
            <div
              className='edit-mission-comment'
              onClick={() => setEditComment(true)}
            >
              <div className='mission-comment'>{missionsInfoData.comment}</div>
              <Edit />
            </div>
          ) : (
            <Placeholder>
              <PlaceholderLine />
            </Placeholder>
          )}
        </div>
      )}
    </Segment>
  );
};

export default MissionItem;
