import React, { useCallback } from 'react';

import type { CustomFieldDefinition } from '@/graphql/hooks/clients/useClientProfileCustomFields';
import InputControl from '@/components/InputControl';
import {
  TextAreaControl,
  SelectControl,
  DatePickerControl,
} from '@/components/FormFields';
import { getTranslatedText } from '@/common';

type CustomFieldProps = {
  name: string;
  definition: CustomFieldDefinition;
  isCreation?: boolean;
  onChange?: (fieldId: string, value: unknown) => void;
  className?: string;
  disabled?: boolean;
  currentValue?: string | string[];
};

export function CustomField({
  name,
  definition,
  isCreation = false,
  onChange,
  className,
  disabled = false,
  currentValue,
}: CustomFieldProps) {
  const onFieldChange = useCallback(
    (value) => {
      if (!onChange) {
        return;
      }

      onChange(definition.id, value);
    },
    [onChange, definition],
  );

  const isRequired = isCreation && definition.isMandatoryAtCreation;
  const label = `${getTranslatedText(definition.title)}${isRequired ? '*' : ''}`;

  if (definition.type === 'inline-text') {
    return (
      <InputControl
        containerClassName={className}
        onChange={onFieldChange}
        required={isRequired}
        name={name}
        type='text'
        label={label}
        horizontal
        disabled={disabled}
        defaultValue={currentValue}
      />
    );
  }
  if (definition.type === 'text') {
    return (
      <TextAreaControl
        className={className}
        onChange={onFieldChange}
        required={isRequired}
        name={name}
        label={label}
        horizontal
        disabled={disabled}
        defaultValue={currentValue as string}
      />
    );
  }
  if (definition.type === 'float') {
    return (
      <InputControl
        containerClassName={className}
        onChange={onFieldChange}
        name={name}
        type='number'
        step='any'
        label={label}
        horizontal
        required={isRequired}
        disabled={disabled}
        defaultValue={currentValue}
      />
    );
  }
  if (definition.type === 'integer') {
    return (
      <InputControl
        containerClassName={className}
        onChange={onFieldChange}
        required={isRequired}
        name={name}
        type='number'
        label={label}
        horizontal
        disabled={disabled}
        defaultValue={currentValue}
      />
    );
  }
  if (definition.type === 'enum') {
    const options = definition.options.map((op) => ({
      label: getTranslatedText(op.title),
      value: op.id,
    }));
    return (
      <SelectControl
        className={className}
        required={isRequired}
        name={name}
        onChange={onFieldChange}
        label={label}
        options={options}
        multiple={definition.isMultiselect}
        horizontal
        disabled={disabled}
        defaultValue={currentValue as string[]}
      />
    );
  }
  if (definition.type === 'day') {
    return (
      <DatePickerControl
        required={isRequired}
        name={name}
        label={label}
        horizontal
        disabled={disabled}
        defaultValue={currentValue as string}
        onSelect={onFieldChange}
      />
    );
  }

  console.error(`Custom field ${definition.type} not supported`);

  return null;
}

export default CustomField;
