import _ from 'underscore';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import { ATStype, ATS_LOGOS_BY_TYPE, ATS_NAMES_BY_TYPE } from '@/common/reveal';
import { ApplicationJob } from '@/common/reveal/SourceData.type';
import { getDateFromTimestamp } from '@/common/dates';
import useMinimizedView from '@/hooks/ui/useMinimizedView';
import { getApplicationStageAndStatusText } from './ATSData.helpers';
import LeverReviewsSummary from './Lever';
import BoondManagerApplications from './Boondmanager';
import { ATSSingleDataProps } from './types';
import SuccessFactorsPhoneNumbers from './SuccessFactors/SuccessFactorsPhoneNumbers';

import styles from './ATSData.module.less';

interface ApplicationToJobTagProps {
  jobs?: ApplicationJob[];
  date?: string;
  atsId: ATStype;
  status: any;
}

export const ApplicationToJobTag: React.FC<ApplicationToJobTagProps> = ({
  jobs,
  status,
  date,
  atsId,
}) => {
  const { t } = useTranslation();

  if (_.isEmpty(jobs)) {
    return null;
  }

  const lastUpdateDate = date ? new Date(date) : null;

  const jobTitles = _.compact(_.pluck(jobs || [], 'title')).join(', ');
  return (
    <div className='tag'>
      <div className='tag-content'>
        <div className='tag-content-text'>{jobTitles}</div>
        <span className='tag-content-status'>
          {getApplicationStageAndStatusText({ status }, atsId)}
        </span>
        {lastUpdateDate && lastUpdateDate.toString() !== 'Invalid Date' && (
          <span className='tag-content-date'>
            {t('common.simpleDate', { date: lastUpdateDate })}
          </span>
        )}
      </div>
    </div>
  );
};

export const safeDateParse = (dateString?: string): number => {
  if (!dateString) {
    return 0;
  }
  try {
    return Date.parse(dateString);
  } catch (e) {
    return 0;
  }
};

interface ProfileCreationTagProps {
  creationTimestamp: number;
}

export const ProfileCreationTag: React.FC<ProfileCreationTagProps> = ({
  creationTimestamp,
}) => {
  const { t } = useTranslation();
  if (!creationTimestamp) {
    return null;
  }
  return (
    <>
      <div className='field-name'>
        <i className='ri-calendar-event-line ri-lg' />
        <div className='tag-content-label'>
          {t('profile.resume.ATSData.creationDate')}
        </div>
      </div>
      <div className='tag'>
        <div className='tag-content'>
          <div className='tag-content-text'>
            {t('common.simpleDate', { date: new Date(creationTimestamp) })}
          </div>
        </div>
      </div>
    </>
  );
};

const SafeCandidateRefreshDate: FC<{
  lastRefreshTimestamp: string | number;
}> = ({ lastRefreshTimestamp }) => {
  const { t } = useTranslation();
  const { isMinimized } = useMinimizedView();

  // If the profile -- for some reason -- indicates a sync date lower than this
  // We can assume it was never really synced
  const MINIMUM_ACCEPTABLE_SYNC_DATE = new Date(
    '1990-01-01T00:00:00.000Z',
  ).getTime();

  if (
    new Date(+lastRefreshTimestamp).getTime() < MINIMUM_ACCEPTABLE_SYNC_DATE
  ) {
    return (
      <>
        <span className={styles.atsLastSyncText}>{t('common.lastSync')}</span>
        <span className={styles.atsLastSyncValue}>{t('common.na')}</span>
      </>
    );
  }

  return (
    <>
      <span
        className={classNames(styles.atsLastSyncText, {
          [styles.minimized]: isMinimized,
        })}
      >
        {t('common.lastSync')}
      </span>
      <span className={styles.atsLastSyncValue}>
        {t('common.relativeDate', {
          date: getDateFromTimestamp(lastRefreshTimestamp),
        })}
      </span>
    </>
  );
};

export const CandidateLastRefresh: FC<{
  lastRefreshTimestamp?: string | number;
  noSyncText?: string | null;
}> = ({ lastRefreshTimestamp, noSyncText = null }) => {
  const { t } = useTranslation();
  const { isMinimized } = useMinimizedView();

  if (lastRefreshTimestamp && lastRefreshTimestamp !== '0') {
    return (
      <SafeCandidateRefreshDate lastRefreshTimestamp={lastRefreshTimestamp} />
    );
  }
  return (
    <span
      className={classNames(styles.atsLastSyncText, {
        [styles.minimized]: isMinimized,
      })}
    >
      {noSyncText || t('common.notSync')}
    </span>
  );
};

const ClassicATSSingleData: FC<ATSSingleDataProps> = ({
  atsId,
  atsData,
  parameters,
  isHired,
  filteredApplications,
  lastRefreshTimestamp,
  candidateURL,
}) => {
  const { t } = useTranslation();
  const atsLogo = ATS_LOGOS_BY_TYPE[atsId];

  const { applications, creationDate, phoneNumbers = [] } = atsData || {};
  const creationTimestamp = safeDateParse(creationDate);

  const hasJobs = !!_.find(
    applications || [],
    (application) => (application?.jobs || []).length > 0,
  );

  return (
    <div className='ats-data-card'>
      <h3>
        {atsLogo && <img height='16px' alt='ats logo' src={atsLogo} />}
        {ATS_NAMES_BY_TYPE[atsId] || ''}
        {(
          atsId === 'boondmanager' && 
          !!_.findWhere(parameters ?? [], { key: 'mode', value: 'sales'})
        ) ? ' (Contacts CRM)' : ''}
        <CandidateLastRefresh lastRefreshTimestamp={lastRefreshTimestamp} />
        {candidateURL ? (
          <a href={candidateURL} target='__blank' rel='noopener noreferrer'>
            <i className='ri-share-box-line' />
          </a>
        ) : (
          <div className='add-to-ats-container'>
            {/* eslint-disable-next-line */}
            <a
              className='add-to-ats-button'
              href='#'
              target='__blank'
              rel='noopener noreferrer'
            >
              <i className='ri-add-line' />
              <span>
                {t('profile.resume.ATSData.addToATS', {
                  ATS: ATS_NAMES_BY_TYPE[atsId],
                })}
              </span>
            </a>
          </div>
        )}
      </h3>
      {atsData?.creationDate && (
        <div className='row'>
          <ProfileCreationTag creationTimestamp={creationTimestamp} />
        </div>
      )}
      {filteredApplications && !_.isEmpty(filteredApplications) && hasJobs && (
        <div className='row'>
          <div className='field-name'>
            <i className='ri-briefcase-4-line ri-lg' />
            <div className='tag-content-label'>
              {t('profile.resume.ATSData.job', {
                count: filteredApplications?.length,
              })}
            </div>
          </div>
          <div className='jobs-column'>
            {_.map(
              _.sortBy(filteredApplications, 'applicationDate').reverse(),
              ({ status, jobs, lastActivityDate }, index) => {
                if (status?.sourceStatusId === 'converted') {
                  return null;
                }
                return (
                  <>
                    <ApplicationToJobTag
                      key={index}
                      jobs={jobs}
                      status={status}
                      atsId={atsId}
                      date={lastActivityDate}
                    />
                    {/* <ApplicationStatusTag status={status} atsId={atsId} /> */}
                  </>
                );
              },
            )}
          </div>
        </div>
      )}
      <LeverReviewsSummary data={atsData} atsId={atsId} isHired={isHired} />
      <BoondManagerApplications atsId={atsId} application={applications?.[0]} />
      <SuccessFactorsPhoneNumbers atsId={atsId} phoneNumbers={phoneNumbers} />
    </div>
  );
};

export default ClassicATSSingleData;
