import React from 'react';
import {
  useQuery,
  useMutation,
  QueryResult,
  MutationTuple,
  MutationHookOptions,
} from '@apollo/client';
import gql from 'graphql-tag';
import TranslatableText from '@/graphql/fragments/TranslatableText';
import { GET_CLIENT_PROFILE_CUSTOM_FIELDS } from '@/graphql/clients';
import { TranslatableText as TranslatableTextType } from '@/types/text';
import { sanitizeTypename } from '@/common/utils/apollo';

interface ICustomFieldDefinition {
  id: string;
  title: TranslatableTextType;
  display?: 'profile-top' | 'in-details' | null;
  shouldDisplayInCard?: boolean;
  shouldBeHidden?: boolean;
  isMandatoryAtCreation?: boolean;
  isDisplayedAtCreation?: boolean;
  shouldDisplayBelowFullname?: boolean;
  contactCategory?: {
    type: string;
  };
}

export type CustomFieldEnumOption = {
  id: string;
  title: TranslatableTextType;
};

export interface CustomFieldDefinitionEnum extends ICustomFieldDefinition {
  type: 'enum';
  options: CustomFieldEnumOption[];
  isMultiselect: boolean;
}

export interface CustomFieldDefinitionCommon extends ICustomFieldDefinition {
  type: 'inline-text' | 'text' | 'float' | 'integer' | 'day';
}

export interface CustomFieldInput {
  clientCustomFieldId: string;
  type: CustomFieldDefinitionCommon['type'] & CustomFieldDefinitionEnum['type'];
  text?: string;
  float?: number;
  integer?: number;
  selected?: [string];
  date?: string;
}

export type CustomFieldDefinition =
  | CustomFieldDefinitionEnum
  | CustomFieldDefinitionCommon;

export type ResultProfileCustomFields = {
  client: {
    profileCustomFields: CustomFieldDefinition[];
  };
};

export function useClientProfileCustomFields(
  clientId: string,
  onCompleted?: (data?: ResultProfileCustomFields) => void,
): QueryResult<ResultProfileCustomFields, { clientId: string }> & {
  profileCustomFields: CustomFieldDefinition[];
} {
  const query = useQuery<ResultProfileCustomFields, { clientId: string }>(
    GET_CLIENT_PROFILE_CUSTOM_FIELDS,
    { variables: { clientId }, onCompleted },
  );
  const { profileCustomFields } = query.data?.client || {};
  const fields = React.useMemo(
    () => sanitizeTypename(profileCustomFields || []),
    [profileCustomFields],
  );
  return {
    ...query,
    profileCustomFields: fields,
  };
}

export default useClientProfileCustomFields;

const updateClientProfileCustomFields = gql`
  mutation updateProfileCustomFieldsSettings(
    $input: ProfileCustomFieldsSettingsInput
  ) {
    updateProfileCustomFieldsSettings(input: $input) {
      id
      profileCustomFields {
        type
        id
        display
        contactCategory {
          type
        }
        title {
          ...TranslatableText
        }
        ... on CustomFieldDefinitionEnum {
          options {
            id
            title {
              ...TranslatableText
            }
          }
        }
        isDisplayedAtCreation
        isMandatoryAtCreation
      }
    }
  }
  ${TranslatableText}
`;
export function useClientProfileCustomFieldsMutation(
  options: MutationHookOptions = {},
): MutationTuple<
  {
    updateProfileCustomFieldsSettings: {
      profileCustomFields: CustomFieldDefinition[];
    };
  },
  { input: { fields: CustomFieldDefinition[] } }
> {
  return useMutation(updateClientProfileCustomFields, {
    ...options,
    variables: options.variables as {
      input: { fields: CustomFieldDefinition[] };
    },
    refetchQueries: ['getClientProfileCustomFields'],
  });
}
