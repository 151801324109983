import gql from 'graphql-tag';
import {
  useLazyQuery,
  type QueryResult,
  type QueryHookOptions,
} from '@apollo/client';
import { useCallback, useContext } from 'react';

import type { SearchPoolJobFiltersInput } from '@/graphql/searchPoolJobs';
import MissionCategoryContext from '@/context/MissionCategoryContext';

const getUnlistedProjects = gql`
  query getUnlistedProjects(
    $searchPoolId: ID!
    $filters: SearchPoolJobFiltersInput
  ) {
    searchPool(id: $searchPoolId) {
      id
      jobs(filters: $filters) {
        id
        data {
          title
        }
      }
    }
  }
`;

type Data = {
  searchPool: {
    id: string;
    jobs: {
      id: string;
      data: {
        title: string;
      };
    }[];
  };
};

type Variables = {
  searchPoolId: string;
  filters: SearchPoolJobFiltersInput;
};

type Input = {
  searchPoolId?: string;
  queryOptions?: QueryHookOptions<Data, Variables>;
};

function useUnlistedProjects({
  searchPoolId = 'reveal',
  queryOptions = {},
}: Input = {}) {
  const missionCategoryType = useContext(MissionCategoryContext);
  const [query, result] = useLazyQuery<Data, Variables>(getUnlistedProjects, {
    ...queryOptions,
  });
  const fetch = useCallback(
    async (title: string) => {
      const res = await query({
        variables: {
          searchPoolId,
          filters: {
            title,
            unlistedMode: 'unlistedOnly',
            includeAll: true,
            missionCategory: { type: missionCategoryType },
          },
        },
      });
      return enrichResult(res);
    },
    [query, searchPoolId, missionCategoryType],
  );
  return [fetch, enrichResult(result)] as const;
}

function enrichResult(result: QueryResult<Data, Variables>) {
  const projects = result.data?.searchPool.jobs ?? [];
  return { ...result, projects };
}

export default useUnlistedProjects;
