import gql from 'graphql-tag';
import CustomFieldsValues from './CustomFieldsValues';

export default gql`
  fragment ProfileMissionInfo on ProfileMissionInfo {
    missionId
    insertionDate
    data {
      archived
      comment
      segmentationStates {
        segmentationId
        state
        interestedStepId
      }
      customFields {
        ...CustomFieldsValues
      }
    }
  }
  ${CustomFieldsValues}
`;
