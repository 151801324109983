import React from 'react';
import {
  useQuery,
  useMutation,
  QueryResult,
  MutationTuple,
  MutationHookOptions,
} from '@apollo/client';
import gql from 'graphql-tag';
import TranslatableText from '@/graphql/fragments/TranslatableText';
import { GET_CLIENT_PROCESS_CUSTOM_FIELDS } from '@/graphql/clients';
import { TranslatableText as TranslatableTextType } from '@/types/text';
import { sanitizeTypename } from '@/common/utils/apollo';

interface ICustomFieldDefinition {
  id: string;
  title: TranslatableTextType;
  shouldDisplayInCard?: boolean;
  shouldBeHidden?: boolean;
  isMandatoryAtCreation?: boolean;
  isDisplayedAtCreation?: boolean;
  shouldDisplayBelowFullname?: boolean;
  contactCategory?: {
    type: string;
  };
}

export type CustomFieldEnumOption = {
  id: string;
  title: TranslatableTextType;
};

export interface CustomFieldDefinitionEnum extends ICustomFieldDefinition {
  type: 'enum';
  options: CustomFieldEnumOption[];
  isMultiselect: boolean;
}

export interface CustomFieldDefinitionCommon extends ICustomFieldDefinition {
  type: 'inline-text' | 'text' | 'float' | 'integer' | 'day';
}

export interface CustomFieldInput {
  clientCustomFieldId: string;
  type: CustomFieldDefinitionCommon['type'] & CustomFieldDefinitionEnum['type'];
  text?: string;
  float?: number;
  integer?: number;
  selected?: [string];
  date?: string;
}

export type CustomFieldDefinition =
  | CustomFieldDefinitionEnum
  | CustomFieldDefinitionCommon;

export type ResultProcessCustomFields = {
  client: {
    processCustomFields: CustomFieldDefinition[];
  };
};

export function useClientProcessCustomFields(
  clientId: string,
  onCompleted?: (data?: ResultProcessCustomFields) => void,
): QueryResult<ResultProcessCustomFields, { clientId: string }> & {
  processCustomFields: CustomFieldDefinition[];
} {
  const query = useQuery<ResultProcessCustomFields, { clientId: string }>(
    GET_CLIENT_PROCESS_CUSTOM_FIELDS,
    { variables: { clientId }, onCompleted },
  );
  const { processCustomFields } = query.data?.client || {};
  const fields = React.useMemo(
    () => sanitizeTypename(processCustomFields || []),
    [processCustomFields],
  );
  return {
    ...query,
    processCustomFields: fields,
  };
}

export default useClientProcessCustomFields;

const updateClientProcessCustomFields = gql`
  mutation updateProcessCustomFieldsSettings(
    $input: ProcessCustomFieldsSettingsInput
  ) {
    updateProcessCustomFieldsSettings(input: $input) {
      id
      processCustomFields {
        type
        id
        display
        contactCategory {
          type
        }
        title {
          ...TranslatableText
        }
        ... on CustomFieldDefinitionEnum {
          options {
            id
            title {
              ...TranslatableText
            }
          }
        }
        isDisplayedAtCreation
        isMandatoryAtCreation
      }
    }
  }
  ${TranslatableText}
`;
export function useClientProcessCustomFieldsMutation(
  options: MutationHookOptions = {},
): MutationTuple<
  {
    updateProcessCustomFieldsSettings: {
      processCustomFields: CustomFieldDefinition[];
    };
  },
  { input: { fields: CustomFieldDefinition[] } }
> {
  return useMutation(updateClientProcessCustomFields, {
    ...options,
    variables: options.variables as {
      input: { fields: CustomFieldDefinition[] };
    },
    refetchQueries: ['getClientProcessCustomFields'],
  });
}
