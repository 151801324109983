/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
import _ from 'underscore';
import React, { ReactNode, useMemo } from 'react';
import { AtsData } from '@/common/reveal/SourceData.type';
import { SearchPoolProfile } from '@/types/searchPoolProfile';
import useClientProfileCustomFields from '@/graphql/hooks/clients/useClientProfileCustomFields';


import {
  ApplicationToJobTag,
  ProfileCreationTag,
  safeDateParse,
} from './ATSData';


type ApplicationsSectionProps = {
  type: string;
  applications: AtsData['applications'];
};

const ApplicationsSection: React.FC<ApplicationsSectionProps> = ({
  type,
  applications,
}) => {
  const hasJobs = !!_.find(
    applications || [],
    (application) => (application?.jobs || []).length > 0,
  );

  return (
    <div style={{ marginBottom: 10 }}>
      {applications && !_.isEmpty(applications) && hasJobs && (
        <div className='row'>
          <div className='field-name'>
            <i className='ri-briefcase-4-line ri-lg' />
            <div className='tag-content-label'>
              {type === 'recruitcrm'
                ? 'RCRM Jobs'
                : type === 'tool4staffing'
                ? 'T4S Jobs'
                : 'Jobs'}
            </div>
          </div>
          <div className='jobs-column'>
            {_.map(
              _.sortBy(applications, 'applicationDate').reverse(),
              ({ status, jobs, lastActivityDate }, index) => {
                if (status?.sourceStatusId === 'converted') {
                  return null;
                }
                return (
                  <div key={index}>
                    <ApplicationToJobTag
                      key={index}
                      jobs={jobs}
                      status={status}
                      atsId='generic'
                      date={lastActivityDate}
                    />
                    {/* <ApplicationStatusTag status={status} atsId={atsId} /> */}
                  </div>
                );
              },
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const MainTags: React.FC<{ atsData: AtsData, infoTags: string[] }> = ({ atsData, infoTags }) => {
  const { tags } = atsData;

  const tagsCategories: { id: string; tags: string[] }[] = [
    { id: 'infos', tags: [...infoTags] },
  ];

  for (const tag of tags ?? []) {
    if (tag.sourceTagId.startsWith('t4s')) {
      tagsCategories[0]?.tags.push(tag.name?.default ?? '');
    }
  }

  return (
    <div>
      {_.map(
        _.filter(tagsCategories, (cat) => !_.isEmpty(cat.tags)),
        (tagCategory) => (
          <div key={tagCategory.id} className='row'>
            <div className='field-name'>
              <i className='ri-bookmark-3-line ri-lg' />
              <div className='tag-content-label'>
                {tagCategory.id === 't4s' ? 'T4S' : "Infos"}
              </div>
            </div>
            <div className='jobs-column'>
              {_.map(tagCategory.tags, (tag) => (
                <div key={tag} className='tag'>
                  <div className='tag-content'>
                    <div
                      className='tag-content-text'
                      style={{ lineHeight: '18px' }}
                    >
                      {tag}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ),
      )}
    </div>
  );
};

type OptionType = {
  id: string; 
  title: { default: string };
};

const CBATSSingleData = ({
  clientId,
  profile,
  atsData,
} : {
  clientId: string;
  profile: SearchPoolProfile;
  atsData: AtsData;
}) : ReactNode => {
  const { profileCustomFields : customFieldDefinitions, loading } = useClientProfileCustomFields(clientId);
  const { applications, creationDate } = atsData || {};
  const creationTimestamp = safeDateParse(creationDate);

  const titleFromCustomFieldIdAndId = useMemo(() => {
    const result : Record<string, Record<string,string>>  = {};
    for (const customFieldDefinition of customFieldDefinitions) {
      
      const options = ((customFieldDefinition as any).options ?? []) as OptionType[];

      for (const option of options) {
        if (!result[customFieldDefinition.id]) {
          result[customFieldDefinition.id] = {};
        }
        if (result[customFieldDefinition.id]) {
          result[customFieldDefinition.id][option.id] = option.title.default;
        }
      }
    }
    return result; 
  }, [customFieldDefinitions]);
  

  const infoTags = useMemo(() => {
    const result : string[] = [];

    for (const customField of profile.resumeData?.customFields ?? []) {
      if (customField.id === 'langues') {
        result.push(_.map(
          (customField as any).selected as string[],
          languageId => titleFromCustomFieldIdAndId.langues?.[languageId] ?? languageId
        ).join(', '));
      }
    }

    return result;
  }, [profile, titleFromCustomFieldIdAndId]);


  if (loading) {
    return null;
  }

  return (
    <div className='ats-data-card'>
      <h3>Résumé</h3>
      {atsData?.creationDate && (
        <div className='row'>
          <ProfileCreationTag creationTimestamp={creationTimestamp} />
        </div>
      )}
      <ApplicationsSection
        type='tool4staffing'
        applications={_.where(applications ?? [], { source: 'tool4staffing' })}
      />
      <MainTags atsData={atsData} infoTags={infoTags} />
    </div>
  );
};

export default CBATSSingleData;
