import _ from 'underscore';
import React, { useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import {
  CustomFieldDefinition,
  CustomFieldInput,
} from '@/graphql/hooks/clients/useClientProfileCustomFields';
import CustomField from '@/revealComponents/CustomField';

import styles from './NewCandidateCustomFields.module.less';

interface NewCandidateCustomFieldsProps {
  customFields?: CustomFieldInput[];
  profileCustomFields: CustomFieldDefinition[];
  onChange: (cfs: CustomFieldInput[]) => void;
  readOnlyFields?: string[];
}

function formatCustomFieldInput(
  value: any,
  customField: CustomFieldDefinition,
): CustomFieldInput {
  const typeValueGetters: Record<
    CustomFieldDefinition['type'],
    (value: unknown) => Record<string, unknown>
  > = {
    day: (fieldValue) => {
      return {
        date: new Date(fieldValue as string).toISOString(),
      };
    },
    text: (fieldValue) => {
      return {
        text: fieldValue,
      };
    },
    'inline-text': (fieldValue) => {
      return {
        text: fieldValue,
      };
    },
    enum: (fieldValue) => {
      return {
        selected: fieldValue,
      };
    },
    float: (fieldValue) => {
      return {
        float: Number.parseFloat(fieldValue as string),
      };
    },
    integer: (fieldValue) => {
      return {
        integer: Number.parseInt(fieldValue as string, 10),
      };
    },
  };

  const valueObj = typeValueGetters[customField.type](value);
  return {
    clientCustomFieldId: customField.id,
    type: customField.type,
    ...valueObj,
  };
}

function NewCandidateCustomFields(props: NewCandidateCustomFieldsProps) {
  const methods = useForm();
  return (
    <FormProvider {...methods}>
      <NewCandidateCustomFieldsHandler {...props} />
    </FormProvider>
  );
}

function NewCandidateCustomFieldsHandler({
  customFields,
  profileCustomFields,
  onChange,
  readOnlyFields,
}: NewCandidateCustomFieldsProps) {
  const displayedCustomFields = useMemo(() => {
    return _.filter(
      profileCustomFields,
      ({ isMandatoryAtCreation, isDisplayedAtCreation }) =>
        Boolean(isMandatoryAtCreation || isDisplayedAtCreation),
    );
  }, [profileCustomFields]);
  const onChangeCustomField = useCallback(
    (fieldId: string, value: unknown) => {
      const profileCustomField = _.findWhere(profileCustomFields, {
        id: fieldId,
      });
      if (!profileCustomField) {
        return null;
      }

      const customFieldInput: CustomFieldInput = formatCustomFieldInput(
        value,
        profileCustomField,
      );

      const customFieldsById = _.indexBy(
        customFields || [],
        'clientCustomFieldId',
      );
      customFieldsById[fieldId] = customFieldInput;

      if (!value || value?.length === 0) {
        delete customFieldsById[fieldId];
      }

      return onChange(Object.values(customFieldsById));
    },
    [onChange, customFields, profileCustomFields],
  );

  return _.map(displayedCustomFields, (customField) => {
    return (
      <CustomField
        key={customField.id}
        isCreation
        definition={customField}
        name={customField.id}
        onChange={onChangeCustomField}
        className={styles.inputControl}
        disabled={readOnlyFields?.includes(customField.id)}
        currentValue={
          _.findWhere(customFields || [], {
            clientCustomFieldId: customField.id,
          })?.rawValue || ''
        }
      />
    );
  }) as unknown as JSX.Element;
}

export default NewCandidateCustomFields;
