import React, { useState } from 'react';
import { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import Attachment from '@/graphql/fragments/Attachment';
import ConfirmationModal from '../../../../components/modals/ConfirmationModal';
import withDeleteAttachment from '../../../../hocs/profiles/withDeleteProfileAttachment';

const DELETE_SEARCH_POOL_PROFILE_ATTACHMENT = gql`
  mutation deleteAttachment(
    $searchPoolId: ID!
    $input: DeleteAttachmentInput!
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      deleteAttachment(input: $input) {
        profile {
          id
          attachments {
            ...Attachment
            file {
              extension
              url
            }
          }
        }
      }
    }
  }
  ${Attachment}
`;

const DeleteButton = ({
  deleteProfileAttachment,
  id,
  searchPoolId,
  profileId,
  icon,
  t,
}) => {
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [deleteSearchPoolProfileAttachment] = useMutation(
    DELETE_SEARCH_POOL_PROFILE_ATTACHMENT,
  );
  const className = icon ? 'download-button' : 'tertiary';

  const onDeleteAttachment = async () => {
    if (searchPoolId) {
      await deleteSearchPoolProfileAttachment({
        variables: { searchPoolId, input: { attachmentId: id, profileId } },
      });
    } else {
      await deleteProfileAttachment({ attachmentId: id, profileId });
    }
    setConfirmationModalOpen(false);
  };
  return (
    <>
      <Button
        className={className}
        onClick={() => setConfirmationModalOpen(true)}
      >
        <i className='ri-delete-bin-line' />
        {!icon && t('common.delete')}
      </Button>
      <ConfirmationModal
        header={t('profile.resume.attachments.delete')}
        submit={t('common.delete')}
        onSubmit={onDeleteAttachment}
        open={confirmationModalOpen}
        onCancel={() => setConfirmationModalOpen(false)}
      />
    </>
  );
};

export default compose(
  withDeleteAttachment,
  withTranslation('translations'),
)(DeleteButton);
