import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/client';
import useDataUpdateSubscription from '@/graphql/dataUpdateSubscription/useDataUpdateSubscription';
import SearchPoolProfile from './fragments/SearchPoolProfile';
import Task from './fragments/Task';
import ProfileSequenceInfoWithSequence from './fragments/ProfileSequenceInfoWithSequence';
import ProfileMissionInfoWithMission from './fragments/ProfileMissionInfoWithMission';
import ContactFlow from './fragments/ContactFlow';

export const SEARCH_POOL_JOB_PROFILE_RESULTS = gql`
  query getSearchPoolJobProfileResults(
    $searchPoolId: ID!
    $jobId: ID!
    $segmentationId: String
    $step: String
    $type: String
    $searchText: String
    $searchEmail: String
    $searchPhoneNumber: String
    $extraFilters: ProfileResultsExtraFilters
    $sortBy: [ProfilesSortBy!]
  ) {
    searchPool(id: $searchPoolId) {
      id
      job(id: $jobId) {
        id
        profileResults(
          segmentationId: $segmentationId
          step: $step
          type: $type
          searchText: $searchText
          searchEmail: $searchEmail
          searchPhoneNumber: $searchPhoneNumber
          extraFilters: $extraFilters
          sortBy: $sortBy
        ) {
          profileId
          sequenceId
          state
          step
        }
      }
    }
  }
`;

/**
 * @typedef {Object} SortOption
 * @property {string} key
 * @property {string} order
 * */

/**
 * @param {Object} input
 * @param {string} input.jobId
 * @param {string} [input.segmentationId]
 * @param {string} [input.step]
 * @param {string} [input.searchText]
 * @param {string} [input.searchEmail]
 * @param {string} [input.searchPhoneNumber]
 * @param {string} [input.type]
 * @param {import('./hooks/searchPoolJob/useSegmentCounts').ExtraFilters} [input.extraFilters]
 * @param {SortOption[]} [input.sortBy]
 * @param {import('@apollo/client').QueryHookOptions} [input.queryOptions]
 * */
export const useSearchPoolJobProfileResults = ({
  jobId,
  segmentationId,
  step,
  searchText,
  searchEmail,
  searchPhoneNumber,
  type,
  extraFilters,
  sortBy,
  queryOptions = {},
}) => {
  const variables = {
    searchPoolId: 'reveal',
    jobId,
    segmentationId,
    searchText,
    searchEmail,
    searchPhoneNumber,
    step,
    type,
    extraFilters,
    sortBy,
  };
  const { data, loading, error, refetch } = useQuery(
    SEARCH_POOL_JOB_PROFILE_RESULTS,
    {
      variables,
      fetchPolicy: 'network-only',
      ...queryOptions,
    },
  );
  useDataUpdateSubscription({
    type: 'onProfilesAddedToMission',
    // missionId == jobId
    match: { missionId: jobId },
    onData: () => refetch(),
  });
  useDataUpdateSubscription({
    type: 'onProfilesRemovedFromMission',
    // missionId == jobId
    match: { missionId: jobId },
    onData: () => refetch(),
  });
  const profileResults = data?.searchPool?.job?.profileResults;
  return {
    profileResults,
    loading,
    error,
    refetch,
  };
};

const SEARCH_POOL_JOB_PROFILES = gql`
  query getSearchPoolJobProfiles(
    $searchPoolId: ID!
    $jobId: ID!
    $ids: [ID!]
    $step: String
    $segmentationId: String
  ) {
    searchPool(id: $searchPoolId) {
      id
      job(id: $jobId) {
        id
        profiles(ids: $ids, step: $step, segmentationId: $segmentationId) {
          ...SearchPoolProfile
          explicitTasks {
            ...Task
          }
        }
      }
    }
  }
  ${SearchPoolProfile}
  ${Task}
`;

const GetJobRecommendedProfiles = gql`
  query getRecommendedProfiles(
    $searchPoolId: ID!
    $missionId: ID!
    $state: String
    $skip: Int
    $limit: Int
  ) {
    searchPool(id: $searchPoolId) {
      id
      job(id: $missionId) {
        id
        recommendedProfiles(state: $state, skip: $skip, limit: $limit) {
          total
          profiles {
            id
            profile {
              id
              recommendedMissions {
                missionId
                skipped
              }
              missionsInfo {
                missionId
              }
            }
            recommendationDate
            recommendationId
          }
        }
        recommendedProfilesStatesCount {
          active
          accepted
          skipped
        }
      }
    }
  }
`;

export function useJobRecommendedProfiles(
  searchPoolId,
  missionId,
  state,
  { skip = 0, limit = 20 } = {},
) {
  const query = useQuery(GetJobRecommendedProfiles, {
    variables: { searchPoolId, missionId, state, skip, limit },
    fetchPolicy: 'network-only',
  });

  const { total = 0, profiles = [] } =
    query.data?.searchPool?.job?.recommendedProfiles || {};

  return {
    ...query,
    totalPages: Math.ceil(total / limit),
    total,
    profiles: profiles?.map((p) => ({
      ...p.profile,
      recommendationDate: p.recommendationDate,
      recommendationId: p.recommendationId,
    })),
    profilesStatesCount:
      query.data?.searchPool?.job?.recommendedProfilesStatesCount,
  };
}

const skipRecommendedProfile = gql`
  mutation skipRecommendedMission(
    $searchPoolId: ID!
    $profileId: ID!
    $missionId: ID!
    $reason: String
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      skipRecommendedMission(
        profileId: $profileId
        missionId: $missionId
        reason: $reason
      ) {
        ...SearchPoolProfile
      }
    }
  }
  ${SearchPoolProfile}
`;

export function useSkipRecommmendedProfileMutation(searchPoolId, missionId) {
  const [mutate] = useMutation(skipRecommendedProfile, {
    refetchQueries: ['getRecommendedProfiles'],
  });

  return [
    (profileId, reason, options) =>
      mutate({
        ...options,
        variables: { searchPoolId, missionId, profileId, reason },
      }),
  ];
}

const skipApplicationProfile = gql`
  mutation skipApplicationMission(
    $searchPoolId: ID!
    $profileId: ID!
    $missionId: ID!
    $reason: String
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      skipApplicationMission(
        profileId: $profileId
        missionId: $missionId
        reason: $reason
      ) {
        ...SearchPoolProfile
      }
    }
  }
  ${SearchPoolProfile}
`;

export function useSkipApplicationProfileMutation(searchPoolId, missionId) {
  const [mutate] = useMutation(skipApplicationProfile, {
    refetchQueries: ['getApplicantProfiles'],
  });

  return [
    (profileId, reason, options) =>
      mutate({
        ...options,
        variables: { searchPoolId, missionId, profileId, reason },
      }),
  ];
}

const confirmRecommendedProfile = gql`
  mutation confirmRecommendedMission(
    $searchPoolId: ID!
    $profileId: ID!
    $missionId: ID!
    $sequenceId: ID
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      confirmRecommendedMission(
        profileId: $profileId
        missionId: $missionId
        sequenceId: $sequenceId
      ) {
        id
        currentSequenceInfo {
          ...ProfileSequenceInfoWithSequence
        }
        missionsInfo {
          ...ProfileMissionInfoWithMission
        }
        contactFlow {
          ...ContactFlow
        }
      }
    }
  }
  ${ContactFlow}
  ${ProfileSequenceInfoWithSequence}
  ${ProfileMissionInfoWithMission}
`;

export function useConfirmRecommendedProfileMutation(searchPoolId, missionId) {
  const [mutate] = useMutation(confirmRecommendedProfile, {
    refetchQueries: ['getRecommendedProfiles', 'getSearchPoolJob'],
  });

  return [
    (profileId, sequenceId, options) =>
      mutate({
        ...options,
        variables: { searchPoolId, missionId, profileId, sequenceId },
      }),
  ];
}

const confirmApplicationProfile = gql`
  mutation confirmApplicationMission(
    $searchPoolId: ID!
    $profileId: ID!
    $missionId: ID!
    $sequenceId: ID
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      confirmApplicationMission(
        profileId: $profileId
        missionId: $missionId
        sequenceId: $sequenceId
      ) {
        id
        currentSequenceInfo {
          ...ProfileSequenceInfoWithSequence
        }
        missionsInfo {
          ...ProfileMissionInfoWithMission
        }
        contactFlow {
          ...ContactFlow
        }
      }
    }
  }
  ${ContactFlow}
  ${ProfileSequenceInfoWithSequence}
  ${ProfileMissionInfoWithMission}
`;

export function useConfirmApplicationProfileMutation(searchPoolId, missionId) {
  const [mutate] = useMutation(confirmApplicationProfile, {
    refetchQueries: ['getApplicantProfiles', 'getSearchPoolJob'],
  });

  return [
    (profileId, sequenceId, options) =>
      mutate({
        ...options,
        variables: { searchPoolId, missionId, profileId, sequenceId },
      }),
  ];
}

const removeRecommendedProfile = gql`
  mutation removeRecommendedMission(
    $searchPoolId: ID!
    $profileId: ID!
    $recommendationId: ID!
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      removeRecommendedMission(
        profileId: $profileId
        recommendationId: $recommendationId
      ) {
        id
      }
    }
  }
`;

export function useRemoveRecommendedProfileMutation(searchPoolId = 'reveal') {
  const [mutate] = useMutation(removeRecommendedProfile, {
    refetchQueries: ['getRecommendedProfiles'],
  });
  return [
    (profileId, recommendationId, options) =>
      mutate({
        ...options,
        variables: {
          searchPoolId,
          recommendationId,
          profileId,
        },
      }),
  ];
}

const removeApplicationProfile = gql`
  mutation removeApplicationMission(
    $searchPoolId: ID!
    $profileId: ID!
    $applicationId: ID!
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      removeApplicationMission(
        profileId: $profileId
        applicationId: $applicationId
      ) {
        id
      }
    }
  }
`;

export function useRemoveApplicationProfileMutation(searchPoolId = 'reveal') {
  const [mutate] = useMutation(removeApplicationProfile, {
    refetchQueries: ['getApplicantProfiles'],
  });
  return [
    (profileId, applicationId, options) =>
      mutate({
        ...options,
        variables: {
          searchPoolId,
          applicationId,
          profileId,
        },
      }),
  ];
}

// TODO: IDEA - could we filter input "ids" by segmentationId directly from useSearchPoolJobProfileResults ?
export const useSearchPoolJobProfilesById = ({
  jobId,
  ids,
  skip,
  step,
  segmentationId,
}) => {
  const variables = {
    searchPoolId: 'reveal',
    jobId,
    ids,
    step,
    segmentationId,
  };
  const { data, loading, error, refetch } = useQuery(SEARCH_POOL_JOB_PROFILES, {
    variables,
    skip,
    fetchPolicy: 'network-only',
  });
  const profiles = data?.searchPool?.job?.profiles;
  useDataUpdateSubscription({
    type: 'onProfilesChangedMissionStage',
    match: { missionId: jobId },
    onData: () => refetch(),
  });
  return {
    profiles,
    loading,
    error,
    refetch,
  };
};

const GetJobApplicantProfiles = gql`
  query getApplicantProfiles(
    $searchPoolId: ID!
    $missionId: ID!
    $state: String
    $skip: Int
    $limit: Int
  ) {
    searchPool(id: $searchPoolId) {
      id
      job(id: $missionId) {
        id
        applicantProfiles(state: $state, skip: $skip, limit: $limit) {
          total
          profiles {
            id
            profile {
              id
              applicationMissions {
                missionId
                skipped
              }
              missionsInfo {
                missionId
              }
            }
            applicationDate
            applicationId
          }
        }
        applicantProfilesStatesCount {
          active
          accepted
          skipped
        }
      }
    }
  }
`;

export function useJobApplicantProfiles(
  searchPoolId,
  missionId,
  state,
  { skip = 0, limit = 200 } = {},
) {
  const query = useQuery(GetJobApplicantProfiles, {
    variables: { searchPoolId, missionId, state, skip, limit },
    fetchPolicy: 'cache-first',
  });

  const { total = 0, profiles = [] } =
    query.data?.searchPool?.job?.applicantProfiles || {};

  return {
    ...query,
    totalPages: Math.ceil(total / limit),
    total,
    profiles: profiles?.map((p) => ({
      ...p.profile,
      recommendationDate: p.applicationDate,
      recommendationId: p.applicationId,
    })),
    profilesStatesCount:
      query.data?.searchPool?.job?.applicantProfilesStatesCount,
  };
}
