const downloadPDF = async ({
  filename,
  objectURL,
}: {
  filename: string;
  objectURL: string;
}): Promise<null> => {
  const blob = await fetch(objectURL).then((r) => r.blob());

  if ('msSaveBlob' in navigator && typeof navigator.msSaveBlob === 'function') {
    // IE 10+
    navigator.msSaveBlob(blob, filename);
    return null;
  }

  const link = document.createElement('a');

  // feature detection
  if (link.download === undefined) {
    return null;
  }

  // Browsers that support HTML5 download attribute
  link.setAttribute('href', objectURL);
  link.setAttribute('download', filename);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  return null;
};

export default downloadPDF;
