import GenericButton from '@/components/Common/GenericButton';
import GenericModal from '@/components/Common/GenericModal';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'semantic-ui-react';
import GenericTabMenu from '@/components/Common/GenericTabMenu/GenericTabMenu';
import _ from 'underscore';
import styles from './DescriptionModal.module.less';
import DescriptionPreview from './DescriptionPreview';
import DescriptionForm from './DescriptionForm';
import CareerPageDescriptionThemeTab from './DescriptionThemeTab';
import DescriptionJobsLayout from './DescriptionJobsLayout';

export type Theme = {
  background: string;
  font: string;
  jobs?: {
    background?: string;
    border?: {
      color?: string;
      radius?: string;
    };
    font?: string;
  };
  footer?: {
    background?: string;
    font?: string;
  };
};

export type JobsLayout = {
  displayType: 'list' | 'grid';
  showFilter: boolean;
  showFirstSectionPreview: boolean;
  hideSalary: boolean;
  hideRemote: boolean;
  hideNativeFooter: boolean;
  extraMenuLinks: {
    title: {
      default: string;
    };
    url: string;
  }[];
  banner: {
    type: 'image';
    file: {
      url: string;
    };
  };
};

interface CareerPageDescriptionModalProps {
  open: boolean;
  onClose: () => void;
  description: any;
  theme: Theme;
  layout: JobsLayout;
  onUpdateCareerPage: ({
    description,
    theme,
    layout,
  }: {
    description: any;
    theme: Theme;
    layout: JobsLayout;
  }) => void;
}

const CareerPageDescriptionModal: React.FC<CareerPageDescriptionModalProps> = ({
  open,
  onClose,
  description,
  theme,
  layout,
  onUpdateCareerPage,
}) => {
  const { t } = useTranslation();
  const [selectedMenu, setSelectedMenu] = useState('theme');
  const [themeState, setThemeState] = useState({
    ...theme,
    ...(!theme?.background && { background: '' }),
    ...(!theme?.font && { font: '' }),
  });
  const [layoutState, setLayoutState] = useState({
    ...layout,
    ...(!layout?.displayType && { displayType: 'list' }),
    ...(!layout?.showFilter && { showFilter: true }),
  });
  const [descriptionState, setDescriptionState] = useState({
    ...description,
    ...(description?.sections?.length === 1 &&
      description?.sections[0].type === 'job-postings-list' && {
        sections: [
          {
            type: 'simple-section',
            title: {
              default: '',
            },
            content: {
              default: '',
            },
          },
          description.sections[0],
          {
            type: 'simple-section',
            title: {
              default: '',
            },
            content: {
              default: '',
            },
          },
        ],
      }),
  });

  const jobPostingListSectionIndex = useMemo(
    () =>
      _.findIndex(
        descriptionState?.sections,
        (section: any) => section.type === 'job-postings-list',
      ),
    [descriptionState],
  );
  const descriptionHeaderSections =
    descriptionState?.sections?.slice(0, jobPostingListSectionIndex) || [];
  const descriptionFooterSections =
    descriptionState?.sections?.slice(jobPostingListSectionIndex + 1) || [];

  const sectionsToDisplay =
    selectedMenu === 'header'
      ? descriptionHeaderSections
      : descriptionFooterSections || [];

  return (
    <GenericModal open={open} onClose={onClose} size='fullscreen'>
      <Modal.Header>{t('settings.careerPage.description.title')}</Modal.Header>
      <Modal.Content className={styles.modalContentContainer}>
        <div className={styles.modalForm}>
          <div className={styles.modalContent}>
            <div className={styles.formContent}>
              <GenericTabMenu
                menus={[
                  {
                    id: 'theme',
                    label: t('settings.careerPage.description.modal.theme'),
                  },
                  {
                    id: 'jobs',
                    label: t(
                      'settings.careerPage.description.modal.jobPostings',
                    ),
                  },
                  {
                    id: 'header',
                    label: t('settings.careerPage.description.modal.header'),
                  },
                  {
                    id: 'footer',
                    label: t('settings.careerPage.description.modal.footer'),
                  },
                ]}
                activeMenuId={selectedMenu}
                onMenuSelected={setSelectedMenu}
              />
              <div className={styles.formContentContainer}>
                {selectedMenu === 'theme' && (
                  <CareerPageDescriptionThemeTab
                    theme={themeState}
                    onTheme={setThemeState}
                  />
                )}
                {selectedMenu === 'jobs' && (
                  <DescriptionJobsLayout
                    jobsLayout={layoutState}
                    onJobsLayout={setLayoutState}
                  />
                )}
                {_.includes(['header', 'footer'], selectedMenu) && (
                  <DescriptionForm
                    sections={sectionsToDisplay}
                    selectedMenu={selectedMenu}
                    onUpdateSections={(sections) => {
                      setDescriptionState({
                        ...descriptionState,
                        sections:
                          selectedMenu === 'header'
                            ? [
                                ...sections,
                                ...descriptionState.sections.slice(
                                  jobPostingListSectionIndex,
                                ),
                              ]
                            : [
                                ...descriptionState.sections.slice(
                                  0,
                                  jobPostingListSectionIndex + 1,
                                ),
                                ...sections,
                              ],
                      });
                    }}
                  />
                )}
              </div>
            </div>
            <div
              className={styles.previewContainer}
              style={{ backgroundColor: themeState?.background || '' }}
            >
              <DescriptionPreview
                sections={descriptionState?.sections}
                theme={themeState}
                layout={layoutState}
              />
            </div>
          </div>

          <div className={styles.modalActions}>
            <GenericButton
              size='big'
              primacy='secondary'
              onClick={() => onClose()}
            >
              {t('common.cancel')}
            </GenericButton>
            <GenericButton
              size='big'
              onClick={() =>
                onUpdateCareerPage({
                  description: descriptionState,
                  theme: themeState,
                  layout: layoutState,
                })
              }
            >
              {t(
                description?.sections?.length > 1
                  ? 'common.save'
                  : 'reveal.parameters.customTasksSettings.creationModal.create',
              )}
            </GenericButton>
          </div>
        </div>
      </Modal.Content>
    </GenericModal>
  );
};

export default CareerPageDescriptionModal;
