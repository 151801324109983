import React, { useState } from 'react';
import classnames from 'classnames';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { Modal } from 'semantic-ui-react';

import { getTranslatedText } from '@/common';
import { getFieldTypeKey, getFieldTypeIcon } from '@/common/customFields';
import { CustomFieldDefinition } from '@/graphql/hooks/clients/useClientProfileCustomFields';
import { CustomFieldForm } from './CustomFieldForm';
import GenericButton from '../Common/GenericButton';
import Plus from '../Reveal/Icons/Plus';

import styles from './CustomFieldListForm.module.less';

type CustomFieldListFormProps = {
  isProfile?: boolean;
  customFields: CustomFieldDefinition[];
  onChange: (customFields: CustomFieldDefinition[]) => Promise<void> | void;
  canBeDisplayedInCard?: boolean;
  canBeDisplayedAtCreation?: boolean;
  canBeMandatoryAtCreation?: boolean;
};
export const CustomFieldListForm = ({
  customFields,
  onChange,
  isProfile = true,
  canBeDisplayedInCard,
  canBeDisplayedAtCreation,
  canBeMandatoryAtCreation,
}: CustomFieldListFormProps): JSX.Element => {
  const { t } = useTranslation();
  const [fields, setFields] = React.useState(customFields);
  const [deletionModalOpen, setDeletionModalOpen] = useState(false);
  const [customFieldToDelete, setCustomFieldToDelete] =
    useState<CustomFieldDefinition>();
  const [openModal, setOpenModal] = React.useState<{
    open: boolean;
    initial?: CustomFieldDefinition;
  }>({ open: false });

  React.useEffect(() => setFields(customFields), [customFields]);
  const onDragEnd = ({ source, destination }: DropResult) => {
    setFields((currentFields) => {
      const item = currentFields[source.index];
      const without = currentFields.filter((i) => i !== item);

      const newFields = [
        ...without.slice(0, destination!.index),
        item,
        ...without.slice(destination!.index),
      ];

      onChange(newFields);
      return newFields;
    });
  };
  const openEdit = (customField: CustomFieldDefinition) =>
    setOpenModal({ open: true, initial: customField });
  const removeField = (customField: CustomFieldDefinition | undefined) => {
    if (!customField) {
      return;
    }
    setFields((current) => {
      const newFields = current.filter((cf) => cf.id !== customField.id);
      onChange(newFields);
      return newFields;
    });
  };
  const upsertField = (field: CustomFieldDefinition) => {
    const isNewField = fields.every((cf) => cf.id !== field.id);
    const newFields = isNewField
      ? [...fields, field]
      : fields.map((x) => (x.id !== field.id ? x : field));

    Promise.resolve(onChange(newFields)).then(() =>
      setOpenModal({ open: false }),
    );
    setFields(newFields);
  };
  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='main'>
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className={styles.fieldList}
            >
              {fields.map((cf, index) => (
                <CustomFieldListItem
                  key={cf.id}
                  customField={cf}
                  index={index}
                  openEdit={openEdit}
                  markForDeletion={setCustomFieldToDelete}
                  setDeletionModalOpen={setDeletionModalOpen}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <GenericButton
        onClick={() => setOpenModal({ open: true })}
        className={styles.buttonAdd}
      >
        <Plus />
        {t('customFieldsSettings.addField')}
      </GenericButton>
      {openModal.open && (
        <CustomFieldForm
          isProfile={isProfile}
          open={openModal.open}
          onSubmit={upsertField}
          closeModal={() => setOpenModal({ open: false })}
          initial={openModal.initial}
          customFields={fields}
          canBeDisplayedInCard={canBeDisplayedInCard}
          canBeDisplayedAtCreation={canBeDisplayedAtCreation}
          canBeMandatoryAtCreation={canBeMandatoryAtCreation}
        />
      )}
      {deletionModalOpen && (
        <Modal
          open={deletionModalOpen}
          onClose={() => setDeletionModalOpen(false)}
          closeOnEscape
          closeOnDimmerClick
          closeIcon
          size='small'
        >
          <Modal.Header>
            {t('customFieldsSettings.deletionModal.title')}
          </Modal.Header>
          <Modal.Actions>
            <div className={styles.modalActions}>
              <GenericButton
                primacy='secondary'
                size='big'
                onClick={() => setDeletionModalOpen(false)}
              >
                {t('common.cancel')}
              </GenericButton>
              <GenericButton
                size='big'
                onClick={() => {
                  removeField(customFieldToDelete);
                  setDeletionModalOpen(false);
                }}
              >
                {t('common.delete')}
              </GenericButton>
            </div>
          </Modal.Actions>
        </Modal>
      )}
    </>
  );
};

function Handle(props: React.ComponentProps<'div'>) {
  const { className } = props;
  return (
    <div
      role='button'
      {...props}
      className={classnames(styles.handle, className)}
    >
      <i className='ri-menu-line' />
    </div>
  );
}

const Item = React.forwardRef<HTMLDivElement, React.ComponentProps<'div'>>(
  (props, ref) => {
    return (
      <div
        ref={ref}
        {...props}
        className={classnames(styles.fieldItem, props.className)}
      />
    );
  },
);

function CustomFieldListItem({
  customField,
  index,
  markForDeletion,
  openEdit,
  setDeletionModalOpen,
}: {
  customField: CustomFieldDefinition;
  index: number;
  markForDeletion: (customField: CustomFieldDefinition) => void;
  openEdit: (customField: CustomFieldDefinition) => void;
  setDeletionModalOpen: (state: boolean) => void;
}) {
  return (
    <Draggable draggableId={customField.id} index={index}>
      {(provided) => (
        <Item {...provided.draggableProps} ref={provided.innerRef}>
          <Handle {...provided.dragHandleProps} />
          <div
            role='button'
            className={styles.fieldName}
            onClick={() => openEdit(customField)}
          >
            {getTranslatedText(customField.title)}
            <span className={styles.fieldEditIcon}>
              <i className='ri-edit-2-fill' />
            </span>
          </div>
          <div className={styles.cell}>
            <CustomFieldTypeTag field={customField} />
          </div>
          <div className={styles.actions}>
            <div
              role='button'
              onClick={() => {
                markForDeletion(customField);
                setDeletionModalOpen(true);
              }}
              className={styles.fieldAction}
            >
              <i className='ri-delete-bin-line' />
            </div>
          </div>
        </Item>
      )}
    </Draggable>
  );
}

const CustomFieldTypeTag = ({ field }: { field: CustomFieldDefinition }) => {
  const { t } = useTranslation();
  return (
    <span className={styles.fieldTypeTag}>
      <i className={getFieldTypeIcon(field)} />
      <span>
        {t(`customFieldsSettings.fieldTypeLabel.${getFieldTypeKey(field)}`)}
      </span>
    </span>
  );
};

export default CustomFieldListForm;
