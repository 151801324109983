import _ from 'underscore';
import React, { useCallback, useMemo, useState } from 'react';
import useMiniSearchPoolProfileByText from '@/graphql/hooks/profiles/useMiniSearchPoolProfileByText';
import { REVEAL_PROFILE_SEARCH_MAX_NB_RESULTS } from '@/hocs/searchPool/withRevealSearchPoolResults';
import useMiniSearchPoolProfileByNumber from '@/graphql/hooks/profiles/useMiniSearchPoolProfileByNumber';
import useMiniSearchPoolProfileByEmail from '@/graphql/hooks/profiles/useMiniSearchPoolProfileByEmail';

interface ContextInterface {
  loading: boolean;
  searchText: string | null;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  searches: any;
  profileIds: string[];
  searchPoolProfiles: any;
}

const MiniProfilesSearchContext = React.createContext<ContextInterface | null>(
  null,
);

interface MiniProfilesSearchProviderProps {
  contactCategory?: string;
  children: React.ReactNode;
}

const MiniProfilesSearchProvider: React.FC<MiniProfilesSearchProviderProps> = ({
  contactCategory,
  children,
}) => {
  const [searchText, innerSetSearchText] = useState('');
  const [currentSearchType, setSearchType] = useState<
    'text' | 'phone' | 'email' | null
  >(null);
  const searches = [{ free: '{}' }];

  // We use the hooks below without parameters to prevent the following issue: https://github.com/apollographql/apollo-client/issues/9317
  const [
    searchProfilesByText,
    { loading: profilesByTextLoading, data: profilesByTextData },
  ] = useMiniSearchPoolProfileByText();

  const [
    searchProfilesByNumber,
    { loading: profilesByNumberLoading, data: profilesByNumberData },
  ] = useMiniSearchPoolProfileByNumber();

  const [
    searchProfilesByEmail,
    { loading: profilesByEmailLoading, data: profilesByEmailData },
  ] = useMiniSearchPoolProfileByEmail();

  const setSearchText = useCallback(
    (newSearchText) => {
      innerSetSearchText(newSearchText);
      if (!newSearchText) {
        return;
      }
      const trimmedText = newSearchText.trim();
      if (
        trimmedText.search(/^[+]?[(]?\d{2,3}[)]?[-\s.]?\d{1,3}[-\s.]?/gim) >= 0
      ) {
        if (trimmedText.replace(/^\+?33/, '0').replace(/\s/g, '').length >= 8) {
          setSearchType('phone');
          searchProfilesByNumber({
            variables: {
              searchPoolId: 'reveal',
              input: {
                searchNumber: trimmedText,
                limit: REVEAL_PROFILE_SEARCH_MAX_NB_RESULTS,
                ...(contactCategory && { contactCategory }),
              },
            },
          });
        }
        return;
      }

      if (trimmedText.search(/@[a-z]*/gim) > 0) {
        setSearchType('email');
        searchProfilesByEmail({
          variables: {
            searchPoolId: 'reveal',
            input: {
              emails: [trimmedText],
              ...(contactCategory && { contactCategory }),
            },
          },
        });
        return;
      }
      setSearchType('text');
      searchProfilesByText({
        variables: {
          searchPoolId: 'reveal',
          input: {
            searchText: trimmedText,
            limit: REVEAL_PROFILE_SEARCH_MAX_NB_RESULTS,
            ...(contactCategory && { contactCategory }),
          },
        },
      });
    },
    [
      searchProfilesByText,
      searchProfilesByNumber,
      searchProfilesByEmail,
      contactCategory,
    ],
  );

  const searchPoolProfiles = useMemo(() => {
    if (!currentSearchType || currentSearchType === 'text') {
      return profilesByTextData?.searchPool?.searchProfilesByText || [];
    }
    if (currentSearchType === 'email') {
      return (
        _.compact(profilesByEmailData?.searchPool?.profilesByEmail?.profiles) ||
        []
      );
    }
    return (
      _.compact(profilesByNumberData?.searchPool?.searchProfilesByNumber) || []
    );
  }, [
    profilesByTextData,
    profilesByNumberData,
    currentSearchType,
    profilesByEmailData,
  ]);

  const profileIds = useMemo(
    () => _.pluck(searchPoolProfiles, 'id'),
    [searchPoolProfiles],
  );

  return (
    <MiniProfilesSearchContext.Provider
      value={{
        loading:
          profilesByTextLoading ||
          profilesByNumberLoading ||
          profilesByEmailLoading,
        searchText,
        setSearchText,
        searches,
        profileIds,
        searchPoolProfiles,
      }}
    >
      {children}
    </MiniProfilesSearchContext.Provider>
  );
};

function useMiniProfilesSearchContext() {
  const context = React.useContext(MiniProfilesSearchContext);
  if (!context) {
    throw new Error(
      'useMiniProfilesSearchContext must be used within a MiniProfilesSearchProvider',
    );
  }
  return context;
}

export { MiniProfilesSearchProvider, useMiniProfilesSearchContext };
