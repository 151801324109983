import _ from 'underscore';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useClientId from '@/hooks/router/useClientId';
import downloadPDF from '@/common/downloadPDF';
import useGenerateDocument from '@/graphql/hooks/useGenerateDocument';
import { Segment } from 'semantic-ui-react';
import useSearchPoolJob from '@/graphql/hooks/searchPoolJobs/useSearchPoolJob';
import {
  Button,
  ProjectContainer,
  ProjectInfoDocuments,
} from '@hiresweet/hiresweet-lib';

import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';
import styles from './Documents.module.less';

type Props = {
  jobId: string;
};

function Documents({ jobId }: Props) {
  const { t } = useTranslation();
  const clientId = useClientId();
  const { permissions } = useClientPermissions(clientId);
  const [generateDocument, { loading: generationLoading }] =
    useGenerateDocument();
  const { data: job } = useSearchPoolJob('reveal', jobId);

  const triggerGenerateDocument = useCallback(
    async ({ documentId }: { documentId: string }) => {
      const { data } = await generateDocument({
        documentId,
        clientIds: [clientId],
        profileIds: [],
        missionIds: [jobId],
      });
      if (data?.generateDocument?.document?.file?.content) {
        downloadPDF({
          filename:
            data?.generateDocument?.document?.file?.name || 'document.docx',
          objectURL: data?.generateDocument?.document?.file?.content,
        });
      }
    },
    [clientId, generateDocument, jobId],
  );

  return (
    <Segment className='pure-criteria-form'>
      {permissions?.documentGeneration && (
        <>
          <h3>
            {t('reveal.missions.mission.settingsTab.documents.generateTitle')}
          </h3>
          <Button
            primacy='secondary'
            onClick={() =>
              triggerGenerateDocument({ documentId: 'aravati-contract' })
            }
            disabled={generationLoading}
          >
            Template de contrat
          </Button>
          <hr className={styles.separator} />
        </>
      )}
      <h3>{t('reveal.missions.mission.settingsTab.documents.title')}</h3>
      <ProjectContainer projectId={jobId}>
        {_.isEmpty(job?.searchPool?.job?.attachments) && (
          <>
            <div className={styles.emptyState}>
              {t('reveal.missions.mission.settingsTab.documents.emptyState')}
            </div>
            <br />
          </>
        )}
        <ProjectInfoDocuments attachments={job?.searchPool?.job?.attachments} />
      </ProjectContainer>
    </Segment>
  );
}

export default Documents;
