import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, DatePicker } from '@hiresweet/hiresweet-lib';
import GenericFormLabel from '@/components/Common/GenericFormLabel';

import useUpdateClientFreeTrialEndDate from './useUpdateClientFreeTrialEndDate';

import styles from './FreeTrialEndDateForm.module.less';

type Props = {
  initialValue: Date;
};

function FreeTrialEndDateForm({ initialValue }: Props) {
  const [updateClientFreeTrialEndDate] = useUpdateClientFreeTrialEndDate();
  const { t } = useTranslation();
  const [date, setDate] = useState(initialValue);
  return (
    <div className={styles.section}>
      <GenericFormLabel className={styles.label}>
        {t('settings.freeTrial.endDate')}
      </GenericFormLabel>
      <div className={styles.container}>
        <DatePicker
          date={date}
          onDate={(newDate) => {
            if (newDate) {
              setDate(newDate);
            }
          }}
        />
        <Button
          onClick={() => {
            updateClientFreeTrialEndDate(date.toISOString());
          }}
          disabled={date.getTime() === initialValue.getTime()}
        >
          {t('common.save')}
        </Button>
      </div>
    </div>
  );
}

export default FreeTrialEndDateForm;
