import * as Sentry from '@sentry/browser';
import React, { PropsWithChildren, ReactNode, useEffect } from 'react';
import classNames from 'classnames';
import { createPortal } from 'react-dom';

import './SidePanel.css';

interface Props {
  title: ReactNode;
  footerContent?: ReactNode;
  onClose?: () => void;
  className?: string;
}

const SidePanel = ({
  className,
  title,
  children,
  footerContent,
  onClose,
}: PropsWithChildren<Props>) => {
  useEffect(() => {
    tryCallChatWidget('remove');
    return () => tryCallChatWidget('load');
  }, []);

  return createPortal(
    <div className={classNames('side-panel', className)}>
      <div className='side-panel-dimmer' onClick={onClose} />
      <div className='side-panel-panel'>
        <div className='side-panel-header'>
          <h4>{title}</h4>
          <i className='ri-close-line' role='button' onClick={onClose} />
        </div>
        <div className='side-panel-content'>{children}</div>
        {footerContent && (
          <div className='side-panel-footer'>{footerContent}</div>
        )}
      </div>
    </div>,
    document.body,
  );
};

const tryCallChatWidget = (methodName: string) => {
  try {
    (window as any).HubSpotConversations?.widget?.[methodName]?.();
  } catch (e) {
    Sentry.captureException(e, { tags: { feature: 'hubspot-chat' } });
  }
};

export default SidePanel;
