/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
import _ from 'underscore';
import React from 'react';
import { AtsData } from '@/common/reveal/SourceData.type';
import { ATSSingleDataProps } from './types';

import {
  ApplicationToJobTag,
  ProfileCreationTag,
  safeDateParse,
} from './ATSData';

type ApplicationsSectionProps = {
  type: string;
  applications: AtsData['applications'];
};

const ApplicationsSection: React.FC<ApplicationsSectionProps> = ({
  type,
  applications,
}) => {
  const hasJobs = !!_.find(
    applications || [],
    (application) => (application?.jobs || []).length > 0,
  );

  return (
    <div style={{ marginBottom: 10 }}>
      {applications && !_.isEmpty(applications) && hasJobs && (
        <div className='row'>
          <div className='field-name'>
            <i className='ri-briefcase-4-line ri-lg' />
            <div className='tag-content-label'>
              {type === 'recruitcrm'
                ? 'RCRM Jobs'
                : type === 'tool4staffing'
                ? 'T4S Jobs'
                : 'Jobs'}
            </div>
          </div>
          <div className='jobs-column'>
            {_.map(
              _.sortBy(applications, 'applicationDate').reverse(),
              ({ status, jobs, lastActivityDate }, index) => {
                if (status?.sourceStatusId === 'converted') {
                  return null;
                }
                return (
                  <div key={index}>
                    <ApplicationToJobTag
                      key={index}
                      jobs={jobs}
                      status={status}
                      atsId='generic'
                      date={lastActivityDate}
                    />
                    {/* <ApplicationStatusTag status={status} atsId={atsId} /> */}
                  </div>
                );
              },
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const MainTags: React.FC<{ atsData: AtsData }> = ({ atsData }) => {
  const { tags } = atsData;

  const tagsCategories: { id: string; tags: string[] }[] = [
    { id: 'lareleve', tags: [] },
    { id: 'tool4staffing', tags: [] },
  ];

  for (const tag of tags ?? []) {
    if (tag.sourceTagId.startsWith('lareleve')) {
      tagsCategories[0]?.tags.push(tag.name?.default ?? '');
    }
    if (tag.sourceTagId.startsWith('aravati')) {
      tagsCategories[1]?.tags.push(tag.name?.default ?? '');
    }
  }

  return (
    <div>
      {_.map(
        _.filter(tagsCategories, (cat) => !_.isEmpty(cat.tags)),
        (tagCategory) => (
          <div key={tagCategory.id} className='row'>
            <div className='field-name'>
              <i className='ri-bookmark-3-line ri-lg' />
              <div className='tag-content-label'>
                {tagCategory.id === 'lareleve' ? 'RCRM tags' : 'T4S tags'}
              </div>
            </div>
            <div className='jobs-column'>
              {_.map(tagCategory.tags, (tag) => (
                <div key={tag} className='tag'>
                  <div className='tag-content'>
                    <div
                      className='tag-content-text'
                      style={{ lineHeight: '18px' }}
                    >
                      {tag}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ),
      )}
    </div>
  );
};

const HumanskillsATSSingleData: React.FC<ATSSingleDataProps> = ({
  atsData,
}) => {
  const { applications, creationDate } = atsData || {};
  const creationTimestamp = safeDateParse(creationDate);

  return (
    <div className='ats-data-card'>
      <h3>Humanskills</h3>
      {atsData?.creationDate && (
        <div className='row'>
          <ProfileCreationTag creationTimestamp={creationTimestamp} />
        </div>
      )}
      <ApplicationsSection
        type='recruitcrm'
        applications={_.where(applications ?? [], { source: 'recruitcrm' })}
      />
      <ApplicationsSection
        type='tool4staffing'
        applications={_.where(applications ?? [], { source: 'tool4staffing' })}
      />
      <MainTags atsData={atsData} />
    </div>
  );
};

export default HumanskillsATSSingleData;
