import gql from 'graphql-tag';
import { QueryHookOptions, useLazyQuery } from '@apollo/client';

import { useCallback } from 'react';
import Attachment from '../fragments/Attachment';

const generateDocument = gql`
  query generateDocument($input: GenerateDocumentInput!) {
    generateDocument(input: $input) {
      document { 
        ...Attachment
        file {
          content
        }
      }
    }
  }
  ${Attachment}
`;

type Data = {
  generateDocument: {
    document: { 
      file: {
        content: string;
      }
    }
  };
};

type Variables = {
  input: GenerateDocumentInput;
};

type GenerateDocumentInput = {
  documentId: string;
  clientIds?: string[];
  missionIds?: string[];
  profileIds?: string[];
};

type Input = {
  queryOptions?: QueryHookOptions<Data, Variables>;
};

function useGenerateDocument({ queryOptions = {} }: Input = {}) {
  const [query, result] = useLazyQuery<Data, Variables>(
    generateDocument,
    queryOptions,
  );
  const fetch = useCallback(
    (input: GenerateDocumentInput) => query({ variables: { input } }),
    [query],
  );
  return [fetch, result] as const;
}

export default useGenerateDocument;
