/* eslint-disable no-underscore-dangle */
import _ from 'underscore';
import { sanitizeTypename } from '@/common/utils/apollo';
import { getTranslatedText, sanitizeURLInput } from '../../../common';

const formatJobPostingInput = (arg) => {
  if (_.isArray(arg)) {
    return _.map(arg, formatJobPostingInput);
  }
  if (_.isObject(arg)) {
    if (arg.__typename === 'MultiSectionsJobPostingDescription') {
      return {
        multiSectionsJobPostingDescription: formatJobPostingInput(
          _.omit(arg, '__typename'),
        ),
      };
    }
    if (arg.__typename === 'SimpleJobPostingDescriptionSection') {
      return {
        simpleJobPostingDescriptionSection: formatJobPostingInput(
          _.omit(arg, '__typename'),
        ),
      };
    }
    if (arg.__typename === 'VideoJobPostingDescriptionSection') {
      return {
        videoJobPostingDescriptionSection: formatJobPostingInput(
          _.omit(arg, '__typename'),
        ),
      };
    }
    if (arg.__typename === 'ImageJobPostingDescriptionSection') {
      return {
        imageJobPostingDescriptionSection: formatJobPostingInput(
          _.omit(arg, '__typename'),
        ),
      };
    }
    if (arg.__typename === 'ContactAndQuestionsJobPostingApplicationForm') {
      return {
        contactAndQuestionsJobPostingApplicationForm: formatJobPostingInput(
          _.omit(arg, '__typename'),
        ),
      };
    }
    if (
      arg.__typename === 'ContactAndExtraQuestionsJobPostingApplicationForm'
    ) {
      return {
        contactAndExtraQuestionsJobPostingApplicationForm:
          formatJobPostingInput(_.omit(arg, '__typename')),
      };
    }
    return _.mapObject(arg, formatJobPostingInput);
  }
  return arg;
};

export const getJobPostingInput = ({ jobPostingState }) => {
  const input = _.pick(
    jobPostingState,
    'id',
    'title',
    'description',
    'locations',
    'published',
    'remote',
    'salary',
    'contractType',
  );
  const formattedInput = formatJobPostingInput(input);
  const sanitizedInput = sanitizeInput(formattedInput);
  return sanitizeTypename(sanitizedInput);
};

export const getCreateJobPostingInput = ({
  jobPostingState,
  careerPageId,
  jobOfferId,
}) => {
  const input = _.pick(
    jobPostingState,
    'id',
    'title',
    'description',
    'locations',
    'published',
    'application',
    'remote',
    'salary',
    'contractType',
  );
  const formattedInput = formatJobPostingInput(input);
  const sanitizedInput = sanitizeInput(formattedInput);
  return {
    ...sanitizeTypename(sanitizedInput),
    jobOfferId,
    careerPageId,
  };
};

const firstSectionIncomplete = ({ jobPosting }) => {
  const firstSection = (jobPosting?.description?.sections || [])[0];
  const { title, content } = firstSection || {};
  return !getTranslatedText(title) || !getTranslatedText(content);
};

export const isInvalidJobPosting = ({ jobPosting }) => {
  return (
    firstSectionIncomplete({ jobPosting }) ||
    !getTranslatedText(jobPosting?.title) ||
    _.isEmpty(jobPosting?.locations)
  );
};

const sanitizeInput = (arg) => {
  if (_.isArray(arg)) {
    return _.map(arg, sanitizeInput);
  }
  if (_.isObject(arg)) {
    if (arg.url && _.isString(arg.url)) {
      // sanitize url in object where key is 'url'
      return _.mapObject(
        { ...arg, url: sanitizeURLInput(arg.url) },
        sanitizeInput,
      );
    }
    return _.mapObject(arg, sanitizeInput);
  }
  return arg;
};
