import React, { useContext, useMemo } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

import useClientProfileCustomFields from '@/graphql/hooks/clients/useClientProfileCustomFields';
import useClientId from '@/hooks/router/useClientId';
import EnumCustomFieldFilter from '@/components/ProfilesViewSettings/EnumCustomFieldFilter';
import GenericAccordion from '@/components/Common/GenericAccordion';
import { getShortLanguage } from '@/common/utils/i18n';
import { getTranslatedText } from '@/common/helpers/translatableText';
import ProfilesViewSettingsContext from '@/context/ProfilesViewSettingsContext';

import AccordionFilter from '../../SearchView/SearchPane/components/AccordionFilter';

function CompaniesCustomFieldFilters() {
  const clientId = useClientId();
  const { t, i18n } = useTranslation();
  const lang = getShortLanguage(i18n.resolvedLanguage);
  const { profileCustomFields } = useClientProfileCustomFields(clientId);
  const [settings] = useContext(ProfilesViewSettingsContext);

  const responsablesCustomField = useMemo(
    () => _.findWhere(profileCustomFields, { id: 'responsables' }),
    [profileCustomFields],
  );

  if (responsablesCustomField?.type !== 'enum') {
    return null;
  }

  return (
    <AccordionFilter
      title={t('reveal.searchView.search.filterCustomProperties')}
      count={_.size(settings.customFieldFilters ?? {})}
    >
      <GenericAccordion
        title={getTranslatedText(lang, responsablesCustomField.title)}
      >
        <EnumCustomFieldFilter customFieldId='responsables' />
      </GenericAccordion>
    </AccordionFilter>
  );
}

export default CompaniesCustomFieldFilters;
