import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

import styles from './GenericFormLabel.module.less';

interface Props {
  secondary?: boolean;
  className?: string;
}

function GenericFormLabel({
  secondary,
  children,
  className,
}: PropsWithChildren<Props>) {
  return (
    <div
      className={classNames(
        styles.label,
        secondary && styles.secondary,
        className,
      )}
    >
      {children}
    </div>
  );
}

export default GenericFormLabel;
