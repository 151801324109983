import gql from 'graphql-tag';
import { useMemo } from 'react';
import {
  MutationHookOptions,
  MutationTuple,
  QueryResult,
  useMutation,
  useQuery,
} from '@apollo/client';

import { GET_CLIENT_MISSION_CUSTOM_FIELDS } from '@/graphql/clients';
import TranslatableText from '@/graphql/fragments/TranslatableText';
import { sanitizeTypename } from '@/common/utils/apollo';
import _ from 'underscore';
import { CustomFieldDefinition } from './useClientProfileCustomFields';

export type ResultMissionCustomFields = {
  client: {
    missionCustomFields: CustomFieldDefinition[];
  };
};

export function useClientMissionCustomFields(
  clientId: string,
  onCompleted?: (data?: ResultMissionCustomFields) => void,
): QueryResult<ResultMissionCustomFields, { clientId: string }> & {
  missionCustomFields: CustomFieldDefinition[];
} {
  const query = useQuery<ResultMissionCustomFields, { clientId: string }>(
    GET_CLIENT_MISSION_CUSTOM_FIELDS,
    { variables: { clientId }, onCompleted },
  );
  const { missionCustomFields } = query.data?.client || {};
  const fields = useMemo(
    () => sanitizeTypename(missionCustomFields || []),
    [missionCustomFields],
  );
  return {
    ...query,
    missionCustomFields: fields,
  };
}

const updateClientMissionCustomFields = gql`
  mutation updateMissionCustomFieldsSettings(
    $input: MissionCustomFieldsSettingsInput
  ) {
    updateMissionCustomFieldsSettings(input: $input) {
      id
      missionCustomFields {
        type
        id
        title {
          ...TranslatableText
        }
        ... on CustomFieldDefinitionEnum {
          options {
            id
            title {
              ...TranslatableText
            }
          }
        }
        shouldDisplayInCard
        shouldBeHidden
        isDisplayedAtCreation
        isMandatoryAtCreation
      }
    }
  }
  ${TranslatableText}
`;

interface ClientMissionCustomFieldsMutationVariables {
  input: { fields: CustomFieldDefinition[] };
}

export function useClientMissionCustomFieldsMutation(
  options: MutationHookOptions = {},
): MutationTuple<
  {
    updateMissionCustomFieldsSettings: {
      MissionCustomFields: CustomFieldDefinition[];
    };
  },
  ClientMissionCustomFieldsMutationVariables
> {
  return useMutation(updateClientMissionCustomFields, {
    ...options,
    variables: options.variables as ClientMissionCustomFieldsMutationVariables,
    refetchQueries: ['getClientMissionCustomFields'],
  });
}
