import SEARCH_POOL_MINI_PROFILE_BY_TEXT_RESULTS from '@/graphql/searchPoolMiniProfileByText';
import { useLazyQuery } from '@apollo/client';

interface useMiniSearchPoolProfileByTextParams {
  searchText: string;
  limit: number;
  contactCategory?: string;
}

const useMiniSearchPoolProfileByText = () =>
  useLazyQuery<
    any,
    { input: useMiniSearchPoolProfileByTextParams; searchPoolId: string }
  >(SEARCH_POOL_MINI_PROFILE_BY_TEXT_RESULTS, {
    fetchPolicy: 'no-cache',
  });

export default useMiniSearchPoolProfileByText;
