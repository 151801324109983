import gql from 'graphql-tag';
import { QueryHookOptions, useQuery } from '@apollo/client';

import { FreeTrialConfiguration } from '@/types/client';
import useClientId from '@/hooks/router/useClientId';

const getFreeTrialConfiguration = gql`
  query getFreeTrialConfiguration($clientId: ID!) {
    client(id: $clientId) {
      id
      freeTrialConfiguration {
        upgraded
        upgradeDate
        startDate
        endDate
      }
    }
  }
`;

type Data = {
  client: {
    freeTrialConfiguration?: FreeTrialConfiguration;
  };
};

type Variables = {
  clientId: string;
};

type Input = {
  queryOptions?: QueryHookOptions<Data, Variables>;
};

function useFreeTrialConfiguration({ queryOptions = {} }: Input = {}) {
  const clientId = useClientId();
  const query = useQuery<Data, Variables>(getFreeTrialConfiguration, {
    ...queryOptions,
    variables: { clientId },
  });
  const freeTrialConfiguration =
    query.data?.client.freeTrialConfiguration ?? {};
  return { ...query, freeTrialConfiguration };
}

export default useFreeTrialConfiguration;
