import React, { useContext, useMemo, useState } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

import ProfilesViewSettingsContext from '@/context/ProfilesViewSettingsContext';
import GenericSelect from '@/components/Common/GenericSelect';
import useClientProfileCustomFields from '@/graphql/hooks/clients/useClientProfileCustomFields';
import useClientId from '@/hooks/router/useClientId';
import { getTranslatedText } from '@/common/helpers/translatableText';
import { getShortLanguage } from '@/common/utils/i18n';

type Props = {
  customFieldId: string;
};

function EnumCustomFieldFilter({ customFieldId }: Props) {
  const { i18n } = useTranslation();
  const lang = getShortLanguage(i18n.resolvedLanguage);
  const clientId = useClientId();
  const [settings, dispatch] = useContext(ProfilesViewSettingsContext);
  const [ids, setIds] = useState(
    settings.customFieldFilters?.[customFieldId]?.value ?? [],
  );
  const { profileCustomFields } = useClientProfileCustomFields(clientId);

  const customField = useMemo(
    () => _.findWhere(profileCustomFields, { id: customFieldId }),
    [profileCustomFields, customFieldId],
  );

  const options = useMemo(
    () =>
      customField?.type === 'enum'
        ? _.map(customField.options, (option) => ({
            value: option.id,
            label: getTranslatedText(lang, option.title),
          }))
        : [],
    [customField, lang],
  );

  const selectedOptions = useMemo(
    () => _.filter(options, ({ value }) => _.includes(ids, value)),
    [ids, options],
  );

  return (
    <GenericSelect
      options={options}
      value={selectedOptions}
      isMulti
      onChange={(value) => {
        const newIds = _.pluck(value ?? [], 'value');
        if (_.isEmpty(newIds)) {
          setIds([]);
          dispatch({
            type: 'setCustomFieldFilter',
            customFieldId,
            value: undefined,
          });
          return;
        }
        setIds(newIds);
        dispatch({
          type: 'setCustomFieldFilter',
          customFieldId,
          value: {
            type: 'enum',
            clientCustomFieldId: customFieldId,
            policy: 'intersect',
            value: newIds,
          },
        });
      }}
    />
  );
}

export default EnumCustomFieldFilter;
