import React, { useEffect, useMemo, useContext } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { Loader } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import useQueryParams from '@/hooks/router/useQueryParams';
import useSearchPoolJob from '@/graphql/hooks/searchPoolJobs/useSearchPoolJob';
import EmptyState from '@/revealComponents/EmptyState/EmptyState';
import { Segmentations } from '@/components/PipelineSegmentation/data';
import PageSelector from '@/components/Common/Pagination/PageSelector';
import { PartitioningSelector } from '@/components/Common/Pagination';
import GenericButton from '@/components/Common/GenericButton';

import './index.css';
import { useClientCurrentAtsId } from '@/graphql/hooks/clients/useClientRevealProjects';
import {
  RecencySegment,
  Segment,
} from '@/components/PipelineSegmentation/pipelineSegmentation';

import MissionCategoryContext from '@/context/MissionCategoryContext';
import ContactCategoryContext from '@/context/ContactTypeContext';
import { useProfilesTableColumnsWithCustomFields } from '../JobView/ProjectProfilesTab/columns/useProfilesTableColumns';
import ProfileRowHeader from '../../ProfileRow/ProfileRowHeader';
import NewProfileRow from '../../ProfileRow';

export const MISSION_PROFILES_TABLE_ID = 'mission-profiles-table-view';

const candidatePerPageOptions = [10, 15, 20, 30, 50];

interface MiniProfile {
  id: string;
}

interface ProfilesTableProps {
  clientId: string;
  tableId?: string;
  onToggleHeaderCheckbox?: ({ checked }: { checked: boolean }) => void;
  isHeaderChecked?: boolean;
  loading: boolean;
  miniProfiles: MiniProfile[];
  setSelectedProfileId?: (id: string | null) => void;
  candidateSelection?: {
    isSelected: (profile: { profileId: string }) => boolean;
    reset: () => void;
  };
  setProfileIndex?: (index: number) => void;
  selectOneProfileHandler?: (profile: any) => void;
  handleRangeSelect?: (profile: any) => void;
  resetPageIndex: boolean;
  currentPageIndex: number;
  setCurrentPageIndex: React.Dispatch<React.SetStateAction<number>>;
  setResetPageIndex: React.Dispatch<React.SetStateAction<boolean>>;
  version: string;
  candidatePerPage: number;
  setCandidatePerPage: React.Dispatch<React.SetStateAction<number>>;
  jobId: string;
  currentSegment?: Segment | null;
  shouldHideCheckboxes?: boolean;
  shouldHideProjectSearch?: boolean;
  disableProfilesInteraction?: boolean;
  displaySortingOptions?: boolean;
}

const ProfilesTable: React.FC<ProfilesTableProps> = ({
  clientId,
  tableId = MISSION_PROFILES_TABLE_ID,
  onToggleHeaderCheckbox,
  currentSegment = null,
  isHeaderChecked,
  loading,
  miniProfiles,
  setSelectedProfileId,
  setProfileIndex,
  candidateSelection,
  selectOneProfileHandler,
  handleRangeSelect,
  resetPageIndex,
  currentPageIndex,
  setCurrentPageIndex,
  setResetPageIndex,
  version,
  candidatePerPage,
  setCandidatePerPage,
  jobId,
  shouldHideCheckboxes,
  shouldHideProjectSearch = false,
  disableProfilesInteraction,
  displaySortingOptions,
}) => {
  const { t } = useTranslation();
  const missionCategory = useContext(MissionCategoryContext);
  const contactCategory = useContext(ContactCategoryContext);
  // const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const nbPages = Math.ceil((miniProfiles?.length ?? 1) / candidatePerPage);
  const pageProfileIds = miniProfiles.slice(
    currentPageIndex * candidatePerPage,
    (currentPageIndex + 1) * candidatePerPage,
  );

  // TODO: this is a quick & dirty version since we could have multiple
  // linked ATS for one single client
  const atsId = useClientCurrentAtsId();
  const showAtsColumn = atsId && currentSegment?.id === 'interested';
  const {
    columnGroups,
    displayedColumns,
    displayColumn,
    updateColumnsDisplay,
    profileCustomFields,
    processCustomFields,
  } = useProfilesTableColumnsWithCustomFields({
    tableId,
    displayProcessCustomFields: true,
    contactCategory,
    defaultColumns: [
      { id: 'project-profiles-view-contact', hidden: false, available: true },
      { id: 'project-profiles-view-email', hidden: true, available: true },
      { id: 'project-profiles-view-phone', hidden: true, available: true },
      { id: 'project-profiles-view-relevance', hidden: true, available: true },
      { id: 'project-profiles-view-missions', hidden: true, available: true },
      {
        id: 'project-profiles-view-comment',
        hidden: true,
        available: true,
      },
      { id: 'project-profiles-view-sequence', hidden: false, available: true },
      {
        id: 'project-profiles-view-last-interaction',
        hidden: false,
        available: true,
      },
      {
        id: 'project-profiles-view-next-interaction',
        hidden: false,
        available: true,
      },
      ...(showAtsColumn
        ? [
            {
              id: 'project-profiles-view-last-ats-applications' as const,
              hidden: false,
              available: true,
            },
          ]
        : []),
    ],
  });

  useEffect(() => {
    if (resetPageIndex) {
      setResetPageIndex(false);
      setCurrentPageIndex(0);
    }
    // if (currentPageIndex !== 0) {
    //   setCurrentPageIndex(0);
    // }
    // eslint-disable-next-line
  }, [version, resetPageIndex, currentPageIndex, setResetPageIndex]);

  const handleCandidatePerPageChange = (value: number) => {
    setCandidatePerPage(value);
    localStorage.setItem('MissionPaginationProfilesPerPage', `${value}`);
  };

  const { data: searchPoolJob, loading: jobLoading } = useSearchPoolJob(
    'reveal',
    jobId,
  );

  const defaultSegmentation = Segmentations[0];
  const { segmentId } = useQueryParams({
    defaults: {
      segmentId: defaultSegmentation?.segments[0]?.id,
    },
  });

  const finalSegmentId = useMemo(() => {
    const recencySegments = [
      RecencySegment.RECENT_MONTH,
      RecencySegment.RECENT_WEEK,
      RecencySegment.ALL,
    ];
    if (_.includes(recencySegments, segmentId)) {
      return 'recency';
    }
    return segmentId;
  }, [segmentId]);

  const customFields = [...profileCustomFields, ...processCustomFields];

  if (jobLoading) {
    return (
      <div>
        <Loader active />
      </div>
    );
  }

  return (
    <div className='generic-profiles-table'>
      <ProfileRowHeader
        tableId={tableId}
        onToggleCheckbox={onToggleHeaderCheckbox}
        isChecked={isHeaderChecked}
        showProfileStep={currentSegment?.id === 'all'}
        showAtsApplications={
          displayColumn('project-profiles-view-last-ats-applications') &&
          atsId &&
          currentSegment?.id === 'interested'
        }
        showEmail={displayColumn('project-profiles-view-email')}
        showPhoneNumber={displayColumn('project-profiles-view-phone')}
        showLastInteraction={displayColumn(
          'project-profiles-view-last-interaction',
        )}
        showNextInteraction={displayColumn(
          'project-profiles-view-next-interaction',
        )}
        showMissions={displayColumn('project-profiles-view-missions')}
        showSequence={displayColumn('project-profiles-view-sequence')}
        showRelevance={displayColumn('project-profiles-view-relevance')}
        showCampaignState={displayColumn(
          'project-profiles-view-campaign-state',
        )}
        showComment={displayColumn('project-profiles-view-comment')}
        shouldHideCheckbox={shouldHideCheckboxes}
        columnGroups={columnGroups}
        displayedColumns={displayedColumns}
        displayColumn={displayColumn}
        onUpdateColumnDisplay={updateColumnsDisplay}
        customFields={customFields}
        columnSorting={displaySortingOptions}
      />

      {!loading && _.isEmpty(pageProfileIds) ? (
        <EmptyState
          title={t(
            `reveal.missions.mission.emptyStates.${finalSegmentId}.title`,
            t(`reveal.missions.mission.emptyStates.generic.title`, {
              name: currentSegment?.name,
            }),
          )}
          innerContent={t(
            `reveal.missions.mission.emptyStates.${finalSegmentId}.innerContent`,
            '',
          )}
          cta={
            !shouldHideProjectSearch ? (
              <Link
                to={`/client/${clientId}/reveal/projects/${missionCategory}/${jobId}/search`}
              >
                <GenericButton size='big'>
                  {t(
                    `reveal.missions.mission.emptyStates.${finalSegmentId}.cta`,
                    t(`reveal.missions.mission.emptyStates.replied.cta`),
                  )}
                </GenericButton>
              </Link>
            ) : null
          }
          illustrationPath={`/images/placeholders/${finalSegmentId}EmptyState.svg`}
          fallbackIllustrationPath='/images/placeholders/interestedEmptyState.svg'
        />
      ) : (
        <>
          <div className='profiles-page-container'>
            {loading ? (
              <div className='loading-placeholder'>
                <br />
                <br />
                <br />
                <Loader active inline='centered' size='large' />
                <br />
                <br />
                <br />
              </div>
            ) : (
              _.map(pageProfileIds, (profile, index) => (
                <div className='generic-profiles-table__row' key={profile.id}>
                  <NewProfileRow
                    job={searchPoolJob?.job}
                    jobId={jobId}
                    clientId={clientId}
                    searchPoolId='reveal'
                    profileId={profile.id}
                    onClick={() => {
                      if (disableProfilesInteraction) {
                        return;
                      }
                      if (setSelectedProfileId) {
                        setSelectedProfileId(profile.id);
                      }
                      if (setProfileIndex) {
                        setProfileIndex(index);
                      }
                    }}
                    isSelected={
                      !!candidateSelection &&
                      candidateSelection.isSelected({
                        profileId: profile.id,
                      })
                    }
                    shouldHideCheckbox={shouldHideCheckboxes}
                    onToggleCheckbox={selectOneProfileHandler}
                    onRangeSelect={handleRangeSelect}
                    showProfileStep={currentSegment?.id === 'all'}
                    showEmail={displayColumn('project-profiles-view-email')}
                    showMissions={displayColumn(
                      'project-profiles-view-missions',
                    )}
                    showPhoneNumber={displayColumn(
                      'project-profiles-view-phone',
                    )}
                    showRelevance={displayColumn(
                      'project-profiles-view-relevance',
                    )}
                    showSequence={displayColumn(
                      'project-profiles-view-sequence',
                    )}
                    showCampaignState={displayColumn(
                      'project-profiles-view-campaign-state',
                    )}
                    showActiveSequence
                    showUnactiveSequence
                    showLastInteraction={displayColumn(
                      'project-profiles-view-last-interaction',
                    )}
                    showNextInteraction={displayColumn(
                      'project-profiles-view-next-interaction',
                    )}
                    showAtsApplications={
                      displayColumn(
                        'project-profiles-view-last-ats-applications',
                      ) &&
                      atsId &&
                      currentSegment?.id === 'interested'
                    }
                    showComment={displayColumn('project-profiles-view-comment')}
                    atsExplanation={undefined}
                    displayColumn={displayColumn}
                    customFields={customFields}
                    isDisabled={disableProfilesInteraction}
                  />
                </div>
              ))
            )}
          </div>
          <div className='table-footer'>
            <PartitioningSelector
              onChange={handleCandidatePerPageChange}
              options={candidatePerPageOptions}
              value={candidatePerPage}
            />
            {nbPages > 1 && (
              <PageSelector
                currentPageIndex={currentPageIndex}
                numberOfPages={nbPages}
                onChange={(pageIndex) => {
                  if (candidateSelection) {
                    candidateSelection.reset();
                  }
                  setCurrentPageIndex(pageIndex);
                }}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ProfilesTable;
