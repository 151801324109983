type ExtraAction = {
  id: string;
  type: 'transform-resume';
  transformationId: string;
  name: { default: string; };
};

export const getExtraActions  = ({ clientId } : { clientId : string; }) : ExtraAction[] => {
  const isHumanskills : boolean = (
    clientId.includes('aravati') 
  ) || (
    clientId.includes('lareleve') 
  ) || (
    clientId.includes('humanskills')
  );

  if (isHumanskills) { 
    return [
      { 
        id: 'aravati-rc',
        type: 'transform-resume',
        transformationId: 'generate-document-from-template',
        name: { default: 'RC Aravati' },
      },
      { 
        id: 'aravati-rc-ai',
        type: 'transform-resume',
        transformationId: 'generate-document-from-template-with-ai',
        name: { default: 'RC Aravati + AI' },
      },
      {
        id: 'aravati-bc',
        type: 'transform-resume',
        transformationId: 'generate-aravati-background-check-from-template',
        name: { default: 'PDR Aravati' },
      },
      { 
        id: 'teaminside-profile',
        type: 'transform-resume',
        transformationId: 'generate-teaminside-profile-from-template',
        name: { default: 'CV TI' },
      }
    ];
  }

  if (clientId === 'chantal-baudron') {
    return [
      { 
        id: 'chantal-baudron-profile',
        type: 'transform-resume',
        transformationId: 'generate-chantal-baudron-profile',
        name: { default: 'Fiche Profil' },
      },
      { 
        id: 'chantal-baudron-profile-with-ai',
        type: 'transform-resume',
        transformationId: 'generate-chantal-baudron-profile-with-ai',
        name: { default: 'Fiche Profil + IA' },
      }
    ]
  }

  return [];
}