import { useMutation, type MutationHookOptions } from '@apollo/client';
import gql from 'graphql-tag';
import { useCallback } from 'react';

const updateClientFreeTrialEndDate = gql`
  mutation updateClientFreeTrialEndDate($endDate: String!) {
    updateClientFreeTrialEndDate(endDate: $endDate) {
      id
      freeTrialConfiguration {
        endDate
      }
    }
  }
`;

type Data = {
  client: {
    freeTrialConfiguration?: {
      endDate?: string; // ISODate
    };
  };
};

type Variables = {
  endDate: string;
};

type Input = {
  mutationOptions?: MutationHookOptions<Data, Variables>;
};

function useUpdateClientFreeTrialEndDate({ mutationOptions = {} }: Input = {}) {
  const [mutate, result] = useMutation<Data, Variables>(
    updateClientFreeTrialEndDate,
    mutationOptions,
  );
  const update = useCallback(
    (endDate: string) => mutate({ variables: { endDate } }),
    [mutate],
  );
  return [update, result] as const;
}

export default useUpdateClientFreeTrialEndDate;
