/* eslint-disable no-underscore-dangle */
import React from 'react';
import _ from 'underscore';
import { withTranslation } from 'react-i18next';

import GenericButton from '@/components/Common/GenericButton';

import JobDescriptionSection from './JobDescriptionSection';

import './JobPostingDescription.css';

const JobPostingDescription = ({
  description,
  editMode,
  setDescriptionState,
  t,
}) => {
  if (description.__typename !== 'MultiSectionsJobPostingDescription') {
    return null;
  }
  return (
    <MultiSectionsJobPostingDescription
      sections={description?.sections}
      editMode={editMode}
      setMultiSectionsJobPostingDescriptionState={(newSections) =>
        setDescriptionState({ ...description, sections: newSections })
      }
      t={t}
    />
  );
};

const MultiSectionsJobPostingDescription = ({
  sections,
  editMode,
  setMultiSectionsJobPostingDescriptionState,
  t,
}) => {
  const setJobDescriptionSectionStateAtIndex = (index) => (newSection) => {
    const newSections = [...sections];
    newSections[index] = newSection;
    setMultiSectionsJobPostingDescriptionState(newSections);
  };

  const addJobDescriptionSection = (type) => () => {
    let newSection;

    if (type === 'simple-section') {
      newSection = {
        __typename: 'SimpleJobPostingDescriptionSection',
        type: 'simple-section',
        title: { default: '', fr: '', en: '' },
        content: { default: '', fr: '', en: '' },
      };
    }
    if (type === 'video-section') {
      newSection = {
        __typename: 'VideoJobPostingDescriptionSection',
        type: 'video-section',
        url: '',
      };
    }
    if (type === 'image-section') {
      newSection = {
        __typename: 'ImageJobPostingDescriptionSection',
        type: 'image-section',
        file: {
          content: '',
        },
      };
    }
    const newSections = [...sections, newSection];
    setMultiSectionsJobPostingDescriptionState(newSections);
  };

  const deleteJobDescriptionSectionAtIndex = (index) => () => {
    const newSections = [...sections];
    newSections.splice(index, 1);
    setMultiSectionsJobPostingDescriptionState(newSections);
  };

  return (
    <div className='job-posting-description'>
      {_.map(sections, (section, index) => (
        <JobDescriptionSection
          key={index} // FIXME Using index as key
          index={index}
          section={section}
          editMode={editMode}
          setJobDescriptionSectionState={setJobDescriptionSectionStateAtIndex(
            index,
          )}
          deleteJobDescriptionSection={deleteJobDescriptionSectionAtIndex(
            index,
          )}
        />
      ))}
      {editMode && (
        <div className='add-section-container'>
          <GenericButton
            size='small'
            primacy='secondary'
            onClick={addJobDescriptionSection('simple-section')}
          >
            {t('offers.jobPostings.form.description.addSection')}
          </GenericButton>
          <GenericButton
            size='small'
            primacy='secondary'
            onClick={addJobDescriptionSection('image-section')}
          >
            {t('offers.jobPostings.form.description.addImage')}
          </GenericButton>
          <GenericButton
            size='small'
            primacy='secondary'
            onClick={addJobDescriptionSection('video-section')}
          >
            {t('offers.jobPostings.form.description.addVideo')}
          </GenericButton>
        </div>
      )}
    </div>
  );
};

export default withTranslation('translations')(JobPostingDescription);
