import {
  FetchResult,
  gql,
  MutationHookOptions,
  MutationResult,
  useMutation,
} from '@apollo/client';
import { useCallback } from 'react';

const generateJobPostingMutation = gql`
  mutation GenerateJobPosting(
    $input: GenerateJobPostingInput!
    $searchPoolId: ID!
  ) {
    searchPoolJob(searchPoolId: $searchPoolId) {
      generateJobPosting(input: $input) {
        sections {
          title
          description
        }
      }
    }
  }
`;

type Data = {
  searchPoolJob: {
    id: string;
    generateJobPosting?: {
      sections: {
        title: string;
        description: string;
      }[];
    };
  };
};

type Variables = {
  input: {
    missionId: string;
  };
  searchPoolId: string;
};

type Input = {
  mutationOptions?: MutationHookOptions<Data, Variables>;
};

type Result = [
  (offerId: string) => Promise<FetchResult<Data>>,
  MutationResult<Data>,
];

function useGenerateJobPosting({ mutationOptions = {} }: Input = {}): Result {
  const [mutation, result] = useMutation<Data, Variables>(
    generateJobPostingMutation,
    mutationOptions,
  );

  const update = useCallback(
    (missionId: string, searchPoolId?: string) =>
      mutation({
        variables: {
          searchPoolId: searchPoolId || 'reveal',
          input: { missionId },
        },
      }),
    [mutation],
  );

  return [update, result];
}

export default useGenerateJobPosting;
