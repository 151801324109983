
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-continue */
/* eslint-disable no-nested-ternary */
import React, { useMemo,useEffect,  useState } from 'react';
import classnames from 'classnames';
import _ from 'underscore';
import { TranslatableText } from '@/types/text';
import styles from './MetaTaskForm.module.less';
import {
  MetaTaskFormat,
  MetaTaskFormatForm,
  MetaTaskFormatFormField,
} from './types';
import MetaTaskFormFieldAnswer from './FormAnswers';
import { HelpText } from './HelpText';

const shouldDisableField = (
  field: MetaTaskFormatFormField,
  formFlagsDict: any,
) => {
  if (field.condition?.type === 'form-flag') {
    if (field.condition?.effect?.type === 'remove') {
      const { key, targetValues } = field.condition;
      return _.contains(targetValues, formFlagsDict[key]);
    }
    if (field.condition?.effect?.type === 'enable') {
      const { key, targetValues } = field.condition;
      return !_.contains(targetValues, formFlagsDict[key]);
    }
  }

  return false;
};

interface MetaTaskFormProps {
  format?: MetaTaskFormat;
  disabled?: boolean;
  readMode?: boolean;
  helpTextCTA?: string | JSX.Element | null;
  onUpdate?: ({
    newForm,
    helpText,
  }: {
    newForm: MetaTaskFormatForm;
    helpText?: TranslatableText;
  }) => void;
}

const MetaTaskForm: React.FC<MetaTaskFormProps> = ({
  format,
  helpTextCTA,
  disabled,
  readMode,
  onUpdate,
}) => {
  const [innerFormat, setInnerFormat] = useState(format);

  useEffect(() => {
    if (!format) {
      return;
    }
    setInnerFormat(format);
  }, [format]);

  const formFlagsDict = useMemo(() => (
    innerFormat ? getFormFlagsDict(innerFormat) : {}
  ), [innerFormat])

  const selectAnswer = ({
    fieldId,
    answerId,
  }: {
    fieldId: string;
    answerId: string;
  }) => {
    if (!innerFormat?.form?.fields) {
      return;
    }

    const newTargetType = fieldId === 'mission-continuation' && answerId === 'yes' ? (
      'followup'
    ) : fieldId === 'mission-continuation' && answerId === 'no' ? (
      'end-1'
    ) : undefined;

    const newFields = _.map(innerFormat?.form.fields, (field, fieldIndex) => ({
      ...field,
      ...(newTargetType && field.type === 'help-text' && fieldIndex === 0) && {
        title: { 
          default: (field.title?.default ?? '').replace(
            newTargetType === 'followup' ? 'Fin de mission -' : 'Suivi de mission -', 
            newTargetType === 'followup' ? 'Suivi de mission -' : 'Fin de mission -',
          )
        }
      },
      ...((field.type === 'select' ||
        field.type === 'checkbox' ||
        field.subtype === 'enum') &&
        field.id === fieldId && {
          options: _.map(field?.options || [], (option) => {
            return {
              ...option,
              ...(!field.isMultiselect && { selected: false }),
              ...(option.id === answerId && {
                selected:
                  option.selected === undefined ? true : !option.selected,
              }),
            };
          }),
        }),
    }));

    const newState = {
      ...innerFormat?.form,
      fields: newFields,
    };
    
    setInnerFormat((current) => ({
      ...current,
      form: newState,
    }));
    if (onUpdate) {
      onUpdate({
        ...(innerFormat?.helpText && { helpText: innerFormat.helpText }),
        newForm: newState,
      });
    }
  };

  const inputAnswer = ({
    fieldId,
    value,
  }: {
    fieldId: string;
    value: string;
  }) => {
    if (!innerFormat?.form?.fields) {
      return;
    }
    const newState = {
      ...innerFormat?.form,
      fields: _.map(innerFormat?.form.fields, (field) => ({
        ...field,
        ...(field.type !== 'select' &&
          field.subtype !== 'enum' &&
          field.type !== 'checkbox' &&
          field.id === fieldId && {
            value,
          }),
      })),
    };
    setInnerFormat((current) => ({
      ...current,
      form: newState,
    }));
    if (onUpdate) {
      onUpdate({
        ...(innerFormat?.helpText && { helpText: innerFormat.helpText }),
        newForm: newState,
      });
    }
  };

  useEffect(() => {
    if (!onUpdate) {
      setInnerFormat(format);
    }
  }, [format, onUpdate]);

  if (!format && !helpTextCTA) {
    return null;
  }

  return (
    <div className={styles.metaTaskFormContainer}>
      <HelpText text={innerFormat?.helpText} cta={helpTextCTA} />
      {_.map(
        innerFormat?.form?.fields || [],
        (field) =>
          !shouldDisableField(field, formFlagsDict) && (
            <div
              className={classnames(
                styles.metaTaskFieldContainer,
                styles.classicField,
              )}
              key={field.id}
            >
              {field.type !== 'help-text' ? (
                <>
                  <div className={styles.metaTaskTitle}>
                    {field.title.default}
                  </div>
                  {(field.type === 'select' ||
                    field.type === 'checkbox' ||
                    field.subtype === 'enum') && (
                    <div className={styles.metaTaskAnswersContainer}>
                      {_.map(
                        _.filter(
                          field.options || [],
                          (x: any) => !readMode || x?.selected,
                        ),
                        (option) => (
                          <div
                            className={styles.metaTaskAnswerContainer}
                            key={option.id}
                          >
                            <MetaTaskFormFieldAnswer
                              field={field}
                              option={option}
                              disabled={disabled}
                              readMode={readMode}
                              onSelect={disabled ? undefined : selectAnswer}
                            />
                          </div>
                        ),
                      )}
                      {readMode &&
                      !_.findWhere(field.options || [], { selected: true }) ? (
                        <div>-</div>
                      ) : (
                        ''
                      )}
                    </div>
                  )}
                  <MetaTaskFormFieldAnswer
                    field={field}
                    disabled={disabled}
                    readMode={readMode}
                    onChange={
                      disabled ? undefined : _.debounce(inputAnswer, 200)
                    }
                  />
                </>
              ) : (
                <HelpText text={field.title} />
              )}
            </div>
          ),
      )}
    </div>
  );
};

function getFormFlagsDict(format?: MetaTaskFormat) {
  const result = {} as Record<string, string>;
  const fields = format?.form?.fields ?? [];
  const isTreatedFieldIndex : Record<number, true> = {};

  const isFlagStable = (key: string) => {
    for (let index = 0; index < fields.length; index++) {
      if (isTreatedFieldIndex[index]) {
        continue;
      }
      const field = fields[index] as MetaTaskFormatFormField;
      if (_.some(field.onEvents ?? [], (event) => event.effect?.key === key)) {
        return false;
      }
    }
    return true;
  }

  const isFieldReady = (field : MetaTaskFormatFormField) => {
    if (!field.condition) {
      return true;
    }
    if (field.condition.effect.type === 'enable') {
      const { key, targetValues } = field.condition;
      return result[key] && _.contains(targetValues, result[key]);
    }
    if (field.condition?.effect?.type === 'remove') {
      const { key, targetValues } = field.condition;
      if (isFlagStable(key)) {
        return !_.contains(targetValues, result[key]);
      }
    }
    return false;
  }

  const getReadyFieldIndices = () => {
    const readyFieldIndices : number[] = [];
    for (let index = 0; index < fields.length; index++) {
      if (isTreatedFieldIndex[index]) {
        continue;
      }
      const field = fields[index] as MetaTaskFormatFormField;
      if (isFieldReady(field)) {
        readyFieldIndices.push(index);
      }
    }
    return readyFieldIndices;
  }

  let hasChanged = true;
  while (hasChanged) {
    const readyFieldIndices = getReadyFieldIndices();
    if (_.isEmpty(readyFieldIndices)) {
      hasChanged = false;
    }
    for (const index of readyFieldIndices) {
      const field = fields[index] as MetaTaskFormatFormField;
      _.each(field.onEvents || [], (event) => {
        if (event.rule?.type !== 'on-selected-value') {
          return;
        }
        const value =
          _.findWhere(field.options || [], { selected: true })?.id || field.value;
        if (value && event.effect?.type === 'set-form-flag-from-selected-value') {
          result[event.effect.key] = value;
        }
      });
      isTreatedFieldIndex[index] = true;
    }
  }

  return result;
}

export default MetaTaskForm;
